<template>
  <a-modal
    :visible="visible"
    :destroyOnClose="true"
    :onCancel="handleCancel"
    :closable="true"
    width="60%"
  >
    <template #footer>
      <a-button key="submit" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :disabled="
          getOfferedContractScore() <= currentBestOffer() && !isSupermax
        "
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <h3>
      How much do you want to offer for
      {{ playerToAdd.Name }}?
    </h3>
    <a-row class="player-info" display="flex" justify="space-between">
      <a-col :span="24">
        <div class="trait-name">
          Set Your Bid
        </div>
        <a-row class="player-info" display="flex" justify="space-between">
          <a-col :span="4">
            Cash
          </a-col>
          <a-col :span="16">
            <a-slider
              v-model:value="offeredCash"
              :min="1"
              :max="remainingCash"
              :marks="marks"
              :disabled="isSupermax"
            />
          </a-col>
          <a-col :span="4">
            <a-input-number
              v-model:value="offeredCash"
              :min="1"
              :max="100"
              style="margin-left: 16px"
              :disabled="isSupermax"
            />
          </a-col>
        </a-row>
        <a-row class="player-info" display="flex" justify="space-between">
          <a-col :span="4">
            Guaranteed Minutes
          </a-col>
          <a-col :span="16">
            <a-slider
              v-model:value="offeredMinutes"
              :min="0"
              :max="33"
              :disabled="isSupermax"
            />
          </a-col>
          <a-col :span="4">
            <a-input-number
              v-model:value="offeredMinutes"
              :min="0"
              :max="33"
              style="margin-left: 16px"
              :disabled="isSupermax"
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row class="player-info" display="flex" justify="space-between">
      <a-col :span="4">
        <div class="trait-name">
          Current Bid to Beat
        </div>
        <h3>
          {{ currentBestOffer() }}
        </h3>
      </a-col>
      <a-col :span="8">
        <div class="trait-name">
          Your Bid
        </div>
        <h3>
          {{ offeredCash }} cash + {{ offeredMinutes }} minutes
          {{
            team === playerToAdd["Prior Team"]
              ? `+ ${playerToAdd.Loyalty} Loyalty`
              : ""
          }}
          = {{ getOfferedContractScore() }}
        </h3>
      </a-col>
      <a-col :span="4">
        <div class="trait-name">
          Offered Contract Length
        </div>
        <h3>
          {{ getContractLength() }}
        </h3>
      </a-col>
    </a-row>
    <a-row class="player-info" display="flex" justify="space-between">
      <a-col :span="4">
        <a-switch
          v-if="canSupermax()"
          v-model:checked="isSupermax"
          checked-children="SUPERMAX"
          un-checked-children="Supermax?"
          @change="supermaxClicked"
        />

        <a-button v-if="canExtend()" @click="extendedClicked"
          >Extend (${{ 20 - parseInt(playerToAdd.Loyalty) }})</a-button
        ></a-col
      >
    </a-row>
  </a-modal>
</template>

<script>
const positions = [
  "PG",
  "SG",
  "SF",
  "PF",
  "C",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "RES"
];

import { mapState, mapActions } from "vuex";
import _ from "lodash";
import Draggable from "vuedraggable";
import { CameraOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

export default {
  name: "AuctionModal",
  props: [
    "team",
    "playerToAdd",
    "handleCancel",
    "visible",
    "remainingCash",
    "remainingMinutes",
    "fromPlayerTable"
  ],
  computed: {
    ...mapState(["pList", "pickupVisible"]),
    teamPlayers() {
      const setTeam = this.team;
      return _.values(
        _.pickBy(this.pList, function(value, key) {
          return value.Team === setTeam;
        })
      );
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  data() {
    return {
      pagination: {
        showSizeChanger: true,
        buildOptionText: props => {
          return <span>{props.value}</span>;
        }
      },
      roleList: [],
      offeredCash: 1,
      offeredMinutes: 0,
      marks: {
        15: "2 Seasons (15)",
        40: "3 Seasons (40)"
      },
      isSupermax: false
    };
  },

  mounted() {
    this.initRoleList();
    if (!this.fromPlayerTable) {
      this.refreshPlayers();
    }
  },
  methods: {
    difference: function(object, base) {
      function changes(object, base) {
        return _.transform(object, function(result, value, key) {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              _.isObject(value) && _.isObject(base[key])
                ? changes(value, base[key])
                : value;
          }
        });
      }
      return changes(object, base);
    },
    initRoleList: function() {
      const entryDataObj = {
        primaryPostion: 1,
        secondaryPosition: 0,
        minutes: 1,
        touches: 1,
        playInitiator: false,
        playOne: 0,
        playTwo: 0,
        playThree: 0,
        playFour: 0,
        role: 0
      };

      this.roleList = _.cloneDeep(this.teamPlayers)
        .map(player => {
          const initData = _.cloneDeep(entryDataObj);
          const vitals = player.Data.VITALS;
          const tendencies = player.Data.TENDENCIES;

          initData.primaryPostion = vitals.POSITION;
          initData.secondaryPosition = vitals.SECONDARY_POSITION;
          initData.minutes = parseInt(vitals.MINUTES_IN_ROTATION);
          initData.touches = parseInt(tendencies.TOUCHES_TENDENCY);
          initData.playInitiator = vitals.PLAY_INITIATOR === "1";
          initData.playOne = vitals["PLAY_TYPE_1"];
          initData.playTwo = vitals["PLAY_TYPE_2"];
          initData.playThree = vitals["PLAY_TYPE_3"];
          initData.playFour = vitals["PLAY_TYPE_4"];
          initData.role = parseInt(player.Role);

          if (initData.role === 13) {
            initData.minutes = 0;
          }

          if (initData.role < 5) {
            Math.max(initData.minutes, 1);
          }

          return {
            player: player,
            entryData: _.cloneDeep(initData)
          };
        })
        .sort((a, b) => {
          return a.entryData.role - b.entryData.role;
        });
    },
    dropPlayer(player) {
      this.handleCancel();
      this.pickupPlayer({
        playerToAdd: this.playerToAdd.Name,
        playerToDrop: player.Name
      });
    },
    currentBestOffer() {
      if (!this.playerToAdd["Contract Offer"]) return 0;
      const contract = this.playerToAdd["Contract Offer"];

      return (
        parseInt(contract.Cash) +
        parseInt(contract.Loyalty) +
        parseInt(contract.Minutes)
      );
    },
    getContractLength() {
      if (this.offeredCash < 15) {
        return 1;
      } else if (this.offeredCash < 40) {
        return 2;
      } else {
        return 3;
      }
    },
    getOfferedContractScore() {
      const loyalty =
        this.playerToAdd["Prior Team"] === this.team
          ? parseInt(this.playerToAdd.Loyalty)
          : 0;

      return this.offeredCash + this.offeredMinutes + loyalty;
    },
    handleOk: async function() {
      await this.refreshPlayers();

      if (this.remainingCash < this.offeredCash) {
        message.error(
          `Sorry, You don't have enough cash to offer this (${this.remainingCash} remaining).`
        );
      } else if (this.remainingMinutes < this.offeredMinutes) {
        message.error(
          `Sorry, You don't have enough minutes to offer this (${this.remainingCash} remaining).`
        );
      } else if (this.currentBestOffer() > this.getOfferedContractScore()) {
        message.error(
          "Sorry, another team has offered a larger contract before you. Please reevaluate your offer."
        );
      } else {
        this.offerContract({
          Player: this.playerToAdd.Name,
          Team: this.team,
          Cash: this.offeredCash,
          Minutes: this.offeredMinutes,
          Supermax: this.isSupermax,
          Extended: this.isExtended,
          Loyalty:
            this.playerToAdd["Prior Team"] === this.team
              ? this.playerToAdd.Loyalty
              : 0
        });
        this.handleCancel();
      }
    },
    supermaxClicked() {
      if (this.isSupermax) {
        this.offeredCash = 67;
        this.offeredMinutes = 33;
      }
    },
    extendedClicked() {
      this.extendPlayer(this.playerToAdd);
      this.handleCancel();
    },
    canSupermax() {
      const priorTeam = this.playerToAdd["Prior Team"];

      const belongsToTeam = priorTeam === this.team;
      const noCurrentSupermaxes = !_.find(
        this.pList,
        val => val.Team === this.team && val["Contract Offer"].Supermax
      );
      return belongsToTeam && noCurrentSupermaxes;
    },
    canExtend() {
      const priorTeam = this.playerToAdd["Prior Team"];

      const belongsToTeam = priorTeam === this.team;
      const extendable = this.playerToAdd.Extendable === "TRUE";
      return belongsToTeam && extendable;
    },
    ...mapActions({
      extendPlayer: "extendPlayer",
      pickupPlayer: "pickupPlayer",
      refreshPlayers: "refreshPlayers",
      offerContract: "offerContract"
    })
  },
  components: {
    Draggable,
    CameraOutlined
  }
};
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}
.position {
  margin-bottom: 0px;
  text-align: center;
}
.trait-name {
  font-size: 14px;
  color: gray;
  text-align: left;
}
.player-info {
  text-align: left;
  padding: 6px;
}
.preview-image {
  max-width: 200px;
}
</style>
