import _ from "lodash";

export const teamStandings = {
  Standard: {
    Record: "",
    "Last 2 weeks": "",
    "Pre-Midseason Record": "",
    "Post-Midseason Record": "",
    Wins: "",
    Losses: "",
    "Games back": "",
    "Games Remaining": "",
    "Avg Home Score": "",
    "Avg Home Score (Opp)": "",
    "Home Differential": "",
    "Avg Away Score": "",
    "Avg Away score (Opp)": "",
    "Away Differential": "",
    "Home Court Advantage": ""
  },
  h2h: {
    "h2h-Mavericks": "",
    "h2h-Pacers": "",
    "h2h-Warriors": "",
    "h2h-Lakers": "",
    "h2h-Nuggets": "",
    "h2h-Wizards": "",
    "h2h-Raptors": ""
  },
  diff: {
    "diff-Mavericks": "",
    "diff-Pacers": "",
    "diff-Warriors": "",
    "diff-Lakers": "",
    "diff-Nuggets": "",
    "diff-Wizards": "",
    "diff-Raptors": ""
  }
};

export const teamStats = {
  "Team Info": {
    Team: ""
  },
  Regular: {
    Points: "",
    Rebounds: "",
    Assists: "",
    Steals: "",
    Blocks: "",
    Turnovers: "",
    "FG Made": "",
    "FG Attempted": "",
    "FG %": "",
    "3PT Made": "",
    "3PT Taken": "",
    "3PT%": "",
    FTs: "",
    "FT Attempted": "",
    "FT%": "",
    "Offensive Rebounds": "",
    "Defensive Rebounds": "",
    Fouls: "",
    Dunks: ""
  },
  Opponent: {
    "O/Points": "",
    "O/Rebounds": "",
    "O/Assists": "",
    "O/Steals": "",
    "O/Blocks": "",
    "O/Turnovers": "",
    "O/FG Made": "",
    "O/FG Attempted": "",
    "O/FG%": "",
    "O/3PT Made": "",
    "O/3PT Taken": "",
    "O/3PT %": "",
    "O/FTs": "",
    "O/FT Attempted": "",
    "O/FT%": "",
    "O/Offensive Rebounds": "",
    "O/Defensive Rebounds": "",
    "O/Fouls": "",
    "O/Dunks": ""
  },
  Diff: {
    "D/Points": "",
    "D/Rebounds": "",
    "D/Assists": "",
    "D/Steals": "",
    "D/Blocks": "",
    "D/Turnovers": "",
    "D/FG Made": "",
    "D/FG Attempted": "",
    "D/FG%": "",
    "D/3PT Made": "",
    "D/3PT Taken": "",
    "D/3PT%": "",
    "D/FTs": "",
    "D/FT Attempted": "",
    "D/FT %": "",
    "D/Offensive Rebounds": "",
    "D/Defensive Rebounds": "",
    "D/Fouls": "",
    "D/Dunks": ""
  },
  Advanced: {
    "Possessions (Pace)": "",
    "Offensive Possessions": "",
    "Defensive possessions": "",
    "Points/Possession": "",
    ORtg: "",
    DRtg: "",
    "Rtg Diff": ""
  }
};

export const teamPlaystyles = {
  Pace: 0, // possessions
  "Shot Selection": 0, // 3pt*3+dnk*2+ft*1/points
  Spacing: 0, // 3pt taken/poss
  "Ball Movement": 0, // ast/poss
  Iso: 0, //fg made - ast
  Defense: 0 // o/points
};

export const teamDetails = {
  Team: "",
  Cash: "",
  Frozen: "",
  "Draft Picks": "",
  Camps: [],
  Mentorships: [],
  "Primary Color": "",
  "Secondary Color": "",
  Owner: "",
  Logo: "",
  Standings: teamStandings,
  Stats: teamStats,
  Playstyles: teamPlaystyles,
  "Retiring Players": [],
  "Ready For Signings": false
};
