<template>
  <div class="game">
    <a-row>
      <h4 class="date">{{ game["Date"] }}</h4>
      <div
        class="game-header"
        @click="toggleExpand"
        :style="{
          padding: isMobile() ? '10px' : '35px'
        }"
      >
        <a-col :xs="5" :md="5" class="team-title">
          <img
            :src="teams[0].Logo"
            class="image"
            :style="{ opacity: game.Home === game['Winning Team'] ? 1 : 0.25 }"
          />
          <strong v-if="!isMobile()">{{ game["Home"] }} </strong>
        </a-col>
        <a-col :xs="14" :md="4" display="flex" justify="center">
          <div class="final-score">
            {{ game["Final Score"] || "0 - 0" }}
          </div>
        </a-col>
        <a-col :xs="5" :md="5" class="team-title">
          <strong v-if="!isMobile()"> {{ game["Away"] }}</strong>
          <img
            :src="teams[1].Logo"
            class="image"
            :style="{ opacity: game.Away === game['Winning Team'] ? 1 : 0.25 }"
          />
        </a-col>
      </div>
    </a-row>
    <div v-if="expanded" class="game-content">
      <h2>Top Players</h2>

      <a-row
        v-if="boxScore.length"
        display="flex"
        justify="space-between"
        class="potg"
      >
        <a-col :xs="2" :md="6" v-if="!isMobile()">
          <img
            class="propic"
            :src="this.pList[getPOTG(teams[0]).Player].Image"
            :style="getPotgShadow(0)"
          />
        </a-col>
        <a-col :xs="10" :md="5">
          <h3>
            {{
              isMobile()
                ? nameToInitial(this.pList[getPOTG(teams[0]).Player].Name)
                : this.pList[getPOTG(teams[0]).Player].Name
            }}
          </h3>
          <div class="comp" style="text-align: left">
            <div
              class="notable-stat"
              :style="{
                textAlign: 'right'
              }"
            >
              <div
                v-for="(item, index) in getTopThree(getPOTG(teams[0]))"
                :key="index"
                class="potg-label"
              >
                {{ item[0] }}
              </div>
            </div>
            <div
              class="notable-stat"
              :style="{ color: teams[0]['Primary Color'], textAlign: 'left' }"
            >
              <div
                v-for="(item, index) in getTopThree(getPOTG(teams[0]))"
                :key="index"
                class="potg-number"
              >
                <strong>{{ getPOTG(teams[0])[item[0]] }}</strong>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="1" v-if="!isMobile()">
          <h3>VS</h3>
        </a-col>
        <a-col :xs="10" :md="5">
          <h3>
            {{
              isMobile()
                ? nameToInitial(this.pList[getPOTG(teams[1]).Player].Name)
                : this.pList[getPOTG(teams[1]).Player].Name
            }}
          </h3>
          <div class="comp" style="text-align: left">
            <div
              class="notable-stat"
              :style="{
                color: teams[1]['Primary Color'],
                textAlign: 'right'
              }"
            >
              <div
                v-for="(item, index) in getTopThree(getPOTG(teams[1]))"
                :key="index"
                class="potg-number"
              >
                <strong>{{ getPOTG(teams[1])[item[0]] }}</strong>
              </div>
            </div>
            <div class="notable-stat">
              <div
                v-for="(item, index) in getTopThree(getPOTG(teams[1]))"
                :key="index"
                class="potg-label"
              >
                {{ item[0] }}
              </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="2" :md="6" v-if="!isMobile()">
          <img
            class="propic"
            :src="this.pList[getPOTG(teams[1]).Player].Image"
            :style="getPotgShadow(1)"
          />
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <h2>Box Score</h2>

          <Statline :data="boxScore" :labels="myBoxScoreLabels" />
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col :span="24" class="archive">
          <h2>Archive</h2>
          <YouTube :src="game['ArchiveVOD']" ref="youtube" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import Statline from "./Statline";
import { boxScoreLabels } from "../models/labels";
import { mapState } from "vuex";
import _ from "lodash";
import YouTube from "vue3-youtube";
import { isMobile } from "../utils/layoutHelper";
import { nameToInitial } from "../utils/convertHelper";
export default {
  props: ["game", "boxScore", "teams"],
  setup() {
    return {
      isMobile,
      nameToInitial
    };
  },
  data() {
    return {
      expanded: false,
      averages: {}
    };
  },
  computed: {
    ...mapState(["pList"]),
    myBoxScoreLabels() {
      return boxScoreLabels;
    }
  },
  methods: {
    getPotgShadow(num) {
      return {
        borderColor: this.teams[num]["Primary Color"],
        boxShadow: `5px 5px 5px
          ${this.teams[num]["Primary Color"]}, -5px -5px 5px
          ${this.teams[num]["Secondary Color"]}`
      };
    },
    toggleExpand() {
      if (this.game["Final Score"]) {
        this.expanded = this.expanded = !this.expanded;
      }
    },
    getPOTG(team) {
      const sortedBoxScores = [...this.boxScore];
      const getGameScore = player => {
        return (
          parseInt(player["Points"]) +
          0.4 * parseInt(player["FG Made"]) -
          0.7 * parseInt(player["FG Taken"]) -
          0.4 * (parseInt(player["FT Taken"]) - parseInt(player["FT Made"])) +
          0.7 * parseInt(player["Offensive Rebounds"]) +
          0.3 *
            (parseInt(player["Rebounds"]) -
              parseInt(player["Offensive Rebounds"])) +
          parseInt(player["Steals"]) +
          0.7 * parseInt(player["Assists"]) +
          0.7 * parseInt(player["Blocks"]) -
          0.4 * parseInt(player["Fouls"]) -
          parseInt(player["Turnovers"])
        );
      };

      sortedBoxScores.sort((a, b) => {
        return getGameScore(b) - getGameScore(a);
      });

      return sortedBoxScores.filter(
        bs => bs["Team"] === team.Team.toUpperCase()
      )[0];
    },
    getTopThree(player) {
      const zScale = [];

      _.keys(this.averages).forEach(avgKey => {
        zScale.push([
          avgKey,
          parseFloat(player[avgKey]) - this.averages[avgKey]
        ]);
      });

      zScale.sort((a, b) => {
        return b[1] - a[1];
      });

      return zScale.slice(0, 5);
    },
    getAverages() {
      const notableStats = [
        "Points",
        "Assists",
        "Rebounds",
        "Steals",
        "Blocks",
        "FG Made",
        "3PT Made",
        "FT Made",
        "+/-"
      ];
      notableStats.forEach(stat => {
        this.averages[stat] = _.meanBy(this.boxScore, bs => {
          return parseFloat(bs[stat]);
        });
      });
    }
  },
  mounted() {
    this.getAverages();
  },
  components: {
    Statline,
    YouTube
  }
};
</script>

<style scoped>
.game {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.game-header {
  display: flex;
  justify-content: center;
  padding: 35px;
  width: 100%;
  background-color: white;
  align-items: center;
  cursor: pointer;
}

.game-content {
  height: fit-content;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1400px;
  width: 100%;
  border-image-source: linear-gradient(to left, #00c853, #b2ff59);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.team-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

.image {
  width: 70px;
  object-fit: contain;
}

.propic {
  max-width: 100%;
  border-radius: 10px;
  width: 250px;
  object-fit: contain;
  outline: none;
  border-width: 5px;
}

.final-score {
  font-size: 32px;
}

.potg {
  padding: 0, 10px;
  font-size: 26px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border: 1px solid lightgray;
}

.potg-number {
  font-size: 26px;
}

.potg-label {
  font-size: 26px;
}

.comp {
  height: 100%;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.notable-stat {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.archive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.scroll-container {
  width: "100%";
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>
