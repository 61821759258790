import _ from "lodash";

export const getPositionName = player => {
  const { SECONDARY_POSITION, POSITION } = player.Data?.VITALS;

  const getPosition = pos => {
    switch (pos) {
      case "0":
        return "PG";
      case "1":
        return "SG";
      case "2":
        return "SF";
      case "3":
        return "PF";
      case "4":
        return "C";
      case "5":
        return false;
    }
  };

  const primary = getPosition(POSITION);
  const secondary = getPosition(SECONDARY_POSITION);

  return secondary ? `${primary}/${secondary}` : primary;
};

export const getHeight = player => {
  const cmToInFt = (cm, inches = Math.round(cm / 2.54)) => ({
    feet: Math.floor(inches / 12),
    inches: inches % 12
  });
  const height = cmToInFt(
    parseInt(_.get(player, ["Data", "VITALS", "HEIGHT_CM"]))
  );

  return `${height.feet}'${height.inches}"`;
};

export const getWeight = player => {
  return parseInt(_.get(player, ["Data", "VITALS", "WEIGHT_LBS"], 0));
};

export const getWingspan = player => {
  const cmToInFt = (cm, inches = Math.round(cm / 2.54)) => ({
    feet: Math.floor(inches / 12),
    inches: inches % 12
  });
  const height = cmToInFt(
    parseInt(_.get(player, ["Data", "VITALS", "WINGSPAN_CM"]))
  );

  return `${height.feet}'${height.inches}"`;
};

export const getTotals = player => {
  return {
    attributes: _.values(_.get(player, ["Data", "ATTRIBUTES"], {}))
      .flat()
      .keys()
      .reduce((prev, curr) => {
        return prev + parseInt(curr.value);
      }, 0),
    badges: _.values(_.get(player, ["Data", "ATTRIBUTES"], {}))
      .flat()
      .keys()
      .reduce((prev, curr) => {
        return prev + parseInt(curr.value);
      }, 0)
  };
};

export const getPlayTypes = player => {
  const vitals = _.get(player, ["Data", "VITALS"], []);
  const playTypeOptions = [
    {
      value: "0",
      label: "None"
    },
    {
      value: "1",
      label: "Isolation"
    },
    {
      value: "2",
      label: "Isolation Point"
    },
    {
      value: "3",
      label: "Isolation Wing"
    },
    {
      value: "4",
      label: "P&R Ball Handler"
    },
    {
      value: "5",
      label: "P&R Point"
    },
    {
      value: "6",
      label: "P&R Wing"
    },
    {
      value: "7",
      label: "P&R Roll Man"
    },
    {
      value: "8",
      label: "Post Up Low"
    },
    {
      value: "9",
      label: "Post Up High"
    },
    {
      value: "10",
      label: "Guard Post Up"
    },
    {
      value: "11",
      label: "Cutter"
    },
    {
      value: "12",
      label: "Hand Off"
    },
    {
      value: "13",
      label: "Mid Range"
    },
    {
      value: "14",
      label: "3 PT"
    }
  ];

  return [
    playTypeOptions[parseInt(vitals.PLAY_TYPE_1)],
    playTypeOptions[parseInt(vitals.PLAY_TYPE_2)],
    playTypeOptions[parseInt(vitals.PLAY_TYPE_3)],
    playTypeOptions[parseInt(vitals.PLAY_TYPE_4)]
  ];
};

const capitalizedTeam = team => {
  return team[0].toUpperCase() + team.slice(1).toLowerCase();
};
export const getTeamColors = (player, teams) => {
  const team = player.Team ?? player;
  return {
    primary: _.get(teams, [capitalizedTeam(team), "Primary Color"], "#1a1526"),
    secondary: _.get(
      teams,
      [capitalizedTeam(team), "Secondary Color"],
      "#1a1526"
    )
  };
};

export const getTeamLogo = (player, teams) => {
  const team = player.Team ?? player;
  return _.get(teams, [capitalizedTeam(team), "Logo"]);
};

export const getPlayerArchs = player => {
  const archetypeAdjectives = [
    {
      name: "Athletic",
      attributes: [
        "SPEED",
        "ACCELERATION",
        "VERTICAL",
        "SPEED_WITH_BALL",
        "STAMINA"
      ]
    },
    {
      name: "Sharpshooting",
      attributes: [
        "3PT_SHOT",
        "MID-RANGE_SHOT",
        "SHOT_CLOSE",
        "OFFENSIVE_CONSISTENCY"
      ]
    },
    {
      name: "Playmaking",
      attributes: [
        "PASSING_ACCURACY",
        "BALL_CONTROL",
        "PASSING_IQ",
        "PASSING_VISION"
      ]
    },
    {
      name: "Post-Scoring",
      attributes: [
        "POST_MOVES",
        "POST_HOOK",
        "POST_FADEAWAY",
        "STRENGTH",
        "STANDING_DUNK"
      ]
    },
    {
      name: "Two-Way",
      attributes: [
        "PASS_PERCEPTION",
        "PERIMETER_DEFENSE",
        "INTERIOR_DEFENSE",
        "STEAL",
        "BLOCK",
        "HELP_DEFENSIVE_IQ",
        "LATERAL_QUICKNESS"
      ]
    },
    {
      name: "Rebounding",
      attributes: [
        "OFFENSIVE_REBOUND",
        "DEFENSIVE_REBOUND",
        "VERTICAL",
        "STRENGTH"
      ]
    },
    {
      name: "Slashing",
      attributes: [
        "DRIVING_LAYUP",
        "DRIVING_DUNK",
        "DRAW_FOUL",
        "BALL_CONTROL",
        "SPEED_WITH_BALL"
      ]
    },
    {
      name: "Dominant",
      attributes: ["OFFENSIVE_CONSISTENCY", "DEFENSIVE_CONSISTENCY", "HUSTLE"]
    },
    {
      name: "High-IQ",
      attributes: ["SHOT_IQ", "HELP_DEFENSIVE_IQ", "PASSING_IQ"]
    },
    {
      name: "High-Motor",
      attributes: ["HUSTLE", "STAMINA", "HANDS"]
    },
    {
      name: "Floor-Spacing",
      attributes: ["3PT_SHOT", "SPEED"]
    },
    {
      name: "Foul-Baiting",
      attributes: ["DRAW_FOUL", "FREE_THROW", "POST_FADEAWAY"]
    }
  ];

  const {
    Athleticism,
    Shooting,
    Finishing,
    Mental,
    Conditioning,
    Defense,
    Playmaking,
    "Post Game": PostScoring,
    Rebounding
  } = player.Data.ATTRIBUTES;
  const playerAttr = {
    ...Athleticism,
    ...Shooting,
    ...Finishing,
    ...Mental,
    ...Conditioning,
    ...Defense,
    ...Playmaking,
    ...PostScoring,
    ...Rebounding
  };

  const topArchs = archetypeAdjectives.map(entry => {
    const archAverage = entry.attributes.reduce((prev, curr) => {
      return prev + parseInt(playerAttr[curr].value);
    }, 0);

    return {
      name: entry.name,
      value: archAverage / entry.attributes.length
    };
  });

  topArchs.sort((a, b) => b.value - a.value);

  return `${topArchs[0].name} ${topArchs[1].name} ${getArchetype(player)}`;
};

export const getArchetype = player => {
  const positions = [
    player.Data.VITALS.POSITION,
    player.Data.VITALS.SECONDARY_POSITION
  ];

  if (positions.includes("0")) {
    return "Guard";
  } else if (positions.includes("4")) {
    return "Big";
  } else if (positions.includes("3")) {
    return "Forward";
  } else {
    return "Wing";
  }
};
