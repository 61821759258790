import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import {
  Layout,
  Button,
  Spin,
  Result,
  Card,
  Divider,
  Col,
  Row,
  Drawer,
  Table,
  Form,
  InputNumber,
  Image,
  Tag,
  Tabs,
  Tooltip,
  Icon,
  Menu,
  Radio,
  Comment,
  Avatar,
  AutoComplete,
  Statistic,
  Timeline,
  Select,
  Descriptions,
  List,
  Collapse,
  Input,
  Empty,
  Badge,
  Dropdown,
  Checkbox,
  Modal,
  Space,
  Popover,
  Slider,
  Switch
} from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "./stylesheets/global.scss";
import LoadScript from "vue-plugin-load-script";

createApp(App)
  .use(store)
  .use(router)
  .use(Layout)
  .use(Button)
  .use(Spin)
  .use(Result)
  .use(Card)
  .use(Divider)
  .use(Col)
  .use(Row)
  .use(Drawer)
  .use(Table)
  .use(Form)
  .use(InputNumber)
  .use(Image)
  .use(Tag)
  .use(Tabs)
  .use(Tooltip)
  .use(Icon)
  .use(Menu)
  .use(Radio)
  .use(Comment)
  .use(Avatar)
  .use(AutoComplete)
  .use(Select)
  .use(Statistic)
  .use(Timeline)
  .use(Descriptions)
  .use(List)
  .use(Collapse)
  .use(Input)
  .use(Empty)
  .use(Badge)
  .use(Dropdown)
  .use(Checkbox)
  .use(Modal)
  .use(Space)
  .use(Popover)
  .use(Slider)
  .use(Switch)
  .use(LoadScript)

  .mount("#app");
