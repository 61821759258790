<template>
  <div class="team-stats-page">
    <StatsTable
      v-bind:stats="Object.keys(teams).map(team => teams[team].Stats)"
    ></StatsTable>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import StatsTable from "@/components/TeamTable.vue";
export default {
  computed: { ...mapState(["stats", "teams"]) },
  name: "TeamStats",
  components: {
    StatsTable
  }
};
</script>

<style scoped>
.stats-page {
}
</style>
