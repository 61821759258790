<template>
  <a-table
    :columns="columns"
    :rowKey="record => record.key"
    :data-source="tendencies"
    :expandRowByClick="true"
    :expandedRowKeys="currentExpanded"
    :pagination="false"
    :bordered="false"
    :expandIcon="
      () => {
        return null;
      }
    "
  >
    <template #expandedRowRender="{ record }">
      <div :style="{ textAlign: 'center' }">
        <a-list size="small" :data-source="record.tendencies">
          <template #renderItem="{ item }">
            <a-list-item> {{ item }} </a-list-item>
          </template>
        </a-list>
      </div>
    </template>
  </a-table>
</template>

<script>
export default {
  name: "TendenciesTable",
  components: {},
  data() {
    return {
      inner_column: {
        title: "Tendencies",
        dataIndex: "tendencies",
        key: "tendencies",
        align: "center"
      },
      columns: [
        {
          title: "Tendency",
          dataIndex: "tendency",
          key: "tendency",
          align: "center"
        },
        { title: "Value", dataIndex: "value", key: "value", width: "20%" }
      ],
      tendencies: [
        {
          key: 1,
          tendency: "Use Your Athleticism",
          tendencies: [
            "ATTACK_STRONG_ON_DRIVE_TENDENCY",
            "DRIVE_TENDENCY",
            "DRIVING_LAYUP_TENDENCY",
            "DRIVING_DUNK_TENDENCY",
            "ALLEY_OOP_TENDENCY",
            "PUTBACK_TENDENCY",
            "NO_SETUP_DRIBBLE_TENDENCY"
          ],
          value: 20
        },
        {
          key: 2,
          tendency: "Create Your Own Shot",
          tendencies: [
            "SPIN_JUMPER_TENDENCY",
            "STEPBACK_JUMPER_THREE_TENDENCY",
            "STEPBACK_JUMPER_MID-RANGE_TENDENCY",
            "TRIPLE_THREAT_PUMP_FAKE_TENDENCY",
            "DRIVING_STEP_BACK_TENDENCY",
            "DRIVING_BEHIND_THE_BACK_TENDENCY",
            "DRIVING_DOUBLE_CROSSOVER_TENDENCY"
          ],
          value: 20
        },
        {
          key: 3,
          tendency: "Use Your Skills",
          tendencies: [
            "TRIPLE_THREAT_JAB_STEP_TENDENCY",
            "TRIPLE_THREAT_IDLE_TENDENCY",
            "TRIPLE_THREAT_SHOOT_TENDENCY",
            "TRIPLE_THREAT_PUMP_FAKE_TENDENCY",
            "DRIVING_HALF_SPIN_TENDENCY",
            "DRIVING_IN_AND_OUT_TENDENCY",
            "DRIVING_CROSSOVER_TENDENCY",
            "DRIVING_DRIBBLE_HESITATION_TENDENCY",
            "SPIN_LAYUP_TENDENCY",
            "HOP_STEP_LAYUP_TENDENCY",
            "EURO_STEP_LAYUP_TENDENCY",
            "FLOATER_TENDENCY"
          ],
          value: 20
        },
        {
          key: 4,
          tendency: "Shoot More 3s",
          tendencies: [
            "SHOT_THREE_TENDENCY",
            "SPOT_UP_SHOT_THREE_TENDENCY",
            "OFF_SCREEN_SHOT_THREE_TENDENCY",
            "SHOT_THREE_LEFT_TENDENCY",
            "SHOT_THREE_LEFT-CENTER_TENDENCY",
            "SHOT_THREE_CENTER_TENDENCY",
            "SHOT_THREE_RIGHT-CENTER_TENDENCY",
            "SHOT_THREE_RIGHT_TENDENCY",
            "CONTESTED_JUMPER_THREE_TENDENCY",
            "DRIVE_PULL_UP_THREE_TENDENCY",
            "SHOT_TENDENCY"
          ],
          value: 20
        },
        {
          key: 5,
          tendency: "Shoot More Mid-Range",
          tendencies: [
            "SHOT_MID-RANGE_TENDENCY",
            "SPOT_UP_SHOT_MID-RANGE_TENDENCY",
            "OFF_SCREEN_SHOT_MID-RANGE_TENDENCY",
            "SHOT_MID_LEFT_TENDENCY",
            "SHOT_MID_LEFT-CENTER_TENDENCY",
            "SHOT_MID_CENTER_TENDENCY",
            "SHOT_MID_RIGHT-CENTER_TENDENCY",
            "SHOT_MID_RIGHT_TENDENCY",
            "CONTESTED_JUMPER_MID-RANGE_TENDENCY",
            "DRIVE_PULL_UP_MID-RANGE_TENDENCY",
            "SHOT_TENDENCY"
          ],
          value: 20
        },
        {
          key: 6,
          tendency: "Post-em Up",
          tendencies: [
            "POST_UP_TENDENCY",
            "POST_BACK_DOWN_TENDENCY",
            "POST_AGGRESSIVE_BACKDOWN_TENDENCY",
            "SHOOT_FROM_POST_TENDENCY",
            "POST_SPIN_TENDENCY",
            "POST_DRIVE_TENDENCY",
            "POST_FADE_LEFT_TENDENCY",
            "POST_FADE_RIGHT_TENDENCY",
            "POST_HOOK_LEFT_TENDENCY",
            "POST_HOOK_RIGHT_TENDENCY"
          ],
          value: 20
        },
        {
          key: 7,
          tendency: "Iso Iso Iso",
          tendencies: [
            "IS_VS._ELITE_DEFENDER_TENDENCY",
            "IS_VS._GOOD_DEFENDER_TENDENCY",
            "IS_VS._AVERAGE_DEFENDER_TENDENCY",
            "IS_VS._POOR_DEFENDER_TENDENCY"
          ],
          value: 20
        },
        {
          key: 8,
          tendency: "Big Brain Plays",
          tendencies: [
            "DISH_TO_OPEN_MAN_TENDENCY",
            "FLASHY_PASS_TENDENCY",
            "ALLEY-OOP_PASS_TENDENCY",
            "PLAY_DISCIPLINE_TENDENCY"
          ],
          value: 20
        },
        {
          key: 9,
          tendency: "Go Wild",
          tendencies: ["PLAY_DISCIPLINE_TENDENCY"],
          value: -20
        },
        {
          key: 10,
          tendency: "Reign It In",
          tendencies: ["PLAY_DISCIPLINE_TENDENCY", "SHOT_TENDENCY"],
          value: 20
        },

        {
          key: 11,
          tendency: "Play Hard Defense",
          tendencies: [
            "CONTEST_SHOT_TENDENCY",
            "ON-BALL_STEAL_TENDENCY",
            "BLOCK_SHOT_TENDENCY",
            "PASS_INTERCEPTION_TENDENCY"
          ],
          value: 20
        },

        {
          key: 12,
          tendency: "Play Tough",
          tendencies: [
            "CONTEST_SHOT_TENDENCY",
            "FOUL_TENDENCY",
            "HARD_FOUL_TENDENCY",
            "SETUP_WITH_SIZEUP_TENDENCY",
            "POST_AGGRESSIVE_BACKDOWN_TENDENCY",
            "ATTACK_STRONG_ON_DRIVE_TENDENCY"
          ],
          value: 20
        },
        {
          key: 13,
          tendency: "Use Your Size",
          tendencies: [
            "BLOCK_SHOT_TENDENCY",
            "PUTBACK_TENDENCY",
            "CONTEST_SHOT_TENDENCY",
            "POST_UP_TENDENCY",
            "POST_BACK_DOWN_TENDENCY",
            "POST_SPIN_TENDENCY",
            "PASS_INTERCEPTION_TENDENCY",
            "DRIVING_DUNK_TENDENCY",
            "STANDING_DUNK_TENDENCY"
          ],
          value: 20
        },
        {
          key: 14,
          tendency: "Run In Transition",
          tendencies: [
            "TRANSITION_SPOT_UP_TENDENCY",
            "TRANSITION_PULL_UP_THREE_TENDENCY",
            "DRIVE_PULL_UP_THREE_TENDENCY",
            "DISH_TO_OPEN_MAN_TENDENCY",
            "DRIVING_DUNK_TENDENCY"
          ],
          value: 20
        }
      ]
    };
  }
};
</script>

<style></style>
