<template>
  <div class="draft-page">
    <a-row>
      <a-col :span="24">
        <PlayerTable v-bind:players="incomingRookies" v-bind:useRank="true" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import PlayerTable from "@/components/PlayerTable.vue";
import _ from "lodash";
export default {
  name: "Draft Board",
  data() {
    return {
      currentKey: "Current"
    };
  },
  computed: {
    ...mapState(["pList"]),
    incomingRookies() {
      return _.pickBy(this.pList, function(value, key) {
        return value.Team === "Rookie";
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  methods: {
    isMobile() {
      return window.innerWidth < 500;
    }
  },
  components: {
    PlayerTable
  },
  props: {
    team: String
  }
};
</script>

<style scoped>
.list-item {
  padding: 0;
}

.switch {
  padding: 10px;
}
</style>
