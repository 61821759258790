<template>
  <h2>Player Compare Tool</h2>
  <a-row display="flex" justify="space-around">
    <a-col>
      <a-select
        ref="select"
        show-search
        v-model:value="playerOne"
        style="width: 240px"
        :options="selectablePlayers"
        placeholder="Select Player 1"
      ></a-select>
    </a-col>
    <a-col>
      <a-select
        ref="select2"
        show-search
        v-model:value="playerTwo"
        style="width: 240px"
        :options="selectablePlayers"
        placeholder="Select Player 2"
      ></a-select>
    </a-col>
  </a-row>
  <a-row>
    <a-col v-if="playerOne" :span="12">
      <PlayerDetails :player="getPlayer(playerOne)" :noReports="true"
    /></a-col>
    <a-col v-if="playerTwo" :span="12"
      ><PlayerDetails :player="getPlayer(playerTwo)" :noReports="true" />
    </a-col>
  </a-row>
</template>
<script>
import { mapState } from "vuex";
import _ from "lodash";
import PlayerDetails from "@/components/PlayerDetails.vue";
export default {
  computed: {
    ...mapState(["pList", "teams"]),
    selectablePlayers() {
      const res = _.values(this.pList).reduce((acc, curr) => {
        if (!acc[curr.Team]) {
          acc[curr.Team] = {
            label: curr.Team,
            options: [{ label: curr.Name, value: curr.Name }]
          };
        } else {
          acc[curr.Team].options.push({ label: curr.Name, value: curr.Name });
        }

        return acc;
      }, {});

      return _.values(res).sort((a, b) => a.label.localeCompare(b.label));
    }
  },
  data() {
    return {
      playerOne: undefined,
      playerTwo: undefined
    };
  },
  methods: {
    getPlayer(playerToFind) {
      return _.find(
        _.values(this.pList),
        player => player.Name === playerToFind
      );
    }
  },
  components: {
    PlayerDetails
  }
};
</script>
<style lang="scss" scoped></style>
