export function nameToInitial(fullname) {
  const splitname = fullname.split(" ");
  splitname[0] = splitname[0].charAt(0) + ".";
  return splitname.join(" ");
}

export function toNumber(myString, useMax = false) {
  const nanFallback = useMax ? 999999.0 : -999999.0;
  const isNumber = /\d/.test(myString);

  return isNumber ? parseFloat(myString) : nanFallback;
}

export function capitalize(s) {
  if (!s) return;
  if (typeof s !== "string") return "";
  return s
    .split(" ")
    .map(sub => sub[0].toUpperCase() + sub.substring(1).toLowerCase())
    .join(" ");
}

export function flattenObjectOneLevel(object) {
  let value = {};
  for (var prop in object) {
    if (typeof object[prop] == "object") {
      for (var p in object[prop]) {
        value[p] = object[prop][p];
      }
    } else {
      value[prop] = object[prop];
    }
  }
  return value;
}
