<template>
  <div style="width: 100%">
    <span> {{ desc }}: </span>
    <a-input-number
      style="width: 80%"
      :disabled="disabled"
      :id="desc"
      v-model:value="value"
      :min="0"
      @change="$emit('num-updated', value)"
    />
  </div>
</template>
<script>
export default {
  props: ["desc", "disabled"],
  emits: ["num-updated"],
  data() {
    return {
      value: 0
    };
  }
};
</script>
