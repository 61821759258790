<template>
  <div class="ta-page">
    <div v-for="(team, index) in tradingTeams" :key="index">
      <a-row type="flex" justify="space-between" align="middle">
        <h2>{{ team }} get...</h2>
        <div>
          <img
            height="64"
            v-if="teams[team]"
            alt="example"
            :src="teams[team].Logo"
          />
          <img
            height="64"
            v-else
            src="https://cdn.iconscout.com/icon/free/png-256/basketball-2062459-1740039.png"
          />
          <h2>New Total Salary: {{ getTotalSalary(team, index) }}</h2>
          <h2>New Total Minutes: {{ getTotalMinutes(team, index) }}</h2>
        </div>
      </a-row>
      <a-row type="flex" justify="space-between" class="asset-selector">
        <a-col :xs="24" :md="4">
          <Lookup
            :noTags="true"
            :options="
              Object.keys(teams).filter(team => !tradingTeams.includes(team))
            "
            placeholder="Select Team"
            @option-selected="updateSelectedTeam(index, $event)"
          >
          </Lookup>
        </a-col>
        <a-col :xs="24" :md="4">
          <Lookup
            :disabled="!teams[team]"
            :options="gettablePlayers(team)"
            :givenValue="getPlayers[index].map(p => p.Name)"
            @option-selected="updateGetPlayers(index, $event)"
            placeholder="Select Players"
          >
          </Lookup>
        </a-col>
        <a-col :xs="24" :md="4">
          <Lookup
            :disabled="!teams[team]"
            :options="gettableCash(team)"
            :givenValue="getCash[index].map(p => p.name)"
            @option-selected="updateGetCash(index, $event)"
            placeholder="Add Cash"
          >
          </Lookup>
        </a-col>
        <a-col :xs="24" :md="4">
          <Lookup
            :disabled="!teams[team]"
            :options="gettableAssets(team)"
            :givenValue="getAssets[index].map(p => p.Name)"
            @option-selected="updateGetAssets(index, $event)"
            placeholder="Select Picks"
          >
          </Lookup>
        </a-col>
        <a-col :xs="24" :md="4">
          <Lookup
            :disabled="!teams[team]"
            :options="gettableCamps(team)"
            :givenValue="getCamps[index].map(p => p.Name)"
            @option-selected="updateGetCamps(index, $event)"
            placeholder="Select Camps"
          >
          </Lookup>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-card
          hoverable
          style="width: 200px; border-radius: 25px; margin: 10px;"
          bodyStyle="padding: 5px"
          v-for="(content, name, index) in getPlayers[index]"
          :key="name"
          @click="updateCurrentExpandedGet(content)"
        >
          <template #cover>
            <img alt="example" :src="content.Image" />
          </template>

          <h3 :class="content.Team">{{ content.Team }}</h3>
          <h3 class="cash-value">${{ parseInt(content["TriKov Value"]) }}</h3>
          <div class="overall-rtng">{{ content.Overall }}</div>
        </a-card>
        <a-card
          hoverable
          style="width: 200px; border-radius: 25px; margin: 10px;"
          bodyStyle="padding: 5px"
          v-for="(content, name, index) in getAssets[index]"
          :key="name"
        >
          <template #cover>
            <img
              alt="example"
              style="height: 240px"
              :src="this.teams[content.From].Logo"
            />
          </template>

          <h3 :class="content.Owner">{{ content.Owner }}</h3>
          <h3 class="cash-value">${{ parseInt(content["TriKov Value"]) }}</h3>
          <div class="overall-rtng">
            Round {{ content.Name.charAt(0) }} Pick
          </div>
        </a-card>
        <a-card
          hoverable
          style="width: 200px; border-radius: 25px; margin: 10px;"
          bodyStyle="padding: 5px"
          v-for="(content, index) in getCash[index]"
          :key="content.name"
        >
          <template #cover>
            <img
              alt="example"
              style="height: 240px"
              :src="this.teams[content.name].Logo"
            />
          </template>

          <h3 :class="content.name">{{ content.name }}</h3>
          <h3 class="cash-value">
            $<a-input-number
              id="inputNumber"
              v-model:value="content.value"
              :min="1"
              :max="50"
            />
          </h3>
        </a-card>
        <a-card
          hoverable
          style="width: 200px; border-radius: 25px; margin: 10px;"
          bodyStyle="padding: 5px"
          v-for="(content, name, index) in getCamps[index]"
          :key="name"
        >
          <template #cover>
            <img
              alt="example"
              style="height: 240px"
              :src="this.teams[content.From].Logo"
            />
          </template>

          <h3 :class="content.Owner">{{ content.Owner }}</h3>
          <h3 class="cash-value">$5</h3>
          <div class="overall-rtng">{{ content.Name.split("-")[1] }}</div>
        </a-card>
        <a-row :span="24">
          <PlayerDetails
            class="expanded-details"
            v-if="this.currentExpandedGet"
            :player="this.currentExpandedGet"
            :showBasicInfo="true"
          />
        </a-row>
      </a-row>
      <div class="valuation">
        <h2>TriKov Net Trade Value: ${{ netTriKovVal(index) }}</h2>
      </div>
      <a-divider />
    </div>
    <h3>Comments</h3>
    <a-textarea v-model:value="comments" show-count :maxlength="500" />
    <div>
      <a-button
        class="add-button"
        v-on:click="addTradingTeam"
        :disabled="tradingTeams.length > 3"
        >Add Team</a-button
      ><a-button
        class="add-button"
        v-on:click="removeTradingTeam"
        :disabled="tradingTeams.length < 3"
        >Remove Team</a-button
      >
      <a-button
        v-on:click="submitTradeRequest"
        :disabled="!(teams[tradingTeams[0]] && teams[tradingTeams[1]])"
        >Submit Trade Request</a-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import PlayerDetails from "@/components/PlayerDetails.vue";
import Lookup from "@/components/Lookup.vue";
import NumInput from "@/components/NumInput.vue";
import { nameToInitial } from "@/utils/convertHelper.js";
import _ from "lodash";
export default {
  name: "Trade Analyzer",
  data() {
    return {
      tradingTeams: ["Team 1", "Team 2"],
      maxTeams: 4,
      currentKey: "Current",
      getPlayers: [[], [], [], []],
      getAssets: [[], [], [], []],
      getCash: [[], [], [], []],
      getCamps: [[], [], [], []],
      currentExpandedGive: null,
      currentExpandedGet: null,
      comments: ""
    };
  },
  computed: {
    ...mapState(["pList", "teams", "currentTeam"]),
    playerNames() {
      return _.values(this.pList).map(player => player.Name);
    },
    tradableAssets() {
      return Object.assign(
        {},
        ..._.values(this.teams).map(team => team["Draft Picks"])
      );
    },
    tradableAssetsNames() {
      return _.keys(this.tradableAssets);
    },
    tradableCamps() {
      return _.values(this.teams)
        .map(team => team["Camps"])
        .flat();
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  methods: {
    isMobile() {
      return window.innerWidth < 500;
    },
    inFrozenTeam(player) {
      return this.teams[player.Team]
        ? this.teams[player.Team].Frozen === "TRUE"
        : false;
    },
    updateGetPlayers(selectedTeam, getPlayers) {
      this.getPlayers[selectedTeam] = getPlayers.map(
        player => this.pList[nameToInitial(player)]
      );
    },
    updateGetAssets(selectedTeam, getAssets) {
      this.getAssets[selectedTeam] = getAssets.map(
        asset => this.tradableAssets[asset]
      );
    },
    updateGetCash(selectedTeam, getCash) {
      this.getCash[selectedTeam] = getCash.map(team => {
        return {
          name: team,
          value: 10
        };
      });
    },
    updateGetCamps(selectedTeam, getCamps) {
      this.getCamps[selectedTeam] = getCamps.map(camp =>
        _.find(this.tradableCamps, c => c.Name === camp)
      );
    },
    updateSelectedTeam(idx, team) {
      this.tradingTeams[idx] = team;
      this.getPlayers[idx] = [];
      this.getAssets[idx] = [];
      this.getCash[idx] = [];
    },
    assetsHeaders() {
      return _.keys(_.values(this.tradableAssets)[0]).map(header => {
        return {
          title: header,
          dataIndex: header,
          key: header,
          slots: { customRender: header || "column" }
        };
      });
    },
    gettablePlayers(selectedTeam) {
      return _.values(this.pList)
        .filter(player => {
          return (
            this.tradingTeams.includes(player.Team) &&
            player.Team !== selectedTeam
          );
        })
        .map(player => player.Name);
    },
    gettableAssets(selectedTeam) {
      return _.values(this.tradableAssets)
        .filter(
          asset =>
            asset.Owner !== selectedTeam &&
            this.tradingTeams.includes(asset.Owner)
        )
        .map(asset => asset.Name);
    },
    gettableCamps(selectedTeam) {
      return _.values(this.tradableCamps)
        .filter(
          camp =>
            camp.Owner !== selectedTeam &&
            this.tradingTeams.includes(camp.Owner)
        )
        .map(camp => camp.Name);
    },
    gettableCash(selectedTeam) {
      return this.tradingTeams.filter(
        team => team !== selectedTeam && _.keys(this.teams).includes(team)
      );
    },
    netTriKovVal(selectedTeam) {
      const getPlayers = this.getPlayers[selectedTeam] || [];
      const getAssets = this.getAssets[selectedTeam] || [];
      const getCash = this.getCash[selectedTeam] || [];

      const otherTeams = this.tradingTeams.filter(
        team => team !== selectedTeam
      );

      let giveVal = 0;

      for (const team of otherTeams) {
        const players = this.getPlayers[team] || [];
        const assets = this.getAssets[team] || [];
        const cash = this.getCash[team] || [];

        giveVal =
          giveVal +
          players
            .filter(player => player.Team === selectedTeam)
            .map(player => parseInt(player["TriKov Value"]))
            .reduce((a, b) => a + b, 0) +
          assets
            .filter(asset => asset.Owner === selectedTeam)
            .map(asset => parseInt(asset["TriKov Value"]))
            .reduce((a, b) => a + b, 0) +
          cash
            .filter(cashObj => cashObj.name === selectedTeam)
            .map(cashObj => parseInt(cashObj.value))
            .reduce((a, b) => a + b, 0);
      }

      const getVal =
        getPlayers
          .map(player => parseInt(player["TriKov Value"]))
          .reduce((a, b) => a + b, 0) +
        getAssets
          .map(asset => parseInt(asset["TriKov Value"]))
          .reduce((a, b) => a + b, 0) +
        getCash
          .map(cashObj => parseInt(cashObj.value))
          .reduce((a, b) => a + b, 0);
      return parseInt(getVal) - parseInt(giveVal);
    },

    updateCurrentExpandedGive(player) {
      if (this.currentExpandedGive === player) {
        this.currentExpandedGive = null;
      } else {
        this.currentExpandedGive = player;
      }
    },
    updateCurrentExpandedGet(player) {
      if (this.currentExpandedGet === player) {
        this.currentExpandedGet = null;
      } else {
        this.currentExpandedGet = player;
      }
    },
    addTradingTeam() {
      this.tradingTeams.push("Team " + (this.tradingTeams.length + 1));
    },
    removeTradingTeam() {
      this.tradingTeams.pop();
    },
    submitTradeRequest() {
      const trade = {
        id: Date.now(),
        Date: new Date().toISOString().slice(0, 10),
        "Team 1": this.tradingTeams[0],
        "Team 2": this.tradingTeams[1],
        "Team 3": this.tradingTeams[2] || "",
        "Team 4": this.tradingTeams[3] || "",
        "Team 1 receives": {
          players: this.getPlayers[0].map(p => p.Name),
          assets: this.getAssets[0],
          cash: this.getCash[0],
          camps: this.getCamps[0]
        },
        "Team 2 receives": {
          players: this.getPlayers[1].map(p => p.Name),
          assets: this.getAssets[1],
          cash: this.getCash[1],
          camps: this.getCamps[1]
        },
        "Team 3 receives": this.tradingTeams[2]
          ? {
              players: this.getPlayers[2].map(p => p.Name),
              assets: this.getAssets[2],
              cash: this.getCash[2],
              camps: this.getCamps[2]
            }
          : "",
        "Team 4 receives": this.tradingTeams[3]
          ? {
              players: this.getPlayers[3].map(p => p.Name),
              assets: this.getAssets[3],
              cash: this.getCash[3],
              camps: this.getCamps[3]
            }
          : "",
        Status: "PENDING",
        "Proposed By": this.currentTeam,
        Comments: this.comments
      };

      this.addTradeRequest(trade);
      this.$router.push("trade_requests");
    },
    getTotalSalary(team, index) {
      const getSalary = playerList =>
        _.values(playerList).reduce((prev, curr) => {
          const contract = curr["Contract Offer"];
          return prev + parseInt(_.get(contract, ["Cash"], 0));
        }, 0);

      const oldSalary = getSalary(
        _.values(this.pList).filter(player => player.Team === team)
      );

      const tradePlayers = [...this.getPlayers];
      const playersToGet = tradePlayers.splice(index, 1);
      return (
        oldSalary -
        getSalary(tradePlayers.flat().filter(player => player.Team === team)) +
        getSalary(playersToGet[0])
      );
    },
    getTotalMinutes(team, index) {
      const getMinutes = playerList =>
        _.values(playerList).reduce((prev, curr) => {
          const contract = curr["Contract Offer"];
          return prev + parseInt(_.get(contract, ["Minutes"], 0));
        }, 0);

      const oldMinutes = getMinutes(
        _.values(this.pList).filter(player => player.Team === team)
      );

      const tradePlayers = [...this.getPlayers];
      const playersToGet = tradePlayers.splice(index, 1);
      return (
        oldMinutes -
        getMinutes(tradePlayers.flat().filter(player => player.Team === team)) +
        getMinutes(playersToGet[0])
      );
    },
    ...mapActions({
      addTradeRequest: "addTradeRequest"
    })
  },
  components: {
    Lookup,
    NumInput,
    PlayerDetails
  },
  props: {
    team: String
  }
};
</script>

<style scoped>
.list-item {
  padding: 0;
}

.switch {
  padding: 10px;
}

.team-column {
  margin: -8px -8px;
  padding: 8px;
}

.attr-container {
  background-color: white;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  transition: 0.2s;
  border: 1px solid lightgray;
}

.attr-value {
  font-weight: bold;
  font-size: 15px;
  width: 30px;
  height: 30px;
  margin: 5px;
  color: white;
  text-align: center;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  border-radius: 25px;
}

.attr-name {
  font-weight: bold;
  height: 30px;
  margin: 5px;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.attr-item {
  display: flex;
  margin: 5px;
}

.attr-title {
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  text-align: left;
  margin: 5px;
}

.asset-selector {
  margin: 10px;
}

.overall-rtng {
  position: absolute;
  right: 5px;
  top: 5px;
  color: white;
  border-radius: 10px;
  border: 3px solid #fff;
  font-weight: bold;
  font-size: 26px;
  padding: 5px;
  background-color: #2c3e50;
}

.cash-value {
  font-weight: bold;
  font-size: 26px;
}

.expanded-details {
  padding: 10px;
  text-align: left;
}

.h2 {
  text-align: left;
  vertical-align: center;
}

.ta-page {
  padding: 20px;
}

.valuation {
  margin-top: 20px;
}

.add-button {
  margin-right: 10px;
}
</style>
