<template>
  <a-select
    v-model:value="value"
    :mode="noTags ? null : 'tags'"
    style="width: 100%"
    :placeholder="placeholder"
    :disabled="disabled"
    @change="$emit('option-selected', value)"
  >
    <a-select-option
      v-for="(option, index) in options"
      :key="option + index"
      :value="option"
    >
      {{ option }}
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  props: ["options", "placeholder", "noTags", "disabled", "givenValue"],
  emits: ["option-selected"],
  data() {
    return {
      value: []
    };
  },
  watch: {
    givenValue: {
      immediate: true,
      handler(val, oldVal) {
        this.value = val;
      },
      deep: true
    }
  },
  methods: {}
};
</script>

<style scoped></style>
