<template>
  <a-modal
    :visible="visible"
    :destroyOnClose="true"
    :onCancel="handleCancel"
    :closable="true"
    :footer="null"
  >
    <h3 v-if="playerToAdd">
      Select someone to drop to for
      {{ playerToAdd.Name }} {{ teamPlayers.length > 12 ? "" : "(Optional)" }}
    </h3>
    <h3 v-else>
      Select someone to drop (must have 13 at start of season)
    </h3>
    <a-row display="flex" justify="center">
      <a-col :md="2">
        <h3
          class="position"
          :style="{ lineHeight: '60px' }"
          v-for="position in positions"
          v-bind:key="position"
        >
          {{ position }}
        </h3>
      </a-col>
      <a-col :md="22">
        <a-card
          :bodyStyle="{
            padding: '3px'
          }"
          v-for="player in teamPlayers"
          :key="player"
        >
          <a-row class="player-info" display="flex" justify="space-between">
            <a-col :span="14">
              <div class="trait-name">
                Name
                <a-popover placement="left" :title="player.Name">
                  <template #content>
                    <img class="preview-image" :src="player.Image" />
                  </template>
                  <span><camera-outlined /></span>
                </a-popover>
              </div>
              {{ player.Name }}
            </a-col>
            <a-col :span="10">
              <div class="trait-name">
                Actions
              </div>
              <a-button
                :onClick="() => dropPlayer(player)"
                type="primary"
                size="small"
                danger
              >
                Drop Player ({{ getCost(player) }})
              </a-button>
            </a-col>
          </a-row>
        </a-card>
        <a-row
          class="add-without-dropping-btn"
          display="flex"
          justify="center"
          v-if="playerToAdd"
        >
          <a-button
            :onClick="() => addPlayer(player)"
            type="primary"
            size="medium"
          >
            Add Player without Dropping
          </a-button>
        </a-row>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
const positions = [
  "PG",
  "SG",
  "SF",
  "PF",
  "C",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "RES"
];

import { mapState, mapActions } from "vuex";
import _ from "lodash";
import Draggable from "vuedraggable";
import { CameraOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

export default {
  name: "RosterEditor",
  props: ["team", "playerToAdd", "handleCancel", "visible"],
  computed: {
    ...mapState(["pList", "pickupVisible"]),
    teamPlayers() {
      const setTeam = this.team;
      return _.values(
        _.pickBy(this.pList, function(value, key) {
          return value.Team === setTeam;
        })
      );
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  setup() {
    return {
      positions: [
        "PG",
        "SG",
        "SF",
        "PF",
        "C",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "RES"
      ]
    };
  },
  data() {
    return {
      pagination: {
        showSizeChanger: true,
        buildOptionText: props => {
          return <span>{props.value}</span>;
        }
      },
      roleList: []
    };
  },

  mounted() {
    this.initRoleList();
  },
  methods: {
    difference: function(object, base) {
      function changes(object, base) {
        return _.transform(object, function(result, value, key) {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              _.isObject(value) && _.isObject(base[key])
                ? changes(value, base[key])
                : value;
          }
        });
      }
      return changes(object, base);
    },
    getCost(player) {
      const contract = player["Contract Offer"];

      if (!contract) return "No Penalty";

      return `$${Math.ceil(
        (parseInt(contract.Cash) + parseInt(contract.Minutes)) / 3
      )}`;
    },
    initRoleList: function() {
      const entryDataObj = {
        primaryPostion: 1,
        secondaryPosition: 0,
        minutes: 1,
        touches: 1,
        playInitiator: false,
        playOne: 0,
        playTwo: 0,
        playThree: 0,
        playFour: 0,
        role: 0
      };

      this.roleList = _.cloneDeep(this.teamPlayers)
        .map(player => {
          const initData = _.cloneDeep(entryDataObj);
          const vitals = player.Data.VITALS;
          const tendencies = player.Data.TENDENCIES;

          initData.primaryPostion = vitals.POSITION;
          initData.secondaryPosition = vitals.SECONDARY_POSITION;
          initData.minutes = parseInt(vitals.MINUTES_IN_ROTATION);
          initData.touches = parseInt(tendencies.TOUCHES_TENDENCY);
          initData.playInitiator = vitals.PLAY_INITIATOR === "1";
          initData.playOne = vitals["PLAY_TYPE_1"];
          initData.playTwo = vitals["PLAY_TYPE_2"];
          initData.playThree = vitals["PLAY_TYPE_3"];
          initData.playFour = vitals["PLAY_TYPE_4"];
          initData.role = parseInt(player.Role);

          if (initData.role === 13) {
            initData.minutes = 0;
          }

          if (initData.role < 5) {
            Math.max(initData.minutes, 1);
          }

          return {
            player: player,
            entryData: _.cloneDeep(initData)
          };
        })
        .sort((a, b) => {
          return a.entryData.role - b.entryData.role;
        });
    },
    async dropPlayer(player) {
      await this.refreshPlayers();

      if (!this.playerToAdd) {
        this.handleCancel();
        this.pickupPlayer({
          playerToDrop: player.Name
        });
      } else if (
        this.playerToAdd.Team !== "FA" &&
        this.playerToAdd.Team !== "Rookie"
      ) {
        message.error("Sorry, another team has already signed this player.");
      } else {
        this.handleCancel();
        this.pickupPlayer({
          playerToAdd: this.playerToAdd.Name,
          playerToDrop: player.Name
        });
      }
    },
    async addPlayer(player) {
      await this.refreshPlayers();

      if (
        this.playerToAdd.Team !== "FA" &&
        this.playerToAdd.Team !== "Rookie"
      ) {
        message.error("Sorry, another team has already signed this player.");
      } else {
        this.handleCancel();
        this.pickupPlayer({
          playerToAdd: this.playerToAdd.Name
        });
      }
    },
    ...mapActions({
      pickupPlayer: "pickupPlayer",
      refreshPlayers: "refreshPlayers"
    })
  },
  components: {
    Draggable,
    CameraOutlined
  }
};
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}
.position {
  margin-bottom: 0px;
  text-align: center;
}
.trait-name {
  font-size: 10px;
  color: gray;
  text-align: left;
}
.player-info {
  text-align: left;
  padding: 6px;
}
.preview-image {
  max-width: 200px;
}
.add-without-dropping-btn {
  margin-top: 20px;
}
</style>
