<template>
  <div class="players">
    <PlayerTable v-bind:players="leaguePlayers"></PlayerTable>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import _ from "lodash";
import PlayerTable from "@/components/PlayerTable.vue";
export default {
  computed: {
    ...mapState(["pList"]),
    leaguePlayers() {
      return _.pickBy(this.pList, function(value, key) {
        return value.Team !== "Rookie";
      });
    }
  },
  name: "Players",
  components: {
    PlayerTable
  }
};
</script>
