<template>
  <TendenciesTable />
</template>

<script>
import TendenciesTable from "../components/TendenciesTable.vue";

export default {
  name: "Tendency Mappings",
  components: { TendenciesTable }
};
</script>

<style scoped></style>
