<!-- 
# Badge Training 
  #Calculates and lists all the avalible retired player for that team
  #Also compute which were hall of fame badge

  # Listed as such 

  Mentor | Player | Result |Edit | 

  #Mentor (player giving the badge)
  
  # Player (player recieving)
  
  #result (The final Badge level for the recieving player )

  #Edit  
    Launches Camp Badges Editor 
 -->

<template>
  <div>
    <h1>Badge Mentorship</h1>
    <a-table
      class="badge-camp-table"
      :columns="columns"
      :dataSource="dummy"
      :pagination="false"
      :bordered="false"
    >
      <template #bodyCell="{ column}">


        <template v-if="column.title === 'Mentor'">
          <a-select placeholder="Select Mentor"> </a-select>
        </template>
        <template v-else-if="column.title === 'Mentee'">
          <a-select placeholder="Select Mentee"> </a-select>
        </template>
        <template v-else-if="column.title === 'Badge'">
          <a-select placeholder="Select Badge"> </a-select>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "BadgeCampTable",
  data() {
    return {
      columns: [
        {
          title: "Mentor",
          dataIndex: "mentor",
          key: "mentor"
        },
        {
          title: "Mentee",
          dataIndex: "mentee",
          key: "mentee"
        },
        {
          title: "Badge",
          dataIndex: "badge",
          key: "badge"
        },
        {
          title: "Current Badge",
          dataIndex: "current",
          key: "current"
        },
        {
          title: "Resulting Badge",
          dataIndex: "result",
          key: "result"
        }
      ],
      dummy: [
        {
          key: "1001",
          mentor: "Gordon",
          player: "Kirkland",
          result: "generic 1"
        },
        {
          key: "1002",
          mentor: "Jones",
          player: "Smith",
          result: "generic2"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
