<template>
  <TrainingCampTable />
</template>

<script>
import TrainingCampTable from "../components/TrainingCampTable.vue";
import BadgeCampTable from "../components/BadgeCampTable.vue";

export default {
  name: "Offseason Training",
  components: {
    TrainingCampTable,
    BadgeCampTable
  }
};
</script>

<style scoped></style>

<!-- 

<template>
  <a-table :columns="columns" :data-source="ots" :pagination="false">
    <template #bodyCell="{ text, column, record, index }">
      <template v-if="column.isTeam">
        <a-select
          placeholder="Select Player  SADASDASD"
          ref="select"
          :dropdownMatchSelectWidth="false"
          v-model:value="ots[index][column.title]"
          :options="playerOptions"
        ></a-select>
      </template>
    </template>
  </a-table>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import { message } from "ant-design-vue";
import { CameraOutlined } from "@ant-design/icons-vue";
import _ from "lodash";

export default {
  name: "Offseason Training",
  data() {
    return {
      columns: [
        {
          title: "Type",
          dataIndex: "Type",
          key: "Type"
        },
        {
          title: "Category",
          dataIndex: "Category",
          key: "Category"
        }
      ],
      playerOptions: [],
      campValues: {}
    };
  },
  computed: {
    ...mapState(["pList", "ots", "teams", "currentTeam"]),
    teamsList() {
      return _.keys(this.teams).map(team => {
        return {
          label: team,
          value: team
        };
      });
    },

    myPlayers() {
      return _.values(this.pList).filter(
        player => player.Team === this.currentTeam
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event("resize"));
    });

    this.columns = this.columns.concat(
      _.keys(this.teams).map(team => {
        return {
          title: team,
          dataIndex: team,
          key: team,
          isTeam: true
        };
      })
    );

    this.playerOptions = this.myPlayers.map(player => {
      return {
        label: player.Name,
        value: player.Name
      };
    });

    // _.keys(this.teams).forEach(team => {
    //   this.campValues[team] = {
    //     player: "",

    //   }
    // })
  },

  methods: {
    isMobile() {
      return window.innerWidth < 500;
    },
    inFrozenTeam(player) {
      return this.teams[player.Team]
        ? this.teams[player.Team].Frozen === "TRUE"
        : false;
    },
    handleSubmit: function() {
      this.completeRequests(this.filteredRequestsQueue);
    },
    ...mapActions({
      completeRequests: "completeRequests"
    })
  },
  components: {
    CameraOutlined
  },
  props: {
    team: String
  }
};
</script>

<style scoped>
.list-item {
  padding: 0;
}
.switch {
  padding: 10px;
}
.checkbox-container {
  text-align: center;
}
.position {
  margin-bottom: 0px;
  text-align: center;
}
.trait-name {
  font-size: 10px;
  color: gray;
  text-align: left;
}
.player-info {
  text-align: left;
  padding: 6px;
}
.preview-image {
  max-width: 200px;
}
.ant-slider-track {
  pointer-events: none;
}
.team-select-container {
  padding: 5px;
}
.submit-button {
  padding: 10px;
}
.fs-page {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>

 -->
