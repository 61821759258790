<template>
  <div>
    <h1>Training Camp</h1>
    <a-table
      class="training-camp-table"
      :rowKey="(record, index) => record.key + index"
      :expandedRowKeys="expandedRows"
      :columns="columns"
      :dataSource="tableSource"
      :pagination="false"
      :bordered="false"
      :expandIcon="
        () => {
          return null;
        }
      "
    >
      <template #bodyCell="{column, record, index}">
        <template v-if="column.title === 'Player'">
          <a-select
            placeholder="Select Player"
            ref="select"
            :dropdownMatchSelectWidth="false"
            :options="
              record.type === 'badge'
                ? badgePlayerOptions(record)
                : campPlayerOptions
            "
            v-model:value="record.player"
            :onChange="label => expandRow(label, record, index)"
            :allowClear="true"
          >
          </a-select>
        </template>

        <template v-else-if="column.title === 'Status'">
          <a-tag :color="getSliderValid(record, index) ? 'green' : 'red'">
            {{ getSliderValid(record, index) ? "Valid" : "Invalid" }}
          </a-tag>
        </template>
      </template>

      <template #expandedRowRender=" { record, index } ">
        <div v-if="record.type === 'badge'">
          <a-row display="flex" justify="start">
            {{ record.category }} {{ record.player }}
          </a-row>
          <a-divider />

          <a-row display="flex" justify="start">
            <a-col :span="4">
              <div class="trait-name">Mentor</div>
              <a-select
                placeholder="Select Mentor"
                :disabled="badgeArray[index - myCamps().length].retired"
                ref="select"
                :dropdownMatchSelectWidth="false"
                :options="
                  suitableMentors.filter(
                    mentor => mentor.label !== record.player
                  )
                "
                v-model:value="badgeArray[index - myCamps().length].mentor"
                :allowClear="true"
              >
              </a-select>
            </a-col>
            <a-col :span="12">
              <div class="trait-name">Badge to Teach</div>
              <a-select
                placeholder="Select Badge"
                ref="select"
                :dropdownMatchSelectWidth="false"
                :options="
                  getSuitableBadges(
                    badgeArray[index - myCamps().length].mentor,
                    record.player
                  )
                "
                v-model:value="
                  badgeArray[index - myCamps().length].badges[0].key
                "
                :allowClear="true"
              >
              </a-select>
            </a-col>

            <a-col :span="4">
              <div class="trait-name">Old Badge Value</div>
              {{
                badgeLevelToDesc(
                  getBadgeLevel(
                    record.player,
                    badgeArray[index - myCamps().length].badges[0].key
                  )
                )
              }}
            </a-col>
            <a-col :span="4">
              <div class="trait-name">New Badge Value</div>
              {{
                badgeLevelToDesc(
                  getBadgeLevel(
                    record.player,
                    badgeArray[index - myCamps().length].badges[0].key
                  ) +
                    (getBadgeLevel(
                      record.player,
                      badgeArray[index - myCamps().length].badges[0].key
                    ) +
                      2 >
                    4
                      ? 1
                      : 2)
                )
              }}
            </a-col>
          </a-row>
          <a-row
            display="flex"
            justify="start"
            v-if="
              getBadgeLevel(
                record.player,
                badgeArray[index - myCamps().length].badges[0].key
              ) +
                2 >
                4
            "
          >
            <a-col :span="4">
              <div />
            </a-col>
            <a-col :span="12">
              <div class="trait-name">Secondary Badge to Teach</div>
              <a-select
                placeholder="Select Badge"
                ref="select"
                :dropdownMatchSelectWidth="false"
                :options="
                  getSuitableBadges(
                    badgeArray[index - myCamps().length].mentor,
                    record.player
                  )
                "
                v-model:value="
                  badgeArray[index - myCamps().length].badges[1].key
                "
                :allowClear="true"
              >
              </a-select>
            </a-col>

            <a-col :span="4">
              <div class="trait-name">Old Badge Value</div>
              {{
                badgeLevelToDesc(
                  getBadgeLevel(
                    record.player,
                    badgeArray[index - myCamps().length].badges[1].key
                  )
                )
              }}
            </a-col>
            <a-col :span="4">
              <div class="trait-name">New Badge Value</div>
              {{
                badgeLevelToDesc(
                  getBadgeLevel(
                    record.player,
                    badgeArray[index - myCamps().length].badges[1].key
                  ) + 1
                )
              }}
            </a-col>
          </a-row>
        </div>

        <div v-else>
          <a-row display="flex" justify="start">
            {{ record.category }} {{ record.player }}
          </a-row>
          <a-divider />

          <template
            v-for="(attribute, attributeIdx) in getCampAttributes(
              record.category
            )"
            :key="attribute"
          >
            <a-row display="flex" justify="start">
              <a-col :span="4">
                <div v-if="record.player">
                  <div class="trait-name">{{ attribute }}</div>
                  {{ getPlayerAttribute(record.player, attribute) }}
                </div>
              </a-col>
              <a-col :span="12">
                <a-slider
                  id="attribute-slider"
                  v-model:value="
                    campArray[index].attributes[attributeIdx].value
                  "
                  :min="0"
                  :max="getCampMaxAdd(record.category)"
                />
              </a-col>
              <a-col :span="4">
                <a-input-number
                  v-model:value="
                    campArray[index].attributes[attributeIdx].value
                  "
                  :min="0"
                  :max="getCampMaxAdd(record.category)"
                  style="margin-left: 16px"
                />
              </a-col>

              <a-col :span="4">
                <div class="trait-name">Updated {{ attribute }}</div>
                {{
                  campArray[index].attributes[attributeIdx].value +
                    getPlayerAttribute(record.player, attribute)
                }}
              </a-col>
            </a-row>
          </template>
        </div>
      </template>
    </a-table>
    <a-row display="flex" justify="center">
      <a-button
        :onClick="submitTraining"
        :style="{ margin: '10px' }"
        size="large"
        type="primary"
        :disabled="disableSubmitButton()"
        >Submit</a-button
      >
    </a-row>
  </div>
</template>

<script>
import { flattenObjectOneLevel } from "../utils/convertHelper";
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import { ConsoleSqlOutlined } from "@ant-design/icons-vue";

export default {
  name: "TrainingCampTable",
  components: {},
  data() {
    return {
      columns: [
        {
          title: "Team",
          dataIndex: "team",
          key: "team"
        },
        {
          title: "Category",
          dataIndex: "category",
          key: "category"
        },
        {
          title: "Player",
          dataIndex: "player",
          key: "player"
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" }
        }
      ],
      campArray: [],
      badgeArray: [],
      expandedRows: []
    };
  },

  mounted() {
    this.initCampArray();
    this.initBadgeArray();
  },
  methods: {
    initCampArray() {
      this.campArray = this.myCamps().map(camp => {
        const category = camp.Name.split("-")[1];
        return {
          name: camp.Name,
          category: category,
          player: "",
          tab: this.getTab(category),
          attributes: this.getCampAttributes(category).map(attribute => {
            return {
              key: attribute,
              value: 0
            };
          })
        };
      });
    },
    initBadgeArray() {
      const standardBadgeArray = this.myBadges().map(badge => {
        return {
          name: badge.Name,
          player: "",
          mentor: undefined,
          tab: "Badges",
          badges: [
            { key: undefined, value: 2 },
            { key: undefined, value: 1 }
          ],
          retired: false
        };
      });

      const retiredBadgeArray = this.myRetiringPlayers().map((player, idx) => {
        return {
          name: player.Name + idx,
          player: "",
          mentor: player.Name,
          tab: "Badges",
          badges: [
            {
              key: undefined,
              value: 2
            },
            {
              key: undefined,
              value: 1
            }
          ],
          retired: true
        };
      });

      this.badgeArray = standardBadgeArray.concat(retiredBadgeArray);
    },
    expandRow(label, record, idx) {
      if (record.type === "badge") {
        const targetedMentorship = _.find(
          this.badgeArray,
          badge => badge.name === record.key
        );
        if (label) {
          this.expandedRows.push(record.key + idx);
          targetedMentorship.player = record.player;
        } else {
          _.remove(this.expandedRows, cat => cat === record.key + idx);
          targetedMentorship.player = "";
        }
      } else {
        const targetedCamp = _.find(
          this.campArray,
          camp => camp.name === record.key
        );
        if (label) {
          this.expandedRows.push(record.key + idx);
          targetedCamp.player = record.player;
        } else {
          _.remove(this.expandedRows, cat => cat === record.key + idx);
          targetedCamp.player = "";
        }
      }
    },

    getCampAttributes(campCategory) {
      return _.find(this.ots, category => category.Category === campCategory)
        .Options;
    },

    getCampMaxAdd(campCategory) {
      return parseInt(
        _.find(this.ots, category => category.Category === campCategory).Max
      );
    },

    getTab(campCategory) {
      return _.find(this.ots, category => category.Category === campCategory)
        .Tab;
    },
    getPlayerAttribute(playerName, attribute) {
      if (!playerName) return undefined;
      const playerObj = _.find(
        this.pList,
        player => player.Name === playerName
      );
      if (attribute === "Weight") {
        const weight = playerObj.Data.VITALS.WEIGHT_LBS;
        return parseInt(weight);
      }
      const attributes = playerObj.Data.ATTRIBUTES;
      const flat_attrib = flattenObjectOneLevel(attributes);
      const convertedKey = attribute.toUpperCase().replaceAll(" ", "_");
      const valueObj = flat_attrib[convertedKey].value;
      return Math.round(valueObj / 3 + 25);
    },

    myCamps() {
      const current_team = _.find(
        this.teams,
        team => team.Team === this.currentTeam
      );
      return current_team.Camps;
    },
    myBadges() {
      const current_team = _.find(
        this.teams,
        team => team.Team === this.currentTeam
      );
      return current_team.Mentorships;
    },
    myRetiringPlayers() {
      const current_team = _.find(
        this.teams,
        team => team.Team === this.currentTeam
      );
      return current_team["Retiring Players"];
    },
    getSuitableBadges(mentor, mentee) {
      if (!mentor) return [];

      const myMentor =
        _.find(this.pList, p => p.Name === mentor) ||
        _.find(this.retiringPList, p => p.Name === mentor);

      const badges = _.values(flattenObjectOneLevel(myMentor.Data.BADGES))
        .filter(
          badge =>
            badge.value === "4" &&
            parseInt(this.getBadgeLevel(mentee, badge.name)) < 4
        )
        .map(badge => {
          return {
            label: badge.name,
            value: badge.name
          };
        });
      return badges;
    },
    getBadgeLevel(player, badgeToGet) {
      if (!player || !badgeToGet) return 0;
      const myPlayer = _.find(this.pList, p => p.Name === player);
      const foundBadge = _.find(
        _.values(flattenObjectOneLevel(myPlayer.Data.BADGES)),
        badge => badge.name === badgeToGet
      );
      if (!foundBadge) {
        return 0;
      }

      return parseInt(foundBadge.value);
    },
    getSliderValid(record, index) {
      if (_.isEmpty(this.campArray)) return false;

      if (!record) return 0;
      if (record.type === "badge") {
        return (
          record.player &&
          this.badgeArray[index - this.myCamps().length].mentor &&
          !!this.badgeArray[index - this.myCamps().length].badges[0] &&
          this.badgeArray[index - this.myCamps().length].badges[0].key
        );
      } else {
        return (
          this.campArray[index].attributes.reduce((prev, curr) => {
            return prev + curr.value;
          }, 0) === this.getCampMaxAdd(record.category)
        );
      }
    },

    badgeLevelToDesc(badgeLevel) {
      switch (badgeLevel) {
        case 0:
          return "No Badge";
        case 1:
          return "Bronze";
        case 2:
          return "Silver";
        case 3:
          return "Gold";
        case 4:
          return "Hall Of Fame";
        default:
          return "Hall of Fame";
      }
    },

    disableSubmitButton() {
      return !(
        this.campArray.filter(camp => {
          return (
            !!camp.player &&
            camp.attributes.reduce((prev, curr) => {
              return prev + curr.value;
            }, 0) === this.getCampMaxAdd(camp.category)
          );
        }).length +
          this.badgeArray.filter(badge => {
            return (
              !!badge.player &&
              !!badge.mentor &&
              badge.badges[0].key &&
              badge.badges[0].value
            );
          }).length ===
        this.myCamps().length +
          this.myBadges().length +
          this.myRetiringPlayers().length
      );
    },

    submitTraining() {
      const dataToSend = {};
      const campsToUse = {
        camps: [],
        mentorships: [],
        retiredPlayers: []
      };
      const validCamps = this.campArray.filter(camp => {
        return (
          !!camp.player &&
          camp.attributes.reduce((prev, curr) => {
            return prev + curr.value;
          }, 0) === this.getCampMaxAdd(camp.category)
        );
      });

      const validBadges = this.badgeArray.filter(badge => {
        return (
          !!badge.player &&
          !!badge.mentor &&
          badge.badges[0].key &&
          badge.badges[0].value
        );
      });

      validCamps.forEach(vc => {
        if (!dataToSend[vc.player]) {
          dataToSend[vc.player] = {
            Name: vc.player,
            Attributes: [],
            Badges: [],
            Tendencies: [],
            Vitals: []
          };
        }

        const attributesWithoutWeight = vc.attributes.filter(
          atr => atr.key !== "Weight"
        );
        const weight = _.find(vc.attributes, atr => atr.key === "Weight");
        dataToSend[vc.player].Attributes = dataToSend[
          vc.player
        ].Attributes.concat(
          attributesWithoutWeight.map(atr => {
            return {
              key: atr.key.toUpperCase().replaceAll(" ", "_"),
              value: atr.value
            };
          })
        );

        if (weight) {
          dataToSend[vc.player].Vitals.push({
            key: "WEIGHT_LBS",
            value: weight.value
          });
        }

        campsToUse.camps.push(vc.name);
      });

      validBadges.forEach(vb => {
        if (!dataToSend[vb.player]) {
          dataToSend[vb.player] = {
            Name: vb.player,
            Attributes: [],
            Badges: [],
            Tendencies: [],
            Vitals: []
          };
        }

        dataToSend[vb.player].Badges.push({
          key: vb.badges[0].key.toUpperCase().replaceAll(" ", "_"),
          value: vb.badges[1].key ? 1 : vb.badges[0].value
        });

        if (vb.badges[1].key) {
          dataToSend[vb.player].Badges.push({
            key: vb.badges[1].key.toUpperCase().replaceAll(" ", "_"),
            value: vb.badges[1].value
          });
        }

        campsToUse[vb.retired ? "retiredPlayers" : "mentorships"].push(vb.name);
      });

      this.hitUpdatePlayerApi({
        dataToSend: _.values(dataToSend),
        campsToUse: campsToUse
      });
    },
    badgePlayerOptions(row) {
      if (isNaN(row.key)) {
        return this.myPlayers.map(player => {
          return {
            label: player.Name,
            value: player.Name
          };
        });
      }
      return this.myPlayers
        .map(player => {
          return {
            label: player.Name,
            value: player.Name
          };
        })
        .filter(player => {
          return (
            this.badgeArray.filter(
              b => b.player === player.label && b.retired === false
            ).length < 2
          );
        });
    },
    ...mapActions({
      hitUpdatePlayerApi: "hitUpdatePlayerApi"
    })
  },

  computed: {
    ...mapState(["teams", "pList", "ots", "currentTeam", "retiringPList"]),

    myPlayers() {
      return _.values(this.pList).filter(
        player => player.Team === this.currentTeam
      );
    },

    campSource() {
      return this.myCamps().map(camp => {
        let campCategory = camp.Name.split("-")[1];
        return {
          type: "camp",
          key: camp.Name,
          team: camp.Name.split("-")[0],
          category: campCategory,
          status: "Incomplete"
        };
      });
    },
    badgeSource() {
      return this.myBadges().map(badge => {
        return {
          type: "badge",
          key: badge.Name,
          team: this.currentTeam,
          category: `Mentorship ${badge.Name}`,
          status: "Incomplete"
        };
      });
    },

    retiredBadgeSource() {
      return this.myRetiringPlayers().map((rb, idx) => {
        return {
          type: "badge",
          key: rb.Name + idx,
          team: this.currentTeam,
          category: `Mentorship ${rb.Name}`,
          status: "Incomplete"
        };
      });
    },

    tableSource() {
      return this.campSource
        .concat(this.badgeSource)
        .concat(this.retiredBadgeSource);
    },

    campPlayerOptions() {
      return this.myPlayers
        .map(player => {
          return {
            label: player.Name,
            value: player.Name
          };
        })
        .filter(player => {
          return (
            this.campArray.filter(c => c.player === player.label).length < 2
          );
        });
    },
    suitableMentors(mentee) {
      return this.myPlayers
        .map(player => {
          return {
            label: player.Name,
            value: player.Name
          };
        })
        .filter(player => {
          return (
            this.badgeArray.filter(b => b.mentor === player.label).length < 1 &&
            parseInt(_.find(this.pList, p => p.Name === player.label).Age) >
              1 &&
            player !== mentee
          );
        });
    }
  }
};
</script>

<style scoped></style>
