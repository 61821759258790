<template>
  <div class="fs-page">
    <h3>Step 1: Select the team you are updating.</h3>
    <div class="team-select-container">
      <a-select
        ref="select"
        v-model:value="currentTeam"
        style="width: 200px"
        :options="teamsList"
      ></a-select>
    </div>
    <a-divider />
    <div>
      <h3>Step 1.5: Make sure the Coach is correct.</h3>
      <div class="team-select-container">
        <a-row display="flex" justify="center">
          <a-col :span="20">
            <a-card>
              <a-row display="flex" justify="space-between">
                <a-col :span="4">
                  <div class="trait-name">Name</div>
                  {{ teamCoach.name }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Playbook</div>
                  {{ teamCoach.playbook }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Data</div>
                  <a-button @click="copyCoachData" type="primary"
                    >Copy Coach JSON</a-button
                  >
                </a-col>
              </a-row>
              <a-divider />
              <a-row display="flex" justify="space-between">
                <a-col :span="4">
                  <div class="trait-name">Style 1</div>
                  {{ teamCoach.styleOne }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Style 2</div>
                  {{ teamCoach.styleTwo }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Style 3</div>
                  {{ teamCoach.styleThree }}
                </a-col>
              </a-row>
              <a-divider />
              <a-row display="flex" justify="space-between">
                <a-col :span="4">
                  <div class="trait-name">Inside VS Outside</div>
                  {{
                    insideVsOutsideStyles[parseInt(teamCoach.insideVsOutside)]
                  }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Guards VS Forwards</div>
                  {{
                    guardsVsForwardsStyles[parseInt(teamCoach.guardVsForward)]
                  }}
                </a-col>

                <a-col :span="4">
                  <div class="trait-name">Offense VS Defense</div>
                  {{
                    offenseVsDefenseStyles[parseInt(teamCoach.offenseVsDefense)]
                  }}
                </a-col>
              </a-row>
              <a-divider />
              <a-row display="flex" justify="space-between">
                <a-col :span="4">
                  <div class="trait-name">Offensive Focus</div>
                  {{ teamCoach.oFocus }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Offensive Rebounding</div>
                  {{ teamCoach.oRebound }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Offensive Tempo</div>
                  {{ teamCoach.tempo }}
                </a-col>
              </a-row>
              <a-divider />
              <a-row display="flex" justify="space-between">
                <a-col :span="4">
                  <div class="trait-name">Defensive Focus</div>
                  {{ teamCoach.dFocus }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Defensive Rebounding</div>
                  {{ teamCoach.dRebound }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Defensive Agression</div>
                  {{ teamCoach.dAggression }}
                </a-col>
              </a-row>
              <a-divider />
              <a-row display="flex" justify="start">
                <a-col :span="4">
                  <div class="trait-name">Run Plays</div>

                  {{ teamCoach.runPlays }}
                </a-col>
                <a-col :span="16">
                  <a-slider
                    id="test"
                    :value="parseInt(teamCoach.runPlays)"
                    :min="0"
                    :max="100"
                  />
                </a-col>
              </a-row>
              <a-row display="flex" justify="start">
                <a-col :span="4">
                  <div class="trait-name">Zone Usage</div>

                  {{ teamCoach.zoneUsage }}
                </a-col>
                <a-col :span="16">
                  <a-slider
                    id="test"
                    :value="parseInt(teamCoach.zoneUsage)"
                    :min="0"
                    :max="100"
                  />
                </a-col>
              </a-row>
              <a-row display="flex" justify="start">
                <a-col :span="4">
                  <div class="trait-name">Performance Impact</div>

                  {{ teamCoach.performance }}
                </a-col>
                <a-col :span="16">
                  <a-slider
                    id="test"
                    :value="parseInt(teamCoach.performance)"
                    :min="0"
                    :max="100"
                  />
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <a-divider />
    </div>
    <h3>Step 3: Make sure the rotation for the team is correct</h3>
    <a-row display="flex" justify="center">
      <a-col :md="2">
        <h3
          class="position"
          v-for="position in positions"
          v-bind:key="position"
          :style="{ lineHeight: '67px' }"
        >
          {{ position }}
        </h3>
      </a-col>
      <a-col :md="22">
        <a-card
          v-for="player in teamPlayers"
          :key="player"
          :bodyStyle="{
            padding: '3px'
          }"
        >
          <a-row class="player-info" display="flex" justify="space-between">
            <a-col :span="4">
              <div class="trait-name">
                Name
                <a-popover placement="left" :title="player.Name">
                  <template #content>
                    <img class="preview-image" :src="player.Image" />
                  </template>
                  <span><camera-outlined /></span>
                </a-popover>
              </div>
              {{ player.Name }}
            </a-col>
            <a-col :span="4">
              <div class="trait-name">Primary Position</div>
              {{ getPositionName(player.Data.VITALS.POSITION) }}
            </a-col>
            <a-col :span="4">
              <div class="trait-name">Secondary Position</div>
              {{ getPositionName(player.Data.VITALS.SECONDARY_POSITION) }}
            </a-col>
            <a-col :span="8">
              <div class="trait-name">Minutes</div>
              <a-slider
                id="test"
                :value="parseInt(player.Data.VITALS.MINUTES_IN_ROTATION)"
                :min="0"
                :max="48"
              />
            </a-col>

            <a-col :span="4">
              <div class="trait-name">Numerical Minutes</div>
              {{ player.Data.VITALS.MINUTES_IN_ROTATION }}
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-divider />
    <h3>
      Step 4: Submit these changes to the sheet (Make sure all jsons are pasted
      and checked!)
    </h3>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import { message } from "ant-design-vue";
import { CameraOutlined } from "@ant-design/icons-vue";
import _ from "lodash";

export default {
  name: "For Streamers",
  data() {
    return {
      currentTeam: "Hawks",
      columns: [
        {
          title: "Date",
          dataIndex: "Date",
          key: "date",
          width: 100
        },
        {
          title: "Player",
          dataIndex: "Player",
          key: "player"
        },
        {
          title: "Description",
          dataIndex: "Description",
          key: "description",
          ellipsis: true,
          width: "50%"
        },
        {
          title: "Actions",
          key: "action"
        },
        {
          title: "Complete?",
          key: "complete?",
          width: 100
        }
      ],
      checked: [],
      positions: [
        "PG",
        "SG",
        "SF",
        "PF",
        "C",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "S-League "
      ],
      grades: [
        "F",
        "D-",
        "D",
        "D+",
        "C-",
        "c",
        "C+",
        "B-",
        "B",
        "B+",
        "A-",
        "A",
        "A+"
      ],

      systems: [
        "Balanced",
        "Grit & Grind",
        "Pace & Space",
        "Perimeter Centeric",
        "Post Centric",
        "Triangle",
        "Seven Seconds",
        "Defense"
      ],

      styles: [
        "None",
        "Athleticism",
        "High IQ",
        "Intangibles",
        "Marketability",
        "Shooting Post",
        "Shooting Mid",
        "Shooting Three",
        "Shot Creation",
        "Size",
        "Skills",
        "Toughness",
        "Transition",
        "Two Way"
      ],

      insideVsOutsideStyles: [
        "Everything Inside",
        "Mostly Inside",
        "Balanced",
        "Balanced",
        "More Outside",
        "Everything Outside"
      ],

      offenseVsDefenseStyles: [
        "Heavily Offense",
        "Leans Offense",
        "Balanced",
        "Balanced",
        "Leans Defense",
        "Heavily Defense"
      ],

      guardsVsForwardsStyles: [
        "Heavily to Guards",
        "Leans to Guards",
        "Balanced",
        "Balanced",
        "Leans to Forwards",
        "Heavily to Guards"
      ]
    };
  },
  computed: {
    ...mapState(["pList", "teams", "coaches"]),
    teamCoach() {
      const coach = _.find(this.coaches, c => c.team === this.currentTeam);
      return {
        name: [coach.data.VITALS.FIRSTNAME, coach.data.VITALS.LASTNAME].join(
          " "
        ),
        idName: coach.name,
        team: coach.team,
        system: this.systems[parseInt(coach.data.STYLE.ACTIVE_SYSTEM)],
        systemRating:
          coach.data.STYLE[
            (
              this.systems[parseInt(coach.data.STYLE.ACTIVE_SYSTEM)] +
              " PROFICIENCY"
            )
              .toUpperCase()
              .split(" ")
              .join("_")
          ],
        offense: this.grades[parseInt(coach.data.ATTRIBUTES.OFFENSE)],
        defense: this.grades[parseInt(coach.data.ATTRIBUTES.DEFENSE)],
        styleOne: this.styles[parseInt(coach.data.STYLE["STYLE_N#1"])],
        styleTwo: this.styles[parseInt(coach.data.STYLE["STYLE_N#2"])],
        styleThree: this.styles[parseInt(coach.data.STYLE["STYLE_N#3"])],
        zoneUsage: coach.zoneUsage,
        performance: coach.performance,
        guardVsForward: coach.data.STYLE.GUARDS_VS_FORWARDS,
        insideVsOutside: coach.data.STYLE.INSIDE_VS_OUTSIDE,
        offenseVsDefense: coach.data.STYLE.OFFENSE_VS_DEFENSE,
        runPlays: coach.runPlays,
        dAggression: coach.dAggression,
        oRebound: coach.oRebound,
        dRebound: coach.dRebound,
        tempo: coach.tempo,
        oFocus: coach.oFocus,
        dFocus: coach.dFocus,
        playbook: coach.playbook,
        data: JSON.stringify(coach.data)
      };
    },
    teamsList() {
      return _.keys(this.teams).map(team => {
        return {
          label: team,
          value: team
        };
      });
    },
    teamPlayers() {
      const setTeam = this.currentTeam;

      return _.values(
        _.pickBy(this.pList, function(value, key) {
          return value.Team === setTeam;
        })
      ).sort((a, b) => {
        return parseInt(a.Role) - parseInt(b.Role);
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  watch: {
    currentTeam: {
      immediate: true,
      handler(val, oldVal) {}
    }
  },
  methods: {
    isMobile() {
      return window.innerWidth < 500;
    },
    inFrozenTeam(player) {
      return this.teams[player.Team]
        ? this.teams[player.Team].Frozen === "TRUE"
        : false;
    },
    async copyData(mytext) {
      try {
        await navigator.clipboard.writeText(JSON.stringify(mytext));
        message.success("Copied!");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    async copyCoachData() {
      try {
        await navigator.clipboard.writeText(this.teamCoach.data);
        message.success("Copied!");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    getPendingBoosts() {
      return this.teamPlayers
        .map(player => ({
          player: player.Name,
          boosts: player.Updates.filter(
            boost => boost["Done?"] !== "yes" && !(boost.Event === "budget")
          )
        }))
        .filter(boostArr => boostArr.boosts.length)
        .flat();
    },
    handleSubmit: function() {
      this.completeRequests(this.filteredRequestsQueue);
      this.checked = new Array(this.filteredRequestsQueue.length).fill(false);
    },
    getPositionName(num) {
      switch (num) {
        case "0":
          return "Point Guard";
        case "1":
          return "Shooting Guard";
        case "2":
          return "Small Forward";
        case "3":
          return "Power Forward";
        case "4":
          return "Center";
        case "5":
          return "None";
      }
    },
    ...mapActions({
      completeRequests: "completeRequests"
    })
  },
  components: {
    CameraOutlined
  },
  props: {
    team: String
  }
};
</script>

<style scoped>
.list-item {
  padding: 0;
}
.switch {
  padding: 10px;
}
.checkbox-container {
  text-align: center;
}
.position {
  margin-bottom: 0px;
  text-align: center;
}
.trait-name {
  font-size: 10px;
  color: gray;
  text-align: left;
}
.player-info {
  text-align: left;
  padding: 6px;
}
.preview-image {
  max-width: 200px;
}
.ant-slider-track {
  pointer-events: none;
}
.team-select-container {
  padding: 5px;
}
.submit-button {
  padding: 10px;
}
.fs-page {
  margin-top: 20px;
  margin-bottom: 20px;
}

.comment {
  background-color: white;
  padding: 0px 20px;
  border-radius: 5px;
  margin: 20px 0px;
  transition: 0.2s;
  border: 1px solid lightgray;
  font-size: 26px;
  width: 100%;
}
</style>
