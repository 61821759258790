<template>
  <div>
    <a-table
      class="player-table"
      :columns="isIncoming ? incomingHeaders : customHeaders"
      :rowKey="record => record.Name"
      :data-source="playerValues"
      :pagination="false"
      :bordered="false"
      :expandRowByClick="true"
      :expandIconAsCell="false"
      :expandedRowKeys="currentExpanded"
      :onExpand="onRowExpand"
      :expandIcon="
        () => {
          return null;
        }
      "
      :rowClassName="
        () => {
          return 'custom-row';
        }
      "
    >
      <template #bodyCell="{text, record, index, column}">
        <div v-if="column.key === 'Rank'">
          {{ toGmRank(record) }}
        </div>
        <div v-if="column.title === 'XOverall'">
          {{
            record["TriKov Value"] === "0"
              ? "N/A"
              : Math.floor(toXOverall(record))
          }}
        </div>
        <div
          v-if="
            [
              'Combined Rank',
              `Gallo's Rank`,
              `Morris' Rank`,
              `Soto's Rank`
            ].includes(column.title)
          "
        >
          {{ toXRank(column.dataIndex, record.Name) }}
        </div>

        <div
          v-if="
            column.title === 'Current Team' || column.title === 'Prior Team'
          "
          :class="['team-column']"
          :style="{
            backgroundColor: getTeamColors(
              isIncoming ? record.Team : record['Prior Team'],
              teams
            ).primary,
            borderBottom: `5px solid ${
              getTeamColors(
                isIncoming ? record.Team : record['Prior Team'],
                teams
              ).secondary
            }`,
            color: 'white'
          }"
        >
          {{ text }}
        </div>
        <div v-if="column.title === 'Position'">
          {{ getPositionName(record) }}
        </div>
        <div v-if="column.title === 'Height'">
          {{ getHeight(record) }}
        </div>
        <div v-if="column.title === 'Weight'">
          {{ getWeight(record) }}
        </div>
        <div v-if="column.title === 'Wingspan'">
          {{ getWingspan(record) }}
        </div>
        <div v-if="column.title === 'Bid to Beat'">
          {{ globals.canSignFA.status ? "none" : currentBestOffer(record) }}
        </div>
        <a-badge
          v-if="column.title === 'Name'"
          :count="getPendingBoosts(record).length"
          class="name-badge"
          :number-style="{ backgroundColor: '#1DA57A' }"
        >
          {{ text }}
          <a-tooltip v-if="riseOrFall(record) === 'RISE'">
            <template #title>Bot says overpaid</template>
            <rise-outlined class="desc-icon" :style="{ color: 'green' }" />
          </a-tooltip>
          <a-tooltip v-if="riseOrFall(record) === 'FALL'">
            <template #title>Bot says underpaid</template>
            <fall-outlined class="desc-icon" :style="{ color: 'red' }" />
          </a-tooltip>
        </a-badge>

        <div v-if="column.title === 'Signed?'">
          <div v-if="globals.canSignFA.status">
            <a-button
              class="pickup-button"
              :disabled="!currentTeam || !globals.canSignFA.status"
              :onClick="e => signPlayer(e, record)"
              v-if="record.Team === 'FA'"
            >
              Sign <solution-outlined />
            </a-button>
          </div>
          <div v-else>
            <h3 v-if="alreadyOffered(record)" :style="{ color: 'green' }">
              Already Offered! (${{ record["Contract Offer"].Cash }}|{{
                record["Contract Offer"].Minutes
              }}min)
            </h3>

            <h3 v-if="gotSupermax(record)" :style="{ color: 'red' }">
              Supermaxed!
            </h3>

            <a-button
              class="pickup-button"
              :disabled="
                !currentTeam ||
                  gotSupermax(record) ||
                  alreadyOffered(record) ||
                  remainingCash < 1
              "
              :onClick="e => signPlayer(e, record)"
              v-if="record.Team === 'FA' && !alreadyOffered(record)"
            >
              Offer Contract <solution-outlined />
            </a-button>
          </div>
        </div>
      </template>

      <template #expandedRowRender="{ record }">
        <PlayerDetails
          :player="record"
          :playerCount="getSignedPlayerCount()"
          :clearExpanded="clearExpandedRow"
          :showBasicInfo="true"
        />
      </template>
    </a-table>
    <AuctionModal
      v-if="globals.canBidFA.status"
      :visible="pickupVisible"
      :team="currentTeam"
      :playerToAdd="playerToAdd"
      :handleCancel="changePickupVisible"
      :remainingCash="remainingCash"
      :remainingMinutes="remainingMinutes"
    />
    <RosterPickup
      v-if="globals.canSignFA.status"
      :visible="pickupVisible"
      :team="currentTeam"
      :playerToAdd="playerToAdd"
      :handleCancel="changePickupVisible"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  UserAddOutlined,
  SolutionOutlined,
  DollarCircleOutlined,
  FallOutlined,
  RiseOutlined
} from "@ant-design/icons-vue";
import { labels } from "../models/labels";
import Statline from "./Statline";
import PlayerDetails from "./PlayerDetails";
import AuctionModal from "./AuctionModal";
import RosterPickup from "./RosterPickup";
import {
  getPositionName,
  getHeight,
  getWingspan,
  getTeamColors
} from "../utils/playerHelper";

export default {
  name: "FATable",
  props: [
    "players",
    "showTriKov",
    "useRank",
    "remainingCash",
    "remainingMinutes",
    "isIncoming"
  ],
  setup() {
    return {
      getPositionName,
      getHeight,
      getWingspan,
      getTeamColors
    };
  },
  data() {
    return {
      sortKey: "XOverall",
      reverse: false,
      charTypes: {
        standard: ["Points", "Rebounds", "Assists", "Steals", "Blocks"],
        efficiency: ["FG%", "3PT%", "FT%", "TS%", "eFG%"],
        advanced: ["PER", "USG%", "GmSc", "Rtg Diff", "WS"]
      },
      currentExpanded: [],
      playerToAdd: {},
      pickupVisible: false,
      rankWithouts: [
        "Team",
        "Age",
        "Contract Length",
        "Status",
        "Salary",
        "Loyalty"
      ],
      incomingHeaders: [
        {
          title: "Name",
          dataIndex: "Name",
          key: "Name",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a - b,
          sortDirections: ["descend", "ascend"]
        },
        // {
        //   title: "XOverall",
        //   dataIndex: "TriKov Value",
        //   key: "XOverall",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => this.toXOverall(a) - this.toXOverall(b),
        //   sortDirections: ["descend", "ascend"],
        //   defaultSortOrder: "descend"
        // },
        // {
        //   title: "Overall",
        //   dataIndex: "Overall",
        //   key: "Overall",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => a - b,
        //   sortDirections: ["descend", "ascend"]
        // },
        {
          title: "Position",
          dataIndex: "Position",
          key: "Position",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a - b,
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Rank",
          dataIndex: "Rank",
          key: "Rank",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.toGmRank(a) - this.toGmRank(b),
          sortDirections: ["ascend", "descend"],
          defaultSortOrder: "ascend"
        },
        {
          title: "Height",
          dataIndex: "Height",
          key: "Height",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => getHeight(a).localeCompare(getHeight(b)),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Wingspan",
          dataIndex: "Wingspan",
          key: "Wingspan",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => getWingspan(a) - getWingspan(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Weight",
          dataIndex: "Weight",
          key: "Weight",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.getWeight(a) - this.getWeight(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Age",
          dataIndex: "Age",
          key: "Age",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a.Age - b.Age,
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Current Team",
          dataIndex: "Team",
          key: "Team",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            a.Team.localeCompare(b.Team, "en", { numeric: true }),
          sortDirections: ["descend", "ascend"]
        }
      ],
      customHeaders: [
        {
          title: "Name",
          dataIndex: "Name",
          key: "Name",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a - b,
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Position",
          dataIndex: "Position",
          key: "Position",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a - b,
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Rank",
          dataIndex: "Rank",
          key: "Rank",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.toGmRank(a) - this.toGmRank(b),
          sortDirections: ["ascend", "descend"],
          defaultSortOrder: "ascend"
        },
        // {
        //   title: "XOverall",
        //   dataIndex: "TriKov Value",
        //   key: "XOverall",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => this.toXOverall(a) - this.toXOverall(b),
        //   sortDirections: ["descend", "ascend"],
        //   defaultSortOrder: "descend"
        // },
        // {
        //   title: "Overall",
        //   dataIndex: "Overall",
        //   key: "Overall",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => a - b,
        //   sortDirections: ["descend", "ascend"]
        // },
        {
          title: "Height",
          dataIndex: "Height",
          key: "Height",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => getHeight(a).localeCompare(getHeight(b)),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Wingspan",
          dataIndex: "Wingspan",
          key: "Wingspan",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => getWingspan(a) - getWingspan(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Weight",
          dataIndex: "Weight",
          key: "Weight",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.getWeight(a) - this.getWeight(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Age",
          dataIndex: "Age",
          key: "Age",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a.Age - b.Age,
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Prior Team",
          dataIndex: "Prior Team",
          key: "Prior Team",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            a.Team.localeCompare(b.Team, "en", { numeric: true }),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Bid to Beat",
          dataIndex: "Contract Offer",
          key: "Contract Offer",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.currentBestOffer(a) - this.currentBestOffer(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Signed?",
          dataIndex: "Signed?",
          key: "Signed?",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a - b,
          sortDirections: ["descend", "ascend"]
        }
      ]
    };
  },
  computed: {
    ...mapState(["pList", "currentTeam", "globals", "teams"]),
    playerList() {
      return _.values(this.players);
    },
    teamPlayers() {
      const setTeam = this.currentTeam;
      return _.values(
        _.pickBy(this.pList, function(value, key) {
          return value.Team === setTeam;
        })
      );
    },
    isMobile() {
      return window.innerWidth < 500;
    },
    playerValues() {
      return _.values(this.players);
    },
    chartWidth() {
      return window.innerWidth * 0.3;
    },
    statLabels() {
      return labels;
    },
    sortedGmRank() {
      const getGameScore = player => {
        return _.get(player, ["Stats", "Previous", "Advanced", "GmSc"], 0);
      };
      const playerListClone = [...this.playerList];

      playerListClone.sort(function(a, b) {
        return getGameScore(b) - getGameScore(a);
      });
      return playerListClone;
    }
  },
  methods: {
    sortBy: function(sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;

      this.sortKey = sortKey;
    },
    getSignedPlayerCount() {
      return _.values(this.pList).filter(player => player.Team != "FA").length;
    },
    onRowExpand(expanded, record) {
      const keys = [];
      if (expanded) {
        this.currentExpanded.push(record.Name);
      } else {
        const itemIdx = this.currentExpanded.indexOf(record.Name);
        if (itemIdx >= 0) {
          this.currentExpanded.splice(itemIdx, 1);
        }
      }
    },
    clearExpandedRow() {
      this.currentExpanded = [];
    },
    getPendingBoosts(player) {
      return player.Updates.filter(
        boost => boost["Done?"] !== "yes" && !(boost.Event === "budget")
      );
    },
    signPlayer(e, player) {
      e.stopPropagation();
      this.playerToAdd = player;
      this.changePickupVisible();
    },
    changePickupVisible() {
      this.pickupVisible = !this.pickupVisible;
    },
    toXOverall(val) {
      return (parseInt(val["TriKov Value"]) / 50) * 40 + 60;
    },
    toXRank(analystId, player) {
      const playerListClone = [...this.playerList];
      playerListClone.sort(function(a, b) {
        return b[analystId] - a[analystId];
      });

      return (
        _.findIndex(
          playerListClone.map(plyr => plyr.Name),
          p => p === player
        ) + 1
      );
    },
    toGmRank(player) {
      return (
        _.findIndex(
          this.sortedGmRank.map(plyr => plyr.Name),
          p => p === player.Name
        ) + 1
      );
    },
    getWeight(player) {
      return parseInt(_.get(player, ["Data", "VITALS", "WEIGHT_LBS"]));
    },
    currentBestOffer(player) {
      if (!player["Contract Offer"]) return 0;
      const contract = player["Contract Offer"];

      return (
        parseInt(contract.Cash) +
        parseInt(contract.Loyalty) +
        parseInt(contract.Minutes)
      );
    },
    alreadyOffered(player) {
      return player["Contract Offer"].Team === this.currentTeam;
    },
    gotSupermax(player) {
      return player["Contract Offer"].Supermax;
    },
    riseOrFall(player) {
      const predictedSal = parseFloat(player["TriKov Value"]);
      const currentCashOffer = parseFloat(player["Contract Offer"].Cash);

      if (!currentCashOffer) {
        return false;
      } else if (currentCashOffer > predictedSal * 1.5) {
        return "RISE";
      } else if (currentCashOffer < predictedSal * 0.5) {
        return "FALL";
      } else {
        return false;
      }
    },
    ...mapActions({
      pickupPlayer: "pickupPlayer"
    })
  },

  components: {
    CaretDownOutlined,
    CaretUpOutlined,
    Statline,
    PlayerDetails,
    UserAddOutlined,
    SolutionOutlined,
    AuctionModal,
    RosterPickup,
    DollarCircleOutlined,
    FallOutlined,
    RiseOutlined
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.custom-row {
  cursor: pointer;
}
</style>

<style scoped lang="scss">
.expandable-container {
  // margin-left: -60px;
  // padding: 0px 60px;
  margin: auto;
  max-width: 1400px;
  @media only screen and (max-width: 600px) {
    max-width: calc(100vw - 36px);
    margin: 0;
  }
}

.player-table {
  @media only screen and (max-width: 600px) {
    max-width: 100vw;
  }
}

.name-badge {
  padding: 8px;
  margin: -8px;
}

.pickup-button {
  z-index: 10;
}

.pickup-button:hover {
  color: green;
}

.propic {
  border-radius: 5px;
  max-width: 100%;
  height: 300px;
  box-shadow: 0 0 20px 20px #001529 inset;
  background-image: url("https://external-preview.redd.it/DcosmF7ItUt7rP6QIQbYn9MC6H59uV3ePnshLhUa0NQ.png?auto=webp&s=70bba5e7e0e9aa62881193a3a60159f42fb6eeaa");
}
</style>
