<template>
  <a-modal
    v-model:visible="modalVisible"
    :destroyOnClose="true"
    width="90%"
    style="top: 10px"
    :maskClosable="false"
    :closable="false"
  >
    <template #footer>
      <a-button key="submit" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :disabled="totalMinutes !== 240"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-row display="flex" justify="space-between" style="margin-bottom: 3px">
      <a-col md="6">
        <h3>Edit Roster (Drag and Drop)</h3>
      </a-col>
      <a-col>
        <a-radio-group v-model:value="isCompact">
          <a-radio-button value="Compact">Compact</a-radio-button>
          <a-radio-button value="Full">Full</a-radio-button>
        </a-radio-group>
      </a-col>
      <a-col>
        Total Minutes (Should equal 240):
        <span :style="{ color: totalMinutes === 240 ? 'green' : 'red' }">{{
          totalMinutes
        }}</span>
      </a-col>
    </a-row>
    <a-row display="flex" justify="center">
      <a-col :md="2">
        <h3
          class="position"
          :style="{ lineHeight: isCompact === 'Compact' ? '67px' : '124px' }"
          v-for="position in positions"
          v-bind:key="position"
        >
          {{ position }}
        </h3>
      </a-col>
      <a-col :md="22">
        <Draggable
          :list="roleList"
          item-key="name"
          class="list-group"
          ghost-class="ghost"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{element, index}">
            <a-card
              :bodyStyle="{
                padding: '3px',
                cursor: 'grab'
              }"
            >
              <a-row class="player-info" display="flex" justify="space-between">
                <a-col :span="4">
                  <div class="trait-name">
                    Name
                    <a-popover placement="left" :title="element.player.Name">
                      <template #content>
                        <img
                          class="preview-image"
                          :src="element.player.Image"
                        />
                      </template>
                      <span><camera-outlined /></span>
                    </a-popover>
                  </div>
                  {{ element.player.Name }}
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Primary Position</div>
                  <a-select
                    ref="select"
                    v-model:value="element.entryData.primaryPostion"
                    style="width: 120px"
                    :options="positionOptions.slice(1, 6)"
                  ></a-select>
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Secondary Position</div>
                  <a-select
                    ref="select"
                    v-model:value="element.entryData.secondaryPosition"
                    style="width: 120px"
                    :options="positionOptions"
                  ></a-select>
                </a-col>
                <a-col :span="8">
                  <div class="trait-name">Minutes</div>
                  <a-row>
                    <a-col :span="18">
                      <a-slider
                        id="test"
                        v-model:value="element.entryData.minutes"
                        :min="
                          parseInt(element.player['Contract Offer'].Minutes)
                        "
                        :max="48"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-input-number
                        id="inputNumber"
                        v-model:value="element.entryData.minutes"
                        :min="
                          parseInt(element.player['Contract Offer'].Minutes)
                        "
                        :max="48"
                      />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Touches</div>
                  <a-input-number
                    id="inputNumber"
                    v-model:value="element.entryData.touches"
                    :min="1"
                    :max="99"
                  />
                </a-col>
              </a-row>
              <a-row
                class="player-info"
                display="flex"
                justify="space-between"
                v-if="isCompact === 'Full'"
              >
                <a-col :span="4">
                  <div class="trait-name">Play Initiator?</div>
                  <a-checkbox
                    v-model:checked="element.entryData.playInitiator"
                  ></a-checkbox>
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Play Type 1</div>
                  <a-select
                    ref="select"
                    v-model:value="element.entryData.playOne"
                    style="width: 120px"
                    :options="playTypeOptions"
                  ></a-select>
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Play Type 2</div>
                  <a-select
                    ref="select"
                    v-model:value="element.entryData.playTwo"
                    style="width: 120px"
                    :options="playTypeOptions"
                  ></a-select>
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Play Type 3</div>
                  <a-select
                    ref="select"
                    v-model:value="element.entryData.playThree"
                    style="width: 120px"
                    :options="playTypeOptions"
                  ></a-select>
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Play Type 4</div>
                  <a-select
                    ref="select"
                    v-model:value="element.entryData.playFour"
                    style="width: 120px"
                    :options="playTypeOptions"
                  ></a-select>
                </a-col>
                <a-col :span="4">
                  <div class="trait-name">Coach Suggestion?</div>
                  <a-select
                    ref="select"
                    v-model:value="element.entryData.tendencyOption"
                    style="width: 180px"
                    :options="getTendencyLabels()"
                  ></a-select>
                </a-col>
              </a-row>
            </a-card>
          </template>
        </Draggable>
      </a-col>
    </a-row>
    <a-divider />
    <h3>
      Make sure total minutes equal 240 and RES players have no minutes assigned
      at all
    </h3>
  </a-modal>
</template>

<script>
const positions = [
  "PG",
  "SG",
  "SF",
  "PF",
  "C",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "S-League"
];

import { mapState, mapActions } from "vuex";
import _ from "lodash";
import Draggable from "vuedraggable";
import { CameraOutlined } from "@ant-design/icons-vue";

export default {
  name: "RosterEditor",
  props: ["team"],
  computed: {
    ...mapState(["pList", "modalVisible", "coaches"]),
    teamPlayers() {
      const setTeam = this.team;
      return _.values(
        _.pickBy(this.pList, function(value, key) {
          return value.Team === setTeam;
        })
      );
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    totalMinutes() {
      return this.roleList.reduce(
        (prev, curr) => prev + curr.entryData.minutes,
        0
      );
    }
  },
  setup() {
    return {
      positions: [
        "PG",
        "SG",
        "SF",
        "PF",
        "C",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "RES"
      ],
      positionOptions: [
        {
          value: "5",
          label: "None"
        },
        {
          value: "0",
          label: "Point Guard"
        },
        {
          value: "1",
          label: "Shooting Guard"
        },
        {
          value: "2",
          label: "Small Forward"
        },
        {
          value: "3",
          label: "Power Forward"
        },
        {
          value: "4",
          label: "Center"
        }
      ],
      playTypeOptions: [
        {
          value: "0",
          label: "None"
        },
        {
          value: "1",
          label: "Isolation"
        },
        {
          value: "2",
          label: "Isolation Point"
        },
        {
          value: "3",
          label: "Isolation Wing"
        },
        {
          value: "4",
          label: "P&R Ball Handler"
        },
        {
          value: "5",
          label: "P&R Point"
        },
        {
          value: "6",
          label: "P&R Wing"
        },
        {
          value: "7",
          label: "P&R Roll Man"
        },
        {
          value: "8",
          label: "Post Up Low"
        },
        {
          value: "9",
          label: "Post Up High"
        },
        {
          value: "10",
          label: "Guard Post Up"
        },
        {
          value: "11",
          label: "Cutter"
        },
        {
          value: "12",
          label: "Hand Off"
        },
        {
          value: "13",
          label: "Mid Range"
        },
        {
          value: "14",
          label: "3 PT"
        }
      ],
      coachToTendency: [
        "None",
        "Use Your Athleticism",
        "Big Brain Plays",
        "Iso Iso Iso",
        "Go Wild",
        "Post-em Up",
        "Shoot More Mid-Range",
        "Shoot More 3s",
        "Create Your Own Shot",
        "Use Your Size",
        "Use Your Skills",
        "Play Tough",
        "Run In Transition",
        "Play Hard Defense"
      ],
      tendencyOptions: {
        None: { tendencies: [], value: 0 },
        "Use Your Athleticism": {
          tendencies: [
            "ATTACK_STRONG_ON_DRIVE_TENDENCY",
            "DRIVE_TENDENCY",
            "DRIVING_LAYUP_TENDENCY",
            "DRIVING_DUNK_TENDENCY",
            "ALLEY_OOP_TENDENCY",
            "PUTBACK_TENDENCY",
            "NO_SETUP_DRIBBLE_TENDENCY"
          ],
          value: 20
        },
        "Create Your Own Shot": {
          tendencies: [
            "SPIN_JUMPER_TENDENCY",
            "STEPBACK_JUMPER_THREE_TENDENCY",
            "STEPBACK_JUMPER_MID-RANGE_TENDENCY",
            "TRIPLE_THREAT_PUMP_FAKE_TENDENCY",
            "DRIVING_STEP_BACK_TENDENCY",
            "DRIVING_BEHIND_THE_BACK_TENDENCY",
            "DRIVING_DOUBLE_CROSSOVER_TENDENCY"
          ],
          value: 20
        },
        "Use Your Skills": {
          tendencies: [
            "TRIPLE_THREAT_JAB_STEP_TENDENCY",
            "TRIPLE_THREAT_IDLE_TENDENCY",
            "TRIPLE_THREAT_SHOOT_TENDENCY",
            "TRIPLE_THREAT_PUMP_FAKE_TENDENCY",
            "DRIVING_HALF_SPIN_TENDENCY",
            "DRIVING_IN_AND_OUT_TENDENCY",
            "DRIVING_CROSSOVER_TENDENCY",
            "DRIVING_DRIBBLE_HESITATION_TENDENCY",
            "SPIN_LAYUP_TENDENCY",
            "HOP_STEP_LAYUP_TENDENCY",
            "EURO_STEP_LAYUP_TENDENCY",
            "FLOATER_TENDENCY"
          ],
          value: 20
        },
        "Shoot More 3s": {
          tendencies: [
            "SHOT_THREE_TENDENCY",
            "SPOT_UP_SHOT_THREE_TENDENCY",
            "OFF_SCREEN_SHOT_THREE_TENDENCY",
            "SHOT_THREE_LEFT_TENDENCY",
            "SHOT_THREE_LEFT-CENTER_TENDENCY",
            "SHOT_THREE_CENTER_TENDENCY",
            "SHOT_THREE_RIGHT-CENTER_TENDENCY",
            "SHOT_THREE_RIGHT_TENDENCY",
            "CONTESTED_JUMPER_THREE_TENDENCY",
            "DRIVE_PULL_UP_THREE_TENDENCY",
            "SHOT_TENDENCY"
          ],
          value: 20
        },
        "Shoot More Mid-Range": {
          tendencies: [
            "SHOT_MID-RANGE_TENDENCY",
            "SPOT_UP_SHOT_MID-RANGE_TENDENCY",
            "OFF_SCREEN_SHOT_MID-RANGE_TENDENCY",
            "SHOT_MID_LEFT_TENDENCY",
            "SHOT_MID_LEFT-CENTER_TENDENCY",
            "SHOT_MID_CENTER_TENDENCY",
            "SHOT_MID_RIGHT-CENTER_TENDENCY",
            "SHOT_MID_RIGHT_TENDENCY",
            "CONTESTED_JUMPER_MID-RANGE_TENDENCY",
            "DRIVE_PULL_UP_MID-RANGE_TENDENCY",
            "SHOT_TENDENCY"
          ],
          value: 20
        },
        "Post-em Up": {
          tendencies: [
            "POST_UP_TENDENCY",
            "POST_BACK_DOWN_TENDENCY",
            "POST_AGGRESSIVE_BACKDOWN_TENDENCY",
            "SHOOT_FROM_POST_TENDENCY",
            "POST_SPIN_TENDENCY",
            "POST_DRIVE_TENDENCY",
            "POST_FADE_LEFT_TENDENCY",
            "POST_FADE_RIGHT_TENDENCY",
            "POST_HOOK_LEFT_TENDENCY",
            "POST_HOOK_RIGHT_TENDENCY"
          ],
          value: 20
        },
        "Iso Iso Iso": {
          tendencies: [
            "IS_VS._ELITE_DEFENDER_TENDENCY",
            "IS_VS._GOOD_DEFENDER_TENDENCY",
            "IS_VS._AVERAGE_DEFENDER_TENDENCY",
            "IS_VS._POOR_DEFENDER_TENDENCY"
          ],
          value: 20
        },
        "Big Brain Plays": {
          tendencies: [
            "DISH_TO_OPEN_MAN_TENDENCY",
            "FLASHY_PASS_TENDENCY",
            "ALLEY-OOP_PASS_TENDENCY",
            "PLAY_DISCIPLINE_TENDENCY"
          ],
          value: 20
        },
        "Go Wild": {
          tendencies: ["PLAY_DISCIPLINE_TENDENCY"],
          value: -20
        },
        "Reign It In": {
          tendencies: ["PLAY_DISCIPLINE_TENDENCY", "SHOT_TENDENCY"],
          value: 20
        },
        "Play Hard Defense": {
          tendencies: [
            "CONTEST_SHOT_TENDENCY",
            "ON-BALL_STEAL_TENDENCY",
            "BLOCK_SHOT_TENDENCY",
            "PASS_INTERCEPTION_TENDENCY"
          ],
          value: 20
        },
        "Play Tough": {
          tendencies: [
            "CONTEST_SHOT_TENDENCY",
            "FOUL_TENDENCY",
            "HARD_FOUL_TENDENCY",
            "SETUP_WITH_SIZEUP_TENDENCY",
            "POST_AGGRESSIVE_BACKDOWN_TENDENCY",
            "ATTACK_STRONG_ON_DRIVE_TENDENCY"
          ],
          value: 20
        },
        "Use Your Size": {
          tendencies: [
            "BLOCK_SHOT_TENDENCY",
            "PUTBACK_TENDENCY",
            "CONTEST_SHOT_TENDENCY",
            "POST_UP_TENDENCY",
            "POST_BACK_DOWN_TENDENCY",
            "POST_SPIN_TENDENCY",
            "PASS_INTERCEPTION_TENDENCY",
            "DRIVING_DUNK_TENDENCY",
            "STANDING_DUNK_TENDENCY"
          ],
          value: 20
        },
        "Run In Transition": {
          tendencies: [
            "TRANSITION_SPOT_UP_TENDENCY",
            "TRANSITION_PULL_UP_THREE_TENDENCY",
            "DRIVE_PULL_UP_THREE_TENDENCY",
            "DISH_TO_OPEN_MAN_TENDENCY",
            "DRIVING_DUNK_TENDENCY"
          ],
          value: 20
        }
      }
    };
  },
  data() {
    return {
      pagination: {
        showSizeChanger: true,
        buildOptionText: props => {
          return <span>{props.value}</span>;
        }
      },
      roleList: [],
      isCompact: "Compact"
    };
  },
  watch: {
    team: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        this.initRoleList();
      }
    },
    modalVisible: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.initRoleList();
        }
      }
    }
  },
  mounted() {
    this.initRoleList();
  },
  methods: {
    getPositionTrait: function(player) {
      console.log(player);
    },
    difference: function(object, base) {
      function changes(object, base) {
        return _.transform(object, function(result, value, key) {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              _.isObject(value) && _.isObject(base[key])
                ? changes(value, base[key])
                : value;
          }
        });
      }
      return changes(object, base);
    },
    initRoleList: function() {
      const entryDataObj = {
        primaryPostion: 1,
        secondaryPosition: 0,
        minutes: 1,
        touches: 1,
        playInitiator: false,
        playOne: 0,
        playTwo: 0,
        playThree: 0,
        playFour: 0,
        role: 0,
        tendencyOption: "None"
      };

      this.roleList = _.cloneDeep(this.teamPlayers)
        .map(player => {
          const initData = _.cloneDeep(entryDataObj);
          const vitals = player.Data.VITALS;
          const tendencies = player.Data.TENDENCIES;

          initData.primaryPostion = vitals.POSITION;
          initData.secondaryPosition = vitals.SECONDARY_POSITION;
          initData.minutes = parseInt(vitals.MINUTES_IN_ROTATION);
          initData.touches = parseInt(tendencies.TOUCHES_TENDENCY);
          initData.playInitiator = vitals.PLAY_INITIATOR === "1";
          initData.playOne = vitals["PLAY_TYPE_1"];
          initData.playTwo = vitals["PLAY_TYPE_2"];
          initData.playThree = vitals["PLAY_TYPE_3"];
          initData.playFour = vitals["PLAY_TYPE_4"];
          initData.role = parseInt(player.Role);
          initData.tendencyOption = player.Tendency;

          initData.prevData = _.cloneDeep(initData);

          if (initData.role === 13) {
            initData.minutes = 0;
          }

          if (initData.role < 5) {
            Math.max(initData.minutes, 1);
          }

          return {
            player: player,
            entryData: _.cloneDeep(initData)
          };
        })
        .sort((a, b) => {
          return a.entryData.role - b.entryData.role;
        });
    },
    handleOk: function() {
      const newReq = {};
      this.roleList.forEach((role, index) => {
        const name = role.player.Name;
        const entryData = role.entryData;
        const newRole = (index + 1).toString();
        const newData = {
          VITALS: {
            POSITION: entryData.primaryPostion,
            SECONDARY_POSITION: entryData.secondaryPosition,
            MINUTES_IN_ROTATION: entryData.minutes.toString(),
            PLAY_INITIATOR: entryData.playInitiator ? "1" : "0",
            PLAY_TYPE_1: entryData.playOne,
            PLAY_TYPE_2: entryData.playTwo,
            PLAY_TYPE_3: entryData.playThree,
            PLAY_TYPE_4: entryData.playFour
          },
          "SHOES/GEAR": {},
          ACCESSORIES: {},
          ATTRIBUTES: {},
          TENDENCIES: {
            TOUCHES_TENDENCY: entryData.touches.toString()
          },
          HOTZONE: {},
          SIGNATURE: {},
          CONTRACT: {},
          BADGES: {},
          STATS: {}
        };

        let oldVals = role.player["Old Tendency Values"]
          ? role.player["Old Tendency Values"]
          : "{}";
        if (role.player.Tendency !== entryData.tendencyOption) {
          oldVals = this.applyTendencySuggestion(
            newData,
            role.player.Data,
            role.player.Tendency,
            entryData.tendencyOption,
            role.player["Old Tendency Values"]
          );
        }
        const dataChange = !_.isEqual(
          _.omit(role.entryData, ["prevData"]),
          role.entryData.prevData
        );

        const roleChange = parseInt(role.player.Role) !== parseInt(newRole);
        if (dataChange || roleChange) {
          newReq[name] = {
            Data: newData,
            Desc: {
              Data: this.difference(newData, role.player.Data),
              Role: {
                oldRole: role.player.Role,
                newRole: newRole
              },
              Tendency: {
                oldTendency: role.player.Tendency,
                newTendency: entryData.tendencyOption
              }
            },
            Role: newRole,
            Tendency: entryData.tendencyOption,
            "Old Tendency Values": oldVals
          };
        }
      });
      this.addRequests(newReq);
      this.initRoleList();
      this.changeModalVisible("false");
    },
    handleCancel: function() {
      this.changeModalVisible("false");
    },
    getTendencyLabels() {
      const myCoach = _.find(this.coaches, { team: this.team });
      const coachStyles = [
        parseInt(myCoach.data.STYLE["STYLE_N#1"]),
        parseInt(myCoach.data.STYLE["STYLE_N#2"]),
        parseInt(myCoach.data.STYLE["STYLE_N#3"])
      ];
      const availableStyles = coachStyles.map(cs => this.coachToTendency[cs]);
      return ["None"].concat(availableStyles).map(k => {
        return { label: k, value: k };
      });
    },
    applyTendencySuggestion(
      playerData,
      prevData,
      oldTendency,
      newTendency,
      prevOldValues
    ) {
      const oldValues = {};
      if (prevOldValues) {
        this.tendencyOptions[oldTendency].tendencies.forEach(t => {
          playerData.TENDENCIES[t] = prevOldValues[t];
        });
      }
      this.tendencyOptions[newTendency].tendencies.forEach(t => {
        oldValues[t] = prevData.TENDENCIES[t];
        playerData.TENDENCIES[t] = _.clamp(
          parseInt(prevData.TENDENCIES[t]) +
            this.tendencyOptions[newTendency].value,
          0,
          100
        ).toString();
      });

      return oldValues;
    },
    ...mapActions({
      addRequests: "addRequests",
      changeModalVisible: "changeModalVisible"
    })
  },
  components: {
    Draggable,
    CameraOutlined
  }
};
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}
.position {
  margin-bottom: 0px;
  text-align: center;
}
.trait-name {
  font-size: 10px;
  color: gray;
  text-align: left;
}
.player-info {
  text-align: left;
  padding: 6px;
}
.preview-image {
  max-width: 200px;
}
</style>
