<template>
  <div class="trade-block" :class="{ editing: editing }">
    <a-row display="flex" justify="space-between">
      <h2>Trade Block {{ editing ? "EDITING" : "" }}</h2>
      <div class="buttons">
        <a-button @click="submitTradeBlock" type="primary" :disabled="!editing"
          >Submit Trade Block</a-button
        >
        <a-button
          @click="setEditing"
          :disabled="currentTeam !== selectedTeam"
          >{{ editing ? "Cancel" : "Start Editing" }}</a-button
        >
      </div>
    </a-row>
    <h3>Select Team</h3>
    <div class="team-select-container">
      <a-select
        ref="select"
        v-model:value="selectedTeam"
        style="width: 200px"
        :options="teamsList"
        @change="initList"
        :disabled="editing"
      ></a-select>
    </div>
    <a-divider />
    <a-row>
      <a-col :span="4">
        <h3>Assets</h3>
        <Draggable
          class="list-group"
          :list="assets"
          group="people"
          item-key="label"
          :disabled="!editing"
        >
          <template #item="{ element }">
            <div class="list-group-item card">
              <div class="container">
                <a
                  :href="
                    `https://www.thevnba.com/player/${element.label.replaceAll(
                      ' ',
                      '_'
                    )}`
                  "
                  target="_blank"
                >
                  {{ element.label }}
                </a>
              </div>
            </div>
          </template>
        </Draggable></a-col
      >
      <a-col :span="4">
        <h3>Untouchables</h3>
        <Draggable
          class="list-group"
          :list="untouchables"
          group="people"
          item-key="label"
          :disabled="!editing"
        >
          <template #item="{ element }">
            <div class="list-group-item card">
              <div class="container">
                <a
                  :href="
                    `https://www.thevnba.com/player/${element.label.replaceAll(
                      ' ',
                      '_'
                    )}`
                  "
                  target="_blank"
                >
                  {{ element.label }}
                </a>
              </div>
            </div>
          </template>
        </Draggable></a-col
      >
      <a-col :span="16">
        <h3>Selling</h3>
        <Draggable
          class="list-group"
          :list="selling"
          group="people"
          item-key="label"
          :disabled="!editing"
        >
          <template #item="{ element }">
            <div class="list-group-item card">
              <div class="container">
                <a-row display="flex" justify="start" align="top">
                  <a-col :span="4" :style="{ margin: 'auto' }">
                    <a
                      :href="
                        `https://www.thevnba.com/player/${element.label.replaceAll(
                          ' ',
                          '_'
                        )}`
                      "
                      target="_blank"
                    >
                      {{ element.label }}
                    </a>
                  </a-col>
                  <a-col :span="10">
                    <div class="text-area" v-if="editing">
                      <div class="trait-name">
                        You get...
                      </div>
                      <a-textarea
                        v-model:value="element.description"
                        :bordered="false"
                        placeholder="Describe what this asset offers"
                        :rows="3"
                        :maxlength="200"
                        showCount
                        :disabled="!editing"
                      />
                    </div>
                    <div v-else class="desc">
                      <div class="trait-name">
                        You get...
                      </div>
                      <div>
                        {{ element.description }}
                      </div>
                    </div>
                  </a-col>
                  <a-col :span="10">
                    <div class="text-area" v-if="editing">
                      <div class="trait-name">
                        I want...
                      </div>
                      <a-textarea
                        v-model:value="element.want"
                        :bordered="false"
                        placeholder="Describe what you want in return for this asset"
                        :rows="3"
                        :maxlength="200"
                        showCount
                        :disabled="!editing"
                      />
                    </div>
                    <div v-else class="desc">
                      <div class="trait-name">
                        I want...
                      </div>
                      <div>
                        {{ element.want }}
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </template>
        </Draggable>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import Draggable from "vuedraggable";
export default {
  computed: {
    ...mapState(["pList", "tradeBlock", "teams", "currentTeam"]),
    teamsList() {
      return _.keys(this.teams).map(team => {
        return {
          label: team,
          value: team
        };
      });
    },
    teamAssets() {
      const assets = [];
      const playerAssets = _.values(this.pList)
        .filter(player => player.Team === this.selectedTeam)
        .map(player => ({
          label: player.Name,
          type: "Player",
          want: "",
          description: ""
        }));

      const campAssets = _.get(
        this.teams,
        [this.selectedTeam, "Camps"],
        []
      ).map(camp => ({
        label: camp.Name,
        type: "Camp",
        want: "",
        description: ""
      }));

      const draftAssets = _.values(
        _.get(this.teams, [this.selectedTeam, "Draft Picks"], [])
      ).map(pick => ({
        label: pick.Name,
        type: "Draft Pick",
        want: "",
        description: ""
      }));

      const cashAssets = [
        {
          label: "Cash",
          type: "Cash",
          want: "",
          description: ""
        }
      ];

      return assets.concat(playerAssets, draftAssets, campAssets, cashAssets);
    }
  },
  data() {
    return {
      assets: [],
      untouchables: [],
      selling: [],
      selectedTeam: "Bucks",
      editing: false
    };
  },
  name: "Trade Block",
  components: { Draggable },
  mounted() {
    this.initList();
  },
  methods: {
    initList() {
      const untouchables = this.tradeBlock
        .filter(
          item =>
            item["status"] === "Untouchable" && item.team === this.selectedTeam
        )
        .map(item => ({
          label: item.label,
          type: item.type,
          want: item.want,
          description: item.description
        }));
      const selling = this.tradeBlock
        .filter(
          item =>
            item["status"] === "Selling" && item.team === this.selectedTeam
        )
        .map(item => ({
          label: item.label,
          type: item.type,
          want: item.want,
          description: item.description
        }));
      this.untouchables = untouchables;
      this.selling = selling;
      this.assets = this.teamAssets.filter(
        ta => !this.tradeBlock.map(tb => tb.label).includes(ta.label)
      );
    },
    async submitTradeBlock() {
      await this.updateTradeBlock({
        team: this.currentTeam,
        selling: this.selling,
        untouchables: this.untouchables
      });

      this.editing = false;
    },
    setEditing() {
      if (this.editing) {
        this.initList();
        this.editing = false;
      } else {
        this.editing = true;
      }
    },
    ...mapActions({
      updateTradeBlock: "updateTradeBlock"
    })
  }
};
</script>
<style scoped>
.trade-block {
  padding: 20px;
}

.editing {
  background-color: lightgray;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: white;
  cursor: grab;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 16px;
}

.list-group {
  height: fit-content;
  min-height: 100vh;
  margin: 10px;
}
.trait-name {
  font-size: 12px;
  color: gray;
  text-align: left;
}
.text-area {
  margin: 0 10px;
}
.desc {
  padding: 10px;
  text-align: left;
}

a {
  color: inherit;
}
</style>
