export const tradeModel = {
  id: "",
  Date: "",
  "Team 1": "",
  "Team 2": "",
  "Team 3": "",
  "Team 4": "",
  "Team 1 receives": "",
  "Team 2 receives": "",
  "Team 3 receives": "",
  "Team 4 receives": "",
  "Team 1 accepts": false,
  "Team 2 accepts": false,
  "Team 3 accepts": false,
  "Team 4 accepts": false,
  Status: "PENDING",
  "Proposed By": "",
  Comments: ""
};

export const gameModel = {
  Date: "",
  Time: "",
  Streamer: "",
  Home: "",
  Away: "",
  "Home Score": "",
  "Away Score": "",
  "Final Score": "",
  "Winning Team": "",
  "Box Score": "",
  ArchiveVOD: ""
};

export const rawGameStatsModel = {
  Player: "",
  Team: "",
  Minutes: "",
  Points: "",
  Rebounds: "",
  "Offensive Rebounds": "",
  Assists: "",
  Steals: "",
  Blocks: "",
  Turnovers: "",
  "FG Made": "",
  "FG Taken": "",
  "3PT Taken": "",
  "3PT Made": "",
  "FT Made": "",
  "FT Taken": "",
  Dunks: "",
  Fouls: "",
  "+/-": "",
  "Points Responsible For": "",
  "Game Id": ""
};

export const archiveModel = {
  Season: "",
  Key: "",
  Link: ""
};

export const requestModel = {
  Date: "",
  Player: "",
  Team: "",
  Description: "",
  Data: "",
  "Done?": ""
};
