export const labels = {
  Minutes: "MIN",
  Points: "PTS",
  Rebounds: "REB",
  Assists: "AST",
  Steals: "STL",
  Blocks: "BLK",
  Turnovers: "TOV",
  "FG%": "FG%",
  "3PT%": "3PT%",
  "FT%": "FT%",
  Fouls: "PF",
  "+/-": "+/-"
};

export const boxScoreLabels = {
  Player: "Player",
  Team: "Team",
  Minutes: "MIN",
  Points: "PTS",
  Rebounds: "REB",
  Assists: "AST",
  Steals: "STL",
  Blocks: "BLK",
  Turnovers: "TOV",
  "FG Made": "FG",
  "FG Taken": "FGA",
  "3PT Made": "3PT",
  "3PT Taken": "3PTA",
  "FT Made": "FT",
  "FT Taken": "FTA",
  Fouls: "PF",
  "+/-": "+/-"
};

export const teamLabels = {
  Minutes: "MIN",
  Points: "PTS",
  Rebounds: "REB",
  Assists: "AST",
  Steals: "STL",
  Blocks: "BLK",
  Turnovers: "TOV",
  "FG%": "FG%",
  "3PT%": "3PT%",
  "FT%": "FT%",
  Fouls: "PF",
  "+/-": "+/-"
};

export const teamOppLabels = {};

export const teamDiffLabels = {};
