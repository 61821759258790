<template>
  <div class="fa-page">
    <a-row class="top-row">
      <a-col :span="24">
        <a-radio-group v-model:value="currentKey" button-style="solid">
          <a-radio-button value="Current">
            Regular
          </a-radio-button>
          <a-radio-button value="Incoming">
            Incoming
          </a-radio-button>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-row>
      <a-col :md="8">
        <a-statistic
          class="info-item"
          title="Remaining Cash Post FA"
          :value="getSpentCash()"
          style="margin-right: auto"
        />
      </a-col>
      <a-col :md="8">
        <a-statistic
          class="info-item"
          title="Total Projected Guaranteed Minutes (240 max)"
          :value="getTotalMinutes()"
          style="margin-right: auto"
          :valueStyle="{ textAlign: 'center' }"
        />
      </a-col>
      <a-col :md="8">
        <a-statistic
          class="info-item"
          title="Total Projected Salary (325 cap)"
          :value="getTotalSalary()"
          :valueStyle="{
            textAlign: 'center',
            color: getTotalSalary() > 325 ? 'red' : 'initial'
          }"
        />
      </a-col>
    </a-row>
    <a-row justify="center">
      <a-col
        v-for="team in Object.values(teams).filter(t => t.Frozen !== 'TRUE')"
        :key="team.Team"
      >
        <img
          :src="team.Logo"
          class="image"
          :style="{ opacity: team['Ready For Signings'] === 'TRUE' ? 1 : 0.25 }"
        />
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <FATable
          v-bind:players="currentKey == 'Current' ? currentFA : incomingFA"
          v-bind:remainingCash="getSpentCash()"
          v-bind:remainingMinutes="240 - getTotalMinutes()"
          v-bind:isIncoming="currentKey === 'Incoming'"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import FATable from "@/components/FATable.vue";
import _ from "lodash";
export default {
  name: "Free Agency",
  data() {
    return {
      currentKey: "Current"
    };
  },
  computed: {
    ...mapState(["pList", "teams", "currentTeam"]),
    currentFA() {
      return _.pickBy(this.pList, function(value, key) {
        return value.Team === "FA";
      });
    },
    teamPlayers() {
      const setTeam = this.currentTeam;
      return _.pickBy(this.pList, function(value, key) {
        return value.Team === setTeam;
      });
    },
    teamInfo() {
      return this.teams[this.currentTeam];
    },
    incomingFA() {
      return _.pickBy(
        this.pList,
        function(value, key) {
          return (
            (value["Contract Length"] === 1 ||
              value["Contract Length"] === 0) &&
            !this.inFrozenTeam(value) &&
            !(value.Team === "Rookie") &&
            !value["Retiring?"]
          );
        }.bind(this)
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  methods: {
    isMobile() {
      return window.innerWidth < 500;
    },
    inFrozenTeam(player) {
      return this.teams[player.Team]
        ? this.teams[player.Team].Frozen === "TRUE"
        : false;
    },
    getSpentCash() {
      return (
        this.teamInfo.Cash -
        this.getOfferedContracts().reduce((prev, curr) => {
          return prev + parseInt(_.get(curr, ["Cash"], 0));
        }, 0)
      );
    },
    getTotalSalary() {
      return (
        _.values(this.teamPlayers).reduce((prev, curr) => {
          const contract = curr["Contract Offer"];
          return prev + parseInt(_.get(contract, ["Cash"], 0));
        }, 0) +
        this.getOfferedContracts().reduce((prev, curr) => {
          return prev + parseInt(_.get(curr, ["Cash"], 0));
        }, 0)
      );
    },
    getTotalMinutes() {
      return (
        _.values(this.teamPlayers).reduce((prev, curr) => {
          const contract = curr["Contract Offer"];
          return prev + parseInt(_.get(contract, ["Minutes"], 0));
        }, 0) +
        this.getOfferedContracts().reduce((prev, curr) => {
          return prev + parseInt(_.get(curr, ["Minutes"], 0));
        }, 0)
      );
    },
    getOfferedContracts() {
      return _.values(this.currentFA)
        .filter(
          fa =>
            _.get(fa["Contract Offer"], ["Team"], false) === this.currentTeam
        )
        .map(fa => fa["Contract Offer"]);
    }
  },
  components: {
    FATable
  },
  props: {
    team: String
  }
};
</script>

<style scoped>
.list-item {
  padding: 0;
}

.switch {
  padding: 10px;
}
.top-row {
  margin: 10px;
}

.image {
  width: 50px;
  margin: 10px;
  object-fit: contain;
}
</style>
