<template>
  <a-table
    :dataSource="coachesSource"
    :columns="columns"
    :rowKey="record => record.name"
    :expandRowByClick="true"
    :expandIconAsCell="false"
    :expandedRowKeys="currentExpanded"
    :onExpand="onRowExpand"
    :expandIcon="
      () => {
        return null;
      }
    "
  >
    <template #bodyCell="{text, record, index, column}">
      <div v-if="column.title === 'Team'">
        <div v-if="record.team.length" :class="[record.team, 'team-column']">
          {{ text }}
        </div>
        <div v-else>
          <a-button class="pickup-button" :onClick="e => hireCoach(e, record)">
            Hire <solution-outlined />
          </a-button>
        </div>
      </div>
    </template>
    <template #expandedRowRender="{ record }">
      <a-card>
        <a-row display="flex" justify="space-between">
          <a-col :span="4">
            <div class="trait-name">Style 1</div>
            {{ record.styleOne }}
          </a-col>
          <a-col :span="4">
            <div class="trait-name">Style 2</div>
            {{ record.styleTwo }}
          </a-col>
          <a-col :span="4">
            <div class="trait-name">Style 3</div>
            {{ record.styleThree }}
          </a-col>
        </a-row>
        <a-divider />
        <a-row display="flex" justify="space-between">
          <a-col :span="4">
            <div class="trait-name">Inside VS Outside</div>
            {{ insideVsOutsideStyles[parseInt(record.insideVsOutside)] }}
          </a-col>
          <a-col :span="4">
            <div class="trait-name">Guards VS Forwards</div>
            {{ guardsVsForwardsStyles[parseInt(record.guardVsForward)] }}
          </a-col>

          <a-col :span="4">
            <div class="trait-name">Offense VS Defense</div>
            {{ offenseVsDefenseStyles[parseInt(record.offenseVsDefense)] }}
          </a-col>
        </a-row>
        <a-divider />
        <a-row display="flex" justify="space-between">
          <a-col :span="4">
            <div class="trait-name">Offensive Focus</div>
            {{ record.oFocus }}
          </a-col>
          <a-col :span="4">
            <div class="trait-name">Offensive Rebounding</div>
            {{ record.oRebound }}
          </a-col>
          <a-col :span="4">
            <div class="trait-name">Offensive Tempo</div>
            {{ record.tempo }}
          </a-col>
        </a-row>
        <a-divider />
        <a-row display="flex" justify="space-between">
          <a-col :span="4">
            <div class="trait-name">Defensive Focus</div>
            {{ record.dFocus }}
          </a-col>
          <a-col :span="4">
            <div class="trait-name">Defensive Rebounding</div>
            {{ record.dRebound }}
          </a-col>
          <a-col :span="4">
            <div class="trait-name">Defensive Agression</div>
            {{ record.dAggression }}
          </a-col>
        </a-row>
        <a-divider />
        <a-row display="flex" justify="start">
          <a-col :span="4">
            <div class="trait-name">Run Plays</div>

            {{ record.runPlays }}
          </a-col>
          <a-col :span="16">
            <a-slider
              id="test"
              :value="parseInt(record.runPlays)"
              :min="0"
              :max="100"
            />
          </a-col>
        </a-row>
        <a-row display="flex" justify="start">
          <a-col :span="4">
            <div class="trait-name">Zone Usage</div>

            {{ record.zoneUsage }}
          </a-col>
          <a-col :span="16">
            <a-slider
              id="test"
              :value="parseInt(record.zoneUsage)"
              :min="0"
              :max="100"
            />
          </a-col>
        </a-row>
        <a-row display="flex" justify="start">
          <a-col :span="4">
            <div class="trait-name">Performance Impact</div>

            {{ record.performance }}
          </a-col>
          <a-col :span="16">
            <a-slider
              id="test"
              :value="parseInt(record.performance)"
              :min="0"
              :max="100"
            />
          </a-col>
        </a-row>
      </a-card>
    </template>
  </a-table>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { SolutionOutlined } from "@ant-design/icons-vue";
import { map } from "lodash";
export default {
  name: "Coaches",
  computed: {
    ...mapState(["coaches"]),
    coachesSource() {
      return this.coaches.map(coach => {
        return {
          name: [coach.data.VITALS.FIRSTNAME, coach.data.VITALS.LASTNAME].join(
            " "
          ),
          idName: coach.name,
          team: coach.team,
          system: this.systems[parseInt(coach.data.STYLE.ACTIVE_SYSTEM)],
          systemRating:
            coach.data.STYLE[
              (
                this.systems[parseInt(coach.data.STYLE.ACTIVE_SYSTEM)] +
                " PROFICIENCY"
              )
                .toUpperCase()
                .split(" ")
                .join("_")
            ],
          offense: this.grades[parseInt(coach.data.ATTRIBUTES.OFFENSE)],
          defense: this.grades[parseInt(coach.data.ATTRIBUTES.DEFENSE)],
          styleOne: this.styles[parseInt(coach.data.STYLE["STYLE_N#1"])],
          styleTwo: this.styles[parseInt(coach.data.STYLE["STYLE_N#2"])],
          styleThree: this.styles[parseInt(coach.data.STYLE["STYLE_N#3"])],
          zoneUsage: coach.zoneUsage,
          performance: coach.performance,
          guardVsForward: coach.data.STYLE.GUARDS_VS_FORWARDS,
          insideVsOutside: coach.data.STYLE.INSIDE_VS_OUTSIDE,
          offenseVsDefense: coach.data.STYLE.OFFENSE_VS_DEFENSE,
          runPlays: coach.runPlays,
          dAggression: coach.dAggression,
          oRebound: coach.oRebound,
          dRebound: coach.dRebound,
          tempo: coach.tempo,
          oFocus: coach.oFocus,
          dFocus: coach.dFocus,
          playbook: coach.playbook
        };
      });
    }
  },
  data() {
    return {
      currentExpanded: [],
      grades: [
        "F",
        "D-",
        "D",
        "D+",
        "C-",
        "c",
        "C+",
        "B-",
        "B",
        "B+",
        "A-",
        "A",
        "A+"
      ],

      systems: [
        "Balanced",
        "Grit & Grind",
        "Pace & Space",
        "Perimeter Centeric",
        "Post Centric",
        "Triangle",
        "Seven Seconds",
        "Defense"
      ],

      styles: [
        "None",
        "Athleticism",
        "High IQ",
        "Intangibles",
        "Marketability",
        "Shooting Post",
        "Shooting Mid",
        "Shooting Three",
        "Shot Creation",
        "Size",
        "Skills",
        "Toughness",
        "Transition",
        "Two Way"
      ],

      insideVsOutsideStyles: [
        "Everything Inside",
        "Mostly Inside",
        "Balanced",
        "Balanced",
        "More Outside",
        "Everything Outside"
      ],

      offenseVsDefenseStyles: [
        "Heavily Offense",
        "Leans Offense",
        "Balanced",
        "Balanced",
        "Leans Defense",
        "Heavily Defense"
      ],

      guardsVsForwardsStyles: [
        "Heavily to Guards",
        "Leans to Guards",
        "Balanced",
        "Balanced",
        "Leans to Forwards",
        "Heavily to Guards"
      ],

      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "Team",
          dataIndex: "team",
          key: "team"
        },
        {
          title: "System",
          dataIndex: "system",
          key: "system"
        },
        {
          title: "System Rating",
          dataIndex: "systemRating",
          key: "systemRating"
        },
        {
          title: "Offense",
          dataIndex: "offense",
          key: "offense"
        },
        {
          title: "Defense",
          dataIndex: "defense",
          key: "defense"
        },
        {
          title: "Playbook",
          dataIndex: "playbook",
          key: "playbook"
        }
      ]
    };
  },
  methods: {
    onRowExpand(expanded, record) {
      const keys = [];
      if (expanded) {
        this.currentExpanded.push(record.name);
      } else {
        const itemIdx = this.currentExpanded.indexOf(record.name);
        if (itemIdx >= 0) {
          this.currentExpanded.splice(itemIdx, 1);
        }
      }
    },
    clearExpandedRow() {
      this.currentExpanded = [];
    },
    hireCoach(e, coach) {
      e.stopPropagation();
      this.hireNewCoach(coach.idName);
    },
    ...mapActions({
      hireNewCoach: "hireNewCoach"
    })
  },
  components: {
    SolutionOutlined
  }
};
</script>
<style scoped>
.trait-name {
  font-size: 10px;
  color: gray;
  text-align: left;
}

.pickup-button {
  z-index: 10;
}

.pickup-button:hover {
  color: green;
}
</style>
