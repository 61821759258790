<template>
  <a-table
    :columns="columns"
    :data-source="data"
    size="small"
    :rowKey="item => item"
    :pagination="false"
  >
    <template #bodyCell="{text, record, index, column}">
      <div
        v-if="column.title === 'Team'"
        :class="['team-column']"
        :style="{
          backgroundColor: getTeamColors(record, this.teams).primary,
          borderBottom: `5px solid ${
            getTeamColors(record, this.teams).secondary
          }`,
          color: 'white'
        }"
      >
        {{ text }}
      </div>

      <div v-if="typeof text == 'number'">
        {{ text.toFixed(digits || 0) }}
      </div>
    </template>
  </a-table>
</template>

<script>
import _ from "lodash";
import { getTeamColors } from "../utils/playerHelper";
import { mapState } from "vuex";

export default {
  name: "Statline",
  props: ["labels", "data", "digits"],
  setup() {
    return {
      getTeamColors
    };
  },
  computed: {
    ...mapState(["teams"]),
    columns() {
      const statLabels = _.keys(this.labels);
      return statLabels.map(header => {
        return {
          title: this.labels[header],
          dataIndex: header,
          key: header,
          align: "center",
          sorter: (a, b) =>
            !isNaN(a[header]) && !isNaN(b[header])
              ? Number(a[header]) - Number(b[header])
              : a[header].localeCompare(b[header], "en", {
                  numeric: true,
                  sensitivity: "base"
                }),
          sortDirections: ["descend", "ascend"]
        };
      });
    }
  }
};
</script>

<style scoped>
.team-column {
  margin: -8px -8px;
  padding: 8px;
  text-align: center;
}
</style>
