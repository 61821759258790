<template>
  <div class="schedule-page">
    <a-row type="flex" justify="space-around" class="switch">
      <a-col :span="6">
        <a-radio-group v-model:value="currentKey" button-style="solid">
          <a-radio-button value="Regular">
            Regular
          </a-radio-button>
          <a-radio-button value="Playoffs">
            Playoffs
          </a-radio-button>
          <a-radio-button value="Rookies">
            Rookies
          </a-radio-button>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-list item-layout="horizontal" :data-source="getDataSource()">
      <template #renderItem="{ item, index }">
        <a-list-item class="list-item">
          <GameSummary
            :game="item"
            :boxScore="getBoxScores(item)"
            :teams="getTeams(item)"
          />
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import GameSummary from "../components/GameSummary";
export default {
  computed: {
    ...mapState(["rawGameStats", "teams", "otherTeams", "schedule"])
  },
  name: "Schedule",
  data() {
    return {
      currentKey: "Regular"
    };
  },
  components: {
    GameSummary
  },
  methods: {
    getBoxScores(game) {
      return this.rawGameStats
        .filter(rawGame => {
          return rawGame["Game Id"] === game["Box Score"];
        })
        .sort((a, b) => {
          return (
            a["Team"].localeCompare(b["Team"], "en", { numeric: true }) ||
            b["Points"] - a["Points"]
          );
        });
    },
    getTeams(game) {
      const homeTeam = game.Home.substring(game.Home.lastIndexOf(" ") + 1);
      const awayTeam = game.Away.substring(game.Away.lastIndexOf(" ") + 1);

      return this.teams[homeTeam]
        ? [this.teams[homeTeam], this.teams[awayTeam]]
        : [this.otherTeams[homeTeam], this.otherTeams[awayTeam]];
    },
    getDataSource() {
      switch (this.currentKey) {
        case "Regular":
          return this.schedule.regular;
        case "Playoffs":
          return this.schedule.playoffs;
        case "S-League":
          return this.schedule.sLeague;
        case "Rookies":
          return this.schedule.rookies;
        default:
          return this.schedule.regular;
      }
    }
  }
};
</script>

<style scoped>
.list-item {
  padding: 0;
}

.switch {
  padding: 10px;
}
</style>
