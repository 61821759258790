import { createStore } from "vuex";
import { GoogleSpreadsheet } from "google-spreadsheet";
import cookies from "js-cookie";
import _ from "lodash";
require("dotenv").config();

import {
  playerDetails,
  stats,
  ranks,
  attributes,
  badges,
  hotzones,
  rojUpdate
} from "../models/playerModels.js";
import { teamDetails, teamStandings, teamStats } from "../models/teamModels.js";
import {
  tradeModel,
  gameModel,
  rawGameStatsModel,
  archiveModel,
  requestModel
} from "../models/otherModels.js";
import { nameToInitial, toNumber, capitalize } from "../utils/convertHelper";

// const vnbaString = "1INS-TKERe24QAyJCkhkhWBQK4eAWF8RVffhN1BZNRtA"; // Current
// const vnbaString = "1bh3bsyVmnk5ICcnbnK9PMowAwiGbltJRO9SGp9_LJYw"; // Alt
const vnbaString = "12bfAx0RCRa2QSPvjr5H2YxxtypOp8x5McVljT6Jd_7o"; // Alt

const doc = new GoogleSpreadsheet(vnbaString);

export default createStore({
  state: {
    currentTeam: cookies.get("myTeam"),
    sheetsId: vnbaString,
    currentSeason: null,
    pList: {},
    stats: {},
    teams: {},
    otherTeams: {},
    trades: [],
    schedule: {
      regular: [],
      playoffs: []
    },
    archives: {},
    rawGameStats: [],
    requestQueue: [],
    coaches: [],
    ots: [],
    loading: true,
    processing: false,
    modalVisible: false,
    globals: {},
    retiringPList: {},
    tradeBlock: [],
    currentError: ""
  },
  mutations: {
    setPList(state, newData) {
      state.pList = newData[0];
      state.stats = newData[1];
      state.teams = newData[2];
      state.trades = newData[3];
      state.schedule = newData[4];
      state.rawGameStats = newData[5];
      state.archives = newData[6];
      // state.requestQueue = newData[7];
      state.coaches = newData[8];
      state.ots = newData[9];
      state.globals = newData[10];
      state.retiringPList = newData[11];
      state.otherTeams = newData[12];
      state.tradeBlock = newData[13];
      state.loading = false;
      state.processing = false;
    },
    setPlayers(state, newData) {
      state.pList = newData[0];
      state.teams = newData[1];
    },
    setSheetsId(state, newSheetsId) {
      state.sheetsId = newSheetsId;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setProcessing(state, processing) {
      state.processing = processing;
    },
    setCurrentTeam(state, team) {
      cookies.set("myTeam", team);
      state.currentTeam = team;
    },
    toggleModalVisible(state) {
      state.modalVisible = !state.modalVisible;
    }
  },
  actions: {
    async getSheetData({ commit, state }, docID = vnbaString) {
      try {
        const currentDoc = new GoogleSpreadsheet(docID);
        // Authenticate into VNBA Player List Sheets
        await currentDoc.useServiceAccountAuth({
          client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
          private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
            /\\n/g,
            "\n"
          )
        });
        await currentDoc.loadInfo();

        const sheets = currentDoc.sheetsByTitle;
        const players = sheets["Player List"];
        const lLeaders = sheets["League Leaders"];
        const teams = sheets["Team Assets"];
        const teamStats = sheets["Team Stats"];
        const trades = sheets["Trades"];
        const schedule = sheets["Schedule"];
        const playoffs = sheets["Playoff Bracket"];
        const rawGameStats = sheets["Raw Game Stats"];
        const rojUpdates = sheets["Roj Updates"];
        const vnbaArchive = sheets["VNBA Archive"];
        // const requestQueue = sheets["Request Queue"];
        const coaches = sheets["Available Coaches"];
        const offseasonTraining = sheets["Offseason Training"];
        const globals = sheets["Globals"];
        const rPlayers = sheets["Retired Players"];
        const tradeBlock = sheets["Trade Block"];

        Promise.all([
          players.getRows(),
          lLeaders.getRows({ offset: 2 }),
          teams.getRows(),
          teamStats.getRows({ offset: 2 }),
          trades.getRows({ offset: Math.max(trades.rowCount - 100, 5) }),
          schedule.getRows(),
          rawGameStats.getRows({ offset: 1 }),
          playoffs.getRows({ offset: 24 }),
          // rojUpdates.getRows({
          //   offset: Math.max(rojUpdates.rowCount - 25, 0)
          // }),
          vnbaArchive.getRows(),
          // requestQueue.getRows(),
          coaches.getRows(),
          offseasonTraining.getRows(),
          globals.getRows(),
          rPlayers.getRows(),
          tradeBlock.getRows()
        ]).then(async worksheets => {
          const pData = await convertToPlayerDetails(worksheets, state);
          commit("setPList", pData);
          if (
            _.values(state.pList)
              .filter(player => player.Team === state.currentTeam)
              .reduce((prev, curr) => {
                return prev + parseInt(curr.Data.VITALS.MINUTES_IN_ROTATION);
              }, 0) !== 240 &&
            state.currentTeam &&
            state.globals.forceRotation.Status
          ) {
            commit("toggleModalVisible");
          }
        });
      } catch (e) {
        alert(e);
      }
    },
    async refreshPlayers({ commit, state }, docID = vnbaString) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(docID);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();

      const sheets = currentDoc.sheetsByTitle;
      const players = sheets["Player List"];
      const playerRows = await players.getRows();
      const teamSheet = sheets["Team Assets"];
      const teamRows = await teamSheet.getRows();
      const resultingPlayers = { ...state.pList };
      const resultingTeams = {};

      playerRows
        .filter(player => player.Team !== "RETIRED")
        .forEach(player => {
          const playerKey = nameToInitial(player.Name);
          _.set(
            resultingPlayers,
            [playerKey],
            _.merge(
              { ...state.pList[playerKey] },
              gsRowToObject(player, playerDetails)
            )
          );
          if (player.Data) {
            const playerData = JSON.parse(player.Data).reduce(
              (obj, item) => ((obj[item.tab] = item.data), obj),
              {}
            );
            const formedAttributes = _.cloneDeep(attributes);
            const formedBadges = _.cloneDeep(badges);
            const formedHotzones = _.cloneDeep(hotzones);

            Object.keys(formedAttributes).forEach(category => {
              const catObject = _.cloneDeep(formedAttributes[category]);

              Object.keys(catObject).forEach(attributeKey => {
                _.set(
                  formedAttributes,
                  [category, attributeKey, "value"],
                  playerData.ATTRIBUTES[attributeKey]
                );
              });
            });

            Object.keys(formedBadges).forEach(category => {
              const catBadgeObject = _.cloneDeep(formedBadges[category]);

              Object.keys(catBadgeObject).forEach(attributeKey => {
                _.set(
                  formedBadges,
                  [category, attributeKey, "value"],
                  playerData.BADGES[attributeKey]
                );
              });
            });

            Object.keys(formedHotzones).forEach(zone => {
              _.set(formedHotzones, [zone, "value"], playerData.HOTZONE[zone]);
            });

            _.set(playerData, ["ATTRIBUTES"], formedAttributes);
            _.set(playerData, ["BADGES"], formedBadges);
            _.set(playerData, ["HOTZONE"], formedHotzones);

            _.set(resultingPlayers, [playerKey, "Data"], playerData);
          }
        });

      teamRows
        .filter(team => team.Real === "TRUE")
        .forEach(team => {
          const teamObj = resultingTeams;
          const standings = {};
          teamObj[team.Team] = gsRowToObject(team, teamDetails);
          standings["Standard"] = gsRowToObject(
            team,
            teamStandings["Standard"]
          );
          standings["h2h"] = gsRowToObject(team, teamStandings["h2h"]);
          standings["diff"] = gsRowToObject(team, teamStandings["h2h"]);
          teamObj[team.Team].Standings = standings;

          if (team.Camps) {
            _.set(
              teamObj,
              [team.Team, "Camps"],
              team.Camps.split(", ").map(c => {
                return {
                  Name: c,
                  Owner: team.Team,
                  From: c.split("-")[0]
                };
              })
            );
          }

          if (team.Mentorships) {
            _.set(
              teamObj,
              [team.Team, "Mentorships"],
              team.Mentorships.split(", ").map(m => {
                return {
                  Name: m,
                  Owner: team.Team,
                  From: team.Team
                };
              })
            );
          }

          if (team["Retiring Players"]) {
            _.set(
              teamObj,
              [team.Team, "Retiring Players"],
              team["Retiring Players"].split(", ").map(rp => {
                return {
                  Name: rp,
                  Owner: team.Team,
                  From: team.Team
                };
              })
            );
          }

          const resultingPicks = {};
          team["Draft Picks"].split(", ").forEach(
            (key, i) =>
              (resultingPicks[key] = {
                Name: key,
                Owner: team.Team,
                From: key.substring(2),
                "TriKov Value": team["Draft Picks TriKov Value"].split(", ")[i]
              })
          );
          _.set(teamObj, [team.Team, "Draft Picks"], resultingPicks);
        });
      commit("setProcessing", false);
      commit("setPlayers", [resultingPlayers, resultingTeams]);
    },
    async changeSeason({ commit, dispatch }, seasonId) {
      commit("setProcessing", true);
      commit("setSheetsId", seasonId);
      dispatch("getSheetData", seasonId);
    },
    async changeCurrentTeam({ commit, dispatch }, team) {
      commit("setLoading", true);
      commit("setCurrentTeam", team);
      dispatch("getSheetData");
    },
    async changeModalVisible({ commit }) {
      commit("toggleModalVisible");
    },
    async addRequests({ commit, state, dispatch }, requestObj) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      // const requestQueue = sheets["Request Queue"];
      const playerList = sheets["Player List"];

      // const rQRows = await requestQueue.getRows();
      const playerRows = await playerList.getRows();
      const players = _.keys(requestObj);
      const rObjCopy = _.cloneDeep(requestObj);
      // const filteredRequests = rQRows
      //   .filter(row => players.includes(row.Player))
      //   .reduce((obj, currRow) => {
      //     return {
      //       ...obj,
      //       [currRow.Player]: currRow
      //     };
      //   }, {});

      for (const player of _.keys(rObjCopy)) {
        let dataModule = dataToModuleArr(rObjCopy[player].Data);
        let desc = rObjCopy[player].Desc;

        const playerRow = _.find(playerRows, p => {
          return player === p.Name;
        });

        dataModule = mergeDataToModule(JSON.parse(playerRow.Data), dataModule);
        // if (
        //   filteredRequests[player] &&
        //   filteredRequests[player]["Done?"] !== "TRUE"
        // ) {
        //   dataModule = _.merge(
        //     JSON.parse(filteredRequests[player].Data),
        //
        //   );
        //   desc = _.merge(
        //     JSON.parse(filteredRequests[player].Description),
        //     desc
        //   );
        //   filteredRequests[player].Data = JSON.stringify(dataModule);
        //   filteredRequests[player].Description = JSON.stringify(desc);
        //   filteredRequests[player]["Date"] = new Date()
        //     .toISOString()
        //     .slice(0, 10);
        //   // await filteredRequests[player].save();
        // } else {
        //   // await requestQueue.addRow({
        //   //   Date: new Date().toISOString().slice(0, 10),
        //   //   Team: `=VLOOKUP("${player}", 'Player List'!$A$1:$R, 7, FALSE)`,
        //   //   Description: JSON.stringify(desc),
        //   //   Player: player,
        //   //   Data: JSON.stringify(dataModule)
        //   // });
        // }

        playerRow.Data = JSON.stringify(dataModule);

        if (rObjCopy[player].Role) {
          playerRow.Role = rObjCopy[player].Role;
          // const oRole = rObjCopy[player].Desc.Role.oldRole;
          // const nRole = rObjCopy[player].Desc.Role.newRole;
          // if (oRole < 6 && nRole > 5) {
          //   dispatch("sendMediaMessage", {
          //     msg: `Hey, I'm hearing that The ${state.currentTeam} are benching ${player}. He can't be happy about that...`
          //   });
          // }
          // if (oRole > 5 && nRole < 6) {
          //   dispatch("sendMediaMessage", {
          //     msg: `Wow, looks like The ${state.currentTeam} are planning on starting ${player}!`
          //   });
          // }
        }

        if (rObjCopy[player].Tendency) {
          playerRow.Tendency = rObjCopy[player].Tendency;
          playerRow["Old Tendency Values"] = JSON.stringify(
            rObjCopy[player]["Old Tendency Values"]
          );
        }

        await playerRow.save();
      }

      dispatch("getSheetData");
    },
    async completeRequests({ commit, dispatch }, rqToUpdate) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      // const requestQueue = sheets["Request Queue"];
      // const rQRows = await requestQueue.getRows();

      // const filteredRequests = rQRows.filter(
      //   row =>
      //     rqToUpdate.map(rq => rq.Player).includes(row.Player) &&
      //     row["Done?"] !== "TRUE"
      // );
      // for (const fr of filteredRequests) {
      //   fr["Done?"] = true;
      //   await fr.save();
      // }

      dispatch("getSheetData");
    },
    async addTradeRequest({ commit, dispatch }, trade) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const tradeSheet = sheets["Trades"];

      const tradeObjToAdd = { ...trade };
      _.keys(trade).forEach(key => {
        tradeObjToAdd[key] = JSON.stringify(tradeObjToAdd[key]);
      });

      await tradeSheet.addRow(tradeObjToAdd);

      const tradePlayers = [1, 2, 3, 4].map(num => {
        return {
          team: trade[`Team ${num}`],
          playersToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].players
            : [],
          assetsToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].assets
            : [],
          cashToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].cash
            : [],
          campsToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].camps
            : []
        };
      });

      const tradeTerms = [];

      for (const tr of tradePlayers) {
        const teamTerms = [];
        if (tr.team) {
          for (const pta of tr.playersToAdd) {
            teamTerms.push(`${pta}`);
          }

          for (const ata of tr.assetsToAdd) {
            teamTerms.push(
              `The ${ata.From} Round ${ata.Name.charAt(0)} Draft Pick`
            );
          }

          for (const cata of tr.campsToAdd) {
            teamTerms.push(`${cata.From} ${cata.Name.split("-")[1]} Camp`);
          }

          for (const cta of tr.cashToAdd) {
            teamTerms.push(`${cta.value} Cash`);
          }

          const totalGet = teamTerms.join(", ");
          tradeTerms.push(`The ${tr.team} would receive ${totalGet}.\n`);
        }
      }
      dispatch("sendMediaMessage", {
        msg: `pssst, looks like a trade offer has been made submitted by the ${
          trade["Proposed By"]
        }.\n\n${tradeTerms.join("\n")}\n"${trade.Comments}" ~${
          trade["Proposed By"]
        }`
      });

      tradePlayers.forEach(tp => {
        if (tp.team) {
          dispatch("sendTeamMessage", {
            team: tp.team,
            msg: `You have been offered a trade by the ${
              trade["Proposed By"]
            }.\n\n${tradeTerms.join("\n")}\n"${trade.Comments}"  ~${
              trade["Proposed By"]
            }\n\nPlease visit https://www.thevnba.com/trade_requests and select your team to view/reject/accept this trade.`
          });
        }
      });

      dispatch("getSheetData");
    },
    async addTradeResponse({ commit, state, dispatch }, tradeObj) {
      commit("setProcessing", true);
      const { trade, teamNum, acceptance, finalSay } = tradeObj;
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const tradeSheet = sheets["Trades"];
      const tradeRows = await tradeSheet.getRows();

      const rowToUpdate = _.find(tradeRows, { id: `${trade.id}` });
      rowToUpdate[`Team ${teamNum} accepts`] = acceptance;

      const playerSheet = sheets["Player List"];
      const playerRows = await playerSheet.getRows();
      const assetSheet = sheets["Team Assets"];
      const assetRows = await assetSheet.getRows();

      const tradePlayers = [1, 2, 3, 4].map(num => {
        return {
          team: trade[`Team ${num}`],
          playersToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].players
            : [],
          assetsToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].assets
            : [],
          cashToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].cash
            : [],
          campsToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].camps
            : []
        };
      });

      if (finalSay) {
        rowToUpdate.Status =
          acceptance === "ACCEPTED" ? "ACCEPTED" : "REJECTED";

        if (acceptance === "ACCEPTED") {
          const tradeTerms = [];

          for (const tr of tradePlayers) {
            const teamTerms = [];
            if (tr.team) {
              for (const pta of tr.playersToAdd) {
                teamTerms.push(`${pta}`);
                const row = _.find(playerRows, { Name: pta });
                row.Team = tr.team;
                row.Loyalty = Math.ceil(Math.random() * 10);
                await row.save();
              }

              for (const ata of tr.assetsToAdd) {
                const teamRow = _.find(assetRows, { Team: tr.team });
                const givingTeamRow = _.find(assetRows, { Team: ata.Owner });
                teamTerms.push(
                  `The ${ata.From} Round ${ata.Name.charAt(0)} Draft Pick`
                );
                teamRow["Draft Picks"] = teamRow["Draft Picks"].concat(
                  `, ${ata.Name}`
                );
                givingTeamRow["Draft Picks"] = _.replace(
                  givingTeamRow["Draft Picks"],
                  ata.Name,
                  ""
                ).replace(/(^[,\s]+)|([,\s]+$)/g, "");
                await teamRow.save();
              }

              for (const cata of tr.campsToAdd) {
                const teamRow = _.find(assetRows, { Team: tr.team });
                const givingTeamRow = _.find(assetRows, { Team: cata.Owner });
                teamTerms.push(`${cata.From} ${cata.Name.split("-")[1]} Camp`);
                teamRow["Camps"] = teamRow["Camps"].concat(`, ${cata.Name}`);
                givingTeamRow["Camps"] = _.replace(
                  givingTeamRow["Camps"],
                  cata.Name,
                  ""
                ).replace(/(^[,\s]+)|([,\s]+$)/g, "");
                await teamRow.save();
              }

              for (const cta of tr.cashToAdd) {
                const teamRow = _.find(assetRows, { Team: tr.team });
                const givingTeamRow = _.find(assetRows, { Team: cta.name });
                teamTerms.push(`${cta.value} Cash`);
                teamRow["Cash"] =
                  parseInt(teamRow["Cash"]) + parseInt(cta.value);
                givingTeamRow["Cash"] =
                  parseInt(givingTeamRow["Cash"]) - parseInt(cta.value);
                await teamRow.save();
                await givingTeamRow.save();
              }

              const totalGet = teamTerms.join(", ");
              tradeTerms.push(`The ${tr.team} receive ${totalGet}.\n`);
            }
          }

          dispatch("sendDiscordMessage", {
            channel: "transactions",
            msg: `BREAKING: A trade has been processed.\n ${tradeTerms.join(
              ""
            )}`
          });
        } else {
          const tradeTerms = [];

          for (const tr of tradePlayers) {
            const teamTerms = [];
            if (tr.team) {
              for (const pta of tr.playersToAdd) {
                teamTerms.push(`${pta}`);
              }

              for (const ata of tr.assetsToAdd) {
                teamTerms.push(
                  `The ${ata.From} Round ${ata.Name.charAt(0)} Draft Pick`
                );
              }

              for (const cta of tr.cashToAdd) {
                teamTerms.push(`${cta.value} Cash`);
              }

              for (const cata of tr.campsToAdd) {
                teamTerms.push(`${cata.From} ${cata.Name.split("-")[1]} Camp`);
              }

              const totalGet = teamTerms.join(", ");
              tradeTerms.push(`The ${tr.team} would receive ${totalGet}.\n`);
            }
          }
          dispatch("sendMediaMessage", {
            msg: `Well this is awkward, a trade proposed by the ${
              trade["Proposed By"]
            } on ${trade["Date"]} has been rejected by The ${
              state.currentTeam
            }. Proposed Trade: \n\n${tradeTerms.join("\n")}\n`
          });

          tradePlayers.forEach(tp => {
            if (tp.team) {
              dispatch("sendTeamMessage", {
                team: tp.team,
                msg: `A trade proposed by the ${trade["Proposed By"]} on ${
                  trade["Date"]
                } has been rejected by The ${
                  state.currentTeam
                }. Proposed Trade: \n\n${tradeTerms.join(
                  "\n"
                )}\nPlease see https://www.thevnba.com/trade_requests for more details.`
              });
            }
          });
        }
      } else {
        const tradeTerms = [];

        for (const tr of tradePlayers) {
          const teamTerms = [];
          if (tr.team) {
            for (const pta of tr.playersToAdd) {
              teamTerms.push(`${pta}`);
            }

            for (const ata of tr.assetsToAdd) {
              teamTerms.push(
                `The ${ata.From} Round ${ata.Name.charAt(0)} Draft Pick`
              );
            }

            for (const cta of tr.cashToAdd) {
              teamTerms.push(`${cta.value} Cash`);
            }

            const totalGet = teamTerms.join(", ");
            tradeTerms.push(`The ${tr.team} would receive ${totalGet}.\n`);
          }
        }
        dispatch("sendMediaMessage", {
          msg: `Looks like a trade proposed by the ${trade["Proposed By"]} on ${
            trade["Date"]
          } has been accepted by The ${
            state.currentTeam
          }, though more teams need to answer. Proposed Trade: \n\n${tradeTerms.join(
            "\n"
          )}\n`
        });

        tradePlayers.forEach(tp => {
          if (tp.team) {
            dispatch("sendTeamMessage", {
              team: tp.team,
              msg: `A trade proposed by the ${trade["Proposed By"]} on ${
                trade["Date"]
              } has been accepted by The ${
                state.currentTeam
              }. The trade still requires other teams to accept. Proposed Trade: \n\n${tradeTerms.join(
                "\n"
              )}\nPlease see https://www.thevnba.com/trade_requests for more details.`
            });
          }
        });
      }

      await rowToUpdate.save();
      dispatch("getSheetData");
    },
    async cancelTrade({ commit, state, dispatch }, tradeObj) {
      commit("setProcessing", true);
      const { trade } = tradeObj;
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const tradeSheet = sheets["Trades"];
      const tradeRows = await tradeSheet.getRows();

      const rowToUpdate = _.find(tradeRows, { id: `${trade.id}` });

      const playerSheet = sheets["Player List"];
      const playerRows = await playerSheet.getRows();
      const assetSheet = sheets["Team Assets"];
      const assetRows = await assetSheet.getRows();

      const tradePlayers = [1, 2, 3, 4].map(num => {
        return {
          team: trade[`Team ${num}`],
          playersToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].players
            : [],
          assetsToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].assets
            : [],
          cashToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].cash
            : [],
          campsToAdd: trade[`Team ${num} receives`]
            ? trade[`Team ${num} receives`].camps
            : []
        };
      });

      const tradeTerms = [];

      for (const tr of tradePlayers) {
        const teamTerms = [];
        if (tr.team) {
          for (const pta of tr.playersToAdd) {
            teamTerms.push(`${pta}`);
          }

          for (const ata of tr.assetsToAdd) {
            teamTerms.push(
              `The ${ata.From} Round ${ata.Name.charAt(0)} Draft Pick`
            );
          }

          for (const cta of tr.cashToAdd) {
            teamTerms.push(`${cta.value} Cash`);
          }

          for (const cata of tr.campsToAdd) {
            teamTerms.push(`${cata.From} ${cata.Name.split("-")[1]} Camp`);
          }

          const totalGet = teamTerms.join(", ");
          tradeTerms.push(`The ${tr.team} would receive ${totalGet}.\n`);
        }
      }
      tradePlayers.forEach(tp => {
        if (tp.team) {
          dispatch("sendTeamMessage", {
            team: tp.team,
            msg: `A trade proposed by the ${trade["Proposed By"]} on ${
              trade["Date"]
            } has been canceled by The ${
              state.currentTeam
            }. Proposed Trade: \n\n${tradeTerms.join(
              "\n"
            )}\nPlease see https://www.thevnba.com/trade_requests for more details.`
          });
        }
      });

      await rowToUpdate.delete();
      dispatch("getSheetData");
    },
    async pickupPlayer({ commit, state, dispatch }, pickupObj) {
      commit("setProcessing", true);
      const { playerToAdd, playerToDrop } = pickupObj;
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const playerSheet = sheets["Player List"];
      const playerRows = await playerSheet.getRows();
      const assetSheet = sheets["Team Assets"];
      const assetRows = await assetSheet.getRows();

      if (playerToDrop) {
        const playerToDropRow = _.find(playerRows, { Name: playerToDrop });
        const contract = playerToDropRow["Contract Offer"]
          ? playerToDropRow["Contract Offer"]
          : false;

        if (contract) {
          const teamRowToChange = _.find(assetRows, {
            Team: state.currentTeam
          });

          teamRowToChange.Cash =
            parseInt(teamRowToChange.Cash) -
            Math.ceil(
              (parseInt(contract.Cash) + parseInt(contract.Minutes)) / 3
            );

          await teamRowToChange.save();
        }

        playerToDropRow.Team = "FA";
        playerToDropRow["Contract Offer"] = "";
        await playerToDropRow.save();
        dispatch("sendDiscordMessage", {
          channel: "transactions",
          msg: `The ${state.currentTeam} have waived ${playerToDrop}. \n ${playerToDrop} will become a free agent and is availble to be signed by another team starting now.\n\n`
        });
      }

      if (playerToAdd) {
        const playerToAddRow = _.find(playerRows, { Name: playerToAdd });
        const message =
          playerToAddRow["Team"] === "Rookie"
            ? `The ${state.currentTeam} have drafted ${playerToAdd}.`
            : `The ${state.currentTeam} have signed Free Agent ${playerToAdd} to the team.`;
        const newLoyalty = _.random(1, 10);
        playerToAddRow.Loyalty = newLoyalty;
        if (playerToAddRow["Team"] === "Rookie") {
          playerToAddRow.Salary =
            state.globals.isFirstRound.status === "TRUE" ? 20 : 10;
          playerToAddRow["Contract Offer"] = JSON.stringify({
            Player: playerToAddRow.Name,
            Team: state.currentTeam,
            Cash: state.globals.isFirstRound.status === "TRUE" ? "20" : "10",
            Loyalty: newLoyalty,
            Minutes: 0,
            Extandable: state.globals.isFirstRound.status === "TRUE"
          });
        }
        playerToAddRow.Team = state.currentTeam;
        await playerToAddRow.save();
        dispatch("sendDiscordMessage", {
          channel: "transactions",
          msg: message
        });
      }

      dispatch("getSheetData");
    },
    async hireNewCoach({ commit, state, dispatch }, coachToHire) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const coachSheet = sheets["Available Coaches"];
      const coachRows = await coachSheet.getRows();

      const coachToFireRow = _.find(coachRows, { Team: state.currentTeam });

      if (coachToFireRow) {
        coachToFireRow.Team = "";
        await coachToFireRow.save();
      }

      const coachToAddRow = _.find(coachRows, { ["Coach Name"]: coachToHire });
      coachToAddRow.Team = state.currentTeam;
      await coachToAddRow.save();

      dispatch("sendDiscordMessage", {
        channel: "transactions",
        msg: `The ${state.currentTeam} have fired ${coachToFireRow["Coach Name"]} and have hired ${coachToAddRow["Coach Name"]}.`
      });

      dispatch("getSheetData");
    },
    async extendPlayer({ commit, state, dispatch }, playerToAdd) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const playerSheet = sheets["Player List"];
      const teamSheet = sheets["Team Assets"];
      const playerRows = await playerSheet.getRows();
      const teamRows = await teamSheet.getRows();
      const playerToUpdateContract = _.find(playerRows, {
        Name: playerToAdd.Name
      });

      const teamToUpdate = _.find(teamRows, {
        Team: playerToUpdateContract["Prior Team"]
      });

      const teamCash = parseInt(teamToUpdate["Cash"]);
      teamToUpdate["Cash"] =
        teamCash - parseInt(20 - parseInt(playerToAdd.Loyalty));

      playerToUpdateContract["Contract Offer"] = JSON.stringify({
        Player: playerToUpdateContract.Name,
        Team: playerToUpdateContract["Prior Team"],
        Cash: 40,
        Minutes: 0,
        Supermax: false,
        Extended: true,
        Loyalty: playerToAdd.Loyalty
      });
      playerToUpdateContract["Team"] = playerToUpdateContract["Prior Team"];
      await playerToUpdateContract.save();
      await teamToUpdate.save();

      dispatch("sendDiscordMessage", {
        channel: "transactions",
        msg: `The ${state.currentTeam} have extended ${playerToAdd.Name}.`
      });

      dispatch("refreshPlayers");
    },
    async offerContract({ commit, state, dispatch }, contract) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const playerSheet = sheets["Player List"];
      const teamSheet = sheets["Team Assets"];
      const playerRows = await playerSheet.getRows();
      const teamRows = await teamSheet.getRows();

      const playerToUpdateContract = _.find(playerRows, {
        Name: contract.Player
      });

      if (playerToUpdateContract["Contract Offer"]) {
        const parsedContract = JSON.parse(
          playerToUpdateContract["Contract Offer"]
        );

        const teamToUpdate = _.find(teamRows, {
          Team: parsedContract.Team
        });

        teamToUpdate["Ready For Signings"] = "";
        await teamToUpdate.save();
        dispatch("sendTeamMessage", {
          team: parsedContract.Team,
          msg: `Your contract for ${
            parsedContract.Player
          } has been overtaken, the new bid to beat is ${parseInt(
            contract.Cash
          ) +
            parseInt(contract.Loyalty) +
            parseInt(
              contract.Minutes
            )}\n\nYour previous contract was ${parseInt(
            parsedContract.Cash
          )} Cash and ${parseInt(parsedContract.Minutes)} minutes.\n`
        });
      }
      playerToUpdateContract["Contract Offer"] = JSON.stringify(contract);
      await playerToUpdateContract.save();

      dispatch("refreshPlayers");
    },
    async toggleFlag({ commit, state, dispatch }) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const teamSheet = sheets["Team Assets"];
      const teamRows = await teamSheet.getRows();

      const teamToUpdate = _.find(teamRows, {
        Team: state.currentTeam
      });

      teamToUpdate["Ready For Signings"] = !(
        teamToUpdate["Ready For Signings"] == "TRUE"
      );
      teamToUpdate.save();
      dispatch("refreshPlayers");
    },
    async updateTradeBlock({ commit, state, dispatch }, tradeBlockUpdates) {
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const tradeBlockSheet = sheets["Trade Block"];
      const tbRows = await tradeBlockSheet.getRows();

      for (const tb of tbRows.reverse()) {
        if (tb.Team === tradeBlockUpdates.team) {
          await tb.delete();
        }
      }

      for (const sell of tradeBlockUpdates.selling) {
        await tradeBlockSheet.addRow({
          Label: sell.label,
          Type: sell.type,
          Team: tradeBlockUpdates.team,
          Status: "Selling",
          Description: sell.description,
          Want: sell.want
        });
      }

      for (const untouchable of tradeBlockUpdates.untouchables) {
        await tradeBlockSheet.addRow({
          Label: untouchable.label,
          Type: untouchable.type,
          Team: tradeBlockUpdates.team,
          Status: "Untouchable",
          Description: untouchable.description,
          Want: untouchable.want
        });
      }

      dispatch("getSheetData");
    },
    sendDiscordMessage({ state }, discObj) {
      const data = { channelTitle: discObj.channel, value: discObj.msg };
      fetch("https://vnbabot.onrender.com/roj/post/toChannel", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          mode: "no-cors"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data);
        })
        .catch(error => {
          console.error("Error:", error);
        });
    },
    sendMediaMessage({ state }, discObj) {
      const data = { teamName: "Media", value: discObj.msg };
      fetch("https://vnba-bot.herokuapp.com/roj/post/toTeam", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          mode: "no-cors"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data);
        })
        .catch(error => {
          console.error("Error:", error);
        });
    },
    sendTeamMessage({ state }, discObj) {
      const data = { teamName: discObj.team, value: discObj.msg };
      fetch("https://vnbabot.onrender.com/roj/post/toTeam", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          mode: "no-cors"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data);
        })
        .catch(error => {
          console.error("Error:", error);
        });
    },
    async hitUpdatePlayerApi({ state, commit, dispatch }, updateObj) {
      const { dataToSend, campsToUse } = updateObj;
      commit("setProcessing", true);
      const currentDoc = new GoogleSpreadsheet(vnbaString);
      // Authenticate into VNBA Player List Sheets
      await currentDoc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        )
      });
      await currentDoc.loadInfo();
      const sheets = currentDoc.sheetsByTitle;
      const assetSheet = sheets["Team Assets"];
      const assetRows = await assetSheet.getRows();

      fetch("https://vnba-bot.herokuapp.com/roj/post/updatePlayers", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          mode: "no-cors"
        },
        body: JSON.stringify({
          value: dataToSend
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data);
        })
        .catch(error => {
          console.error("Error:", error);
        });

      const teamRow = _.find(assetRows, row => row.Team === state.currentTeam);
      teamRow.Camps = _.difference(
        teamRow.Camps.split(", "),
        campsToUse.camps
      ).join(", ");
      teamRow.Mentorships = _.difference(
        teamRow.Mentorships.split(", "),
        campsToUse.mentorships
      ).join(", ");
      teamRow["Retiring Players"] = _.difference(
        teamRow["Retiring Players"].split(", "),
        campsToUse.retiredPlayers
      ).join(", ");

      await teamRow.save();
      dispatch("getSheetData");
    }
  },
  modules: {}
});

function dataToModuleArr(data) {
  const moduleArr = [];
  _.keys(data).forEach(cat => {
    moduleArr.push({
      module: "PLAYER",
      tab: cat,
      data: data[cat]
    });
  });

  return moduleArr;
}

function mergeDataToModule(data1, data2) {
  const finalModule = [];
  data2.forEach(d => {
    const corrData1 = _.find(data1, o => o.tab === d.tab);
    finalModule.push({
      ...d,
      data: corrData1 ? _.merge(corrData1.data, d.data) : {}
    });
  });

  return finalModule;
}

function gsRowToObject(row, initObj = {}) {
  const newRow = _.cloneDeep(row);
  const newObj = _.cloneDeep(initObj);
  for (const key of Object.keys(initObj)) {
    try {
      newObj[key] = JSON.parse(newRow[key] || newObj[key]);
    } catch (e) {
      newObj[key] = row[key] || newObj[key];
    }
  }
  return newObj;
}

async function convertToPlayerDetails(data, state) {
  const resultingPlayers = {};
  const resultingStats = {};
  const resultingTeams = {};
  const resultingOtherTeams = {};
  const resultingTrades = [];
  const resultingSchedule = {
    regular: [],
    playoffs: [],
    rookies: [],
    sLeague: []
  };
  const resultingRawGameStats = [];
  const ranksKeys = _.keys(ranks);
  const resultingArchive = {};
  const resultingRequestQueue = [];
  const resultingCoaches = [];
  const resultingOTs = [];
  const resultingGlobals = {};
  const resultingRetiredPlayers = {};
  const resultingTradeBlock = [];

  data[8].forEach(archive => {
    _.set(
      resultingArchive,
      [archive.Season],
      gsRowToObject(archive, archiveModel)
    );
  });

  data[9].forEach(coach => {
    resultingCoaches.push({
      name: coach["Coach Name"],
      team: coach.Team,
      data: JSON.parse(coach.Data).reduce((prev, curr) => {
        return { ...prev, [curr.tab]: curr.data };
      }, {}),
      zoneUsage: coach["Zone Usage"],
      performance: coach["Performance Consideration"],
      runPlays: coach["Run Plays"],
      dFocus: coach["Defensive Focus"],
      oFocus: coach["Offensive Focus"],
      oRebound: coach["Offensive Rebounding"],
      dRebound: coach["Defensive Rebounding"],
      tempo: coach["Offensive Tempo"],
      dAggression: coach["Defensive Aggression"],
      playbook: coach["Playbook"]
    });
  });

  data[11].forEach(g => {
    resultingGlobals[g.Global] = {
      name: g.Global,
      status: g.Status === "TRUE"
    };
  });
  data[12]
    .filter(player => player.Team !== "RETIRED")
    .forEach(player => {
      const playerKey = nameToInitial(player.Name);
      _.set(
        resultingRetiredPlayers,
        [playerKey],
        gsRowToObject(player, playerDetails)
      );
      if (player.Data) {
        const playerData = JSON.parse(player.Data).reduce(
          (obj, item) => ((obj[item.tab] = item.data), obj),
          {}
        );
        const formedAttributes = _.cloneDeep(attributes);
        const formedBadges = _.cloneDeep(badges);
        const formedHotzones = _.cloneDeep(hotzones);

        Object.keys(formedAttributes).forEach(category => {
          const catObject = _.cloneDeep(formedAttributes[category]);

          Object.keys(catObject).forEach(attributeKey => {
            _.set(
              formedAttributes,
              [category, attributeKey, "value"],
              playerData.ATTRIBUTES[attributeKey]
            );
          });
        });

        Object.keys(formedBadges).forEach(category => {
          const catBadgeObject = _.cloneDeep(formedBadges[category]);

          Object.keys(catBadgeObject).forEach(attributeKey => {
            _.set(
              formedBadges,
              [category, attributeKey, "value"],
              playerData.BADGES[attributeKey]
            );
          });
        });

        Object.keys(formedHotzones).forEach(zone => {
          _.set(formedHotzones, [zone, "value"], playerData.HOTZONE[zone]);
        });

        _.set(playerData, ["ATTRIBUTES"], formedAttributes);
        _.set(playerData, ["BADGES"], formedBadges);
        _.set(playerData, ["HOTZONE"], formedHotzones);

        _.set(resultingRetiredPlayers, [playerKey, "Data"], playerData);
      }
    });

  data[13].forEach(tb => {
    resultingTradeBlock.push({
      label: tb.Label,
      type: tb.Type,
      team: tb.Team,
      status: tb.Status,
      description: tb.Description,
      want: tb.Want
    });
  });
  data[0]
    .filter(player => player.Team !== "RETIRED")
    .forEach(player => {
      const playerKey = nameToInitial(player.Name);
      _.set(resultingPlayers, [playerKey], {
        ...gsRowToObject(player, playerDetails)
      });
      if (player.Data) {
        const playerData = JSON.parse(player.Data).reduce(
          (obj, item) => ((obj[item.tab] = item.data), obj),
          {}
        );
        const formedAttributes = _.cloneDeep(attributes);
        const formedBadges = _.cloneDeep(badges);
        const formedHotzones = _.cloneDeep(hotzones);

        Object.keys(formedAttributes).forEach(category => {
          const catObject = _.cloneDeep(formedAttributes[category]);

          Object.keys(catObject).forEach(attributeKey => {
            _.set(
              formedAttributes,
              [category, attributeKey, "value"],
              playerData.ATTRIBUTES[attributeKey]
            );
          });
        });

        Object.keys(formedBadges).forEach(category => {
          const catBadgeObject = _.cloneDeep(formedBadges[category]);

          Object.keys(catBadgeObject).forEach(attributeKey => {
            _.set(
              formedBadges,
              [category, attributeKey, "value"],
              playerData.BADGES[attributeKey]
            );
          });
        });

        Object.keys(formedHotzones).forEach(zone => {
          _.set(formedHotzones, [zone, "value"], playerData.HOTZONE[zone]);
        });

        _.set(playerData, ["ATTRIBUTES"], formedAttributes);
        _.set(playerData, ["BADGES"], formedBadges);
        _.set(playerData, ["HOTZONE"], formedHotzones);

        _.set(resultingPlayers, [playerKey, "Data"], playerData);
      }
    });

  data[1].forEach(player => {
    resultingStats[player.Player + player.Team] = {
      "Player Info": {
        Player: player.Player,
        Team: capitalize(player.Team),
        Minutes: player.Minutes,
        "Games Played": player["Games Played"]
      },
      Regular: gsRowToObject(player, stats["Regular"]),
      Advanced: gsRowToObject(player, stats["Advanced"]),
      "Per 36": gsRowToObject(player, stats["Per 36"])
    };
    if (resultingPlayers[player.Player]) {
      _.set(
        resultingPlayers,
        [player.Player, "Stats", capitalize(player.Team)],
        { ...resultingStats[player.Player + player.Team] }
      );
    }
  });

  const prevDocKey = resultingArchive[_.keys(resultingArchive).length].Key;

  if (prevDocKey) {
    const previousDoc = new GoogleSpreadsheet(prevDocKey);

    await previousDoc.useServiceAccountAuth({
      client_email: process.env.VUE_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
      private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY.replace(/\\n/g, "\n")
    });
    await previousDoc.loadInfo();
    const prevSheets = previousDoc.sheetsByTitle;
    const prevLLeaders = prevSheets["League Leaders"];
    const pllRows = await prevLLeaders.getRows({ offset: 2 });
    pllRows.forEach(player => {
      if (resultingPlayers[player.Player]) {
        if (
          (!_.get(resultingPlayers, [player.Player, "Stats", "Previous"]) ||
            parseFloat(player["Minutes"]) >
              parseFloat(
                _.get(resultingPlayers, [
                  player.Player,
                  "Stats",
                  "Previous",
                  "Player Info",
                  "Minutes"
                ])
              )) &&
          parseInt(player["Games Played"]) > 3
        ) {
          _.set(resultingPlayers, [player.Player, "Stats", "Previous"], {
            "Player Info": {
              Player: player.Player,
              Team: capitalize(player.Team),
              Minutes: player.Minutes,
              "Games Played": player["Games Played"]
            },
            Regular: gsRowToObject(player, stats["Regular"]),
            Advanced: gsRowToObject(player, stats["Advanced"]),
            "Per 36": gsRowToObject(player, stats["Per 36"])
          });
        }
      }
    });
  }

  const reverseRanks = ["DRtg"];
  ranksKeys.forEach(rank => {
    const isReversed = _.includes(reverseRanks, rank);
    const statObject = _.values(resultingStats).map(player =>
      _.merge(
        {},
        player["Regular"],
        player["Advanced"],
        player["Per 36"],
        player["Player Info"]
      )
    );
    const sortedStats = statObject
      .filter(
        player =>
          !!_.get(resultingPlayers, [player.Player, "Stats", player.Team])
      )
      .sort((a, b) => {
        return toNumber(a[rank], isReversed) - toNumber(b[rank], isReversed);
      });
    _.forEach(sortedStats, (player, index) => {
      const rankToSet = isReversed ? index : sortedStats.length - index;
      _.set(
        resultingPlayers,
        [player.Player, "Rank", player.Team, rank],
        rankToSet
      );
    });
  });

  data[2].forEach(team => {
    const teamObj = team.Real === "TRUE" ? resultingTeams : resultingOtherTeams;
    const standings = {};
    teamObj[team.Team] = gsRowToObject(team, teamDetails);
    standings["Standard"] = gsRowToObject(team, teamStandings["Standard"]);
    standings["h2h"] = gsRowToObject(team, teamStandings["h2h"]);
    standings["diff"] = gsRowToObject(team, teamStandings["h2h"]);
    teamObj[team.Team].Standings = standings;

    if (team.Camps) {
      _.set(
        teamObj,
        [team.Team, "Camps"],
        team.Camps.split(", ").map(c => {
          return {
            Name: c,
            Owner: team.Team,
            From: c.split("-")[0]
          };
        })
      );
    }

    if (team.Mentorships) {
      _.set(
        teamObj,
        [team.Team, "Mentorships"],
        team.Mentorships.split(", ").map(m => {
          return {
            Name: m,
            Owner: team.Team,
            From: team.Team
          };
        })
      );
    }

    if (team["Retiring Players"]) {
      _.set(
        teamObj,
        [team.Team, "Retiring Players"],
        team["Retiring Players"].split(", ").map(rp => {
          return {
            Name: rp,
            Owner: team.Team,
            From: team.Team
          };
        })
      );
    }

    const resultingPicks = {};
    team["Draft Picks"].split(", ").forEach(
      (key, i) =>
        (resultingPicks[key] = {
          Name: key,
          Owner: team.Team,
          From: key.substring(2),
          "TriKov Value": team["Draft Picks TriKov Value"].split(", ")[i]
        })
    );
    _.set(teamObj, [team.Team, "Draft Picks"], resultingPicks);
  });

  data[3].forEach(team => {
    const capTeam = capitalize(team.Team);
    const tStats = {};
    if (resultingTeams[capTeam]) {
      tStats["Team Info"] = gsRowToObject(team, teamStats["Team Info"]);
      tStats["Regular"] = gsRowToObject(team, teamStats["Regular"]);
      tStats["Opponent"] = gsRowToObject(team, teamStats["Opponent"]);
      tStats["Diff"] = gsRowToObject(team, teamStats["Diff"]);
      tStats["Advanced"] = gsRowToObject(team, teamStats["Advanced"]);
      resultingTeams[capTeam].Stats = tStats;
    }
  });

  _.keys(resultingTeams).forEach(team => {
    const resTeam = resultingTeams[team];
    if (resTeam.Stats) {
      const { Regular, Advanced, Opponent } = resTeam.Stats;
      const playstyles = {
        Pace: parseFloat(Advanced["Possessions (Pace)"]),
        "Shot Selection":
          (parseInt(Regular["3PT Made"]) * 3 +
            parseInt(Regular["Dunks"]) * 2 +
            Regular["FTs"]) /
          Regular["Points"],
        Spacing: Regular["3PT Taken"] / Advanced["Possessions (Pace)"],
        "Ball Movement": Regular["Assists"] / Advanced["Possessions (Pace)"],
        Iso:
          (Regular["FG Made"] - Regular["Assists"]) /
          Advanced["Possessions (Pace)"],
        Defense: 100 / Opponent["O/Points"]
      };

      resTeam.Playstyles = playstyles;
    }
  });

  data[4].forEach(trade => {
    resultingTrades.push(gsRowToObject(trade, tradeModel));
  });

  data[5].forEach(game => {
    if (game.Home) {
      if (
        game.Home === "Sacramento Kings" ||
        game.Home === "Charlotte Hornets"
      ) {
        resultingSchedule.rookies.push(gsRowToObject(game, gameModel));
      } else {
        resultingSchedule.regular.push(gsRowToObject(game, gameModel));
      }
    }
  });

  data[6].forEach(rawStat => {
    resultingRawGameStats.push(gsRowToObject(rawStat, rawGameStatsModel));
  });

  data[7].forEach(game => {
    if (game.Home) {
      resultingSchedule.playoffs.push(gsRowToObject(game, gameModel));
    }
  });

  // data[8].forEach(update => {
  //   const playerObject = resultingPlayers[nameToInitial(update.Player)];

  //   if (playerObject) {
  //     const formedRojUpdate = _.cloneDeep(rojUpdate);

  //     Object.keys(formedRojUpdate).forEach(rojUpdateKey => {
  //       _.set(formedRojUpdate, [rojUpdateKey], update[rojUpdateKey]);
  //     });
  //     _.set(
  //       playerObject,
  //       ["Updates"],
  //       playerObject.Updates.concat([formedRojUpdate])
  //     );
  //   }
  // });

  data[10].forEach(row => {
    resultingOTs.push({
      Category: row.Category,
      Type: row.Type,
      Max: row.Max,
      Tab: row.Tab,
      Options: row.Options ? row.Options.split(", ") : [],
      ..._.keys(resultingTeams).reduce((prev, curr) => {
        return {
          [curr]: row[curr],
          ...prev
        };
      }, {})
    });
  });

  return [
    resultingPlayers,
    resultingStats,
    resultingTeams,
    resultingTrades,
    resultingSchedule,
    resultingRawGameStats,
    resultingArchive,
    resultingRequestQueue,
    resultingCoaches,
    resultingOTs,
    resultingGlobals,
    resultingRetiredPlayers,
    resultingOtherTeams,
    resultingTradeBlock
  ];
}
