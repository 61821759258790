const { createCanvas, loadImage } = require("canvas");

export const drawPropic = async (player, team) => {
  const width = 300;
  const height = 300;

  // Instantiate the canvas object
  const canvas = createCanvas(width, height);
  const context = canvas.getContext("2d");

  const circle = {
    x: 100,
    y: 100,
    radius: 100
  };

  context.beginPath();
  context.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2, true);
  context.closePath();
  context.clip();

  const imgToUse = player.Image ?? team.Logo;
  const avatar = await loadImage(imgToUse);

  // Compute aspectration
  const aspect = avatar.height / avatar.width;
  // Math.max is ued to have cover effect use Math.min for contain
  const hsx = circle.radius * Math.max(1 / aspect, 1);
  const hsy = circle.radius * Math.max(aspect, 1);
  // x - hsl and y - hsy centers the image
  context.drawImage(
    avatar,
    avatar.width / 2 - 50,
    avatar.height / 20,
    avatar.width / 2.5,
    avatar.height / 2.5,
    circle.x - hsx,
    circle.y - hsy,
    hsx * 2,
    hsy * 2
  );

  const buffer = canvas.toCanvas("image/png");

  return buffer;
};
