<template>
  <div class="home">
    <a-row display="flex" justify="center">
      <a-col :xs="24" :md="16" v-if="isMobile()">
        <iframe
          :src="
            'https://player.twitch.tv/?channel=thevnba&parent=' +
              (env === 'development' ? 'localhost' : 'thevnba.com') +
              '&muted=true'
          "
          width="100%"
          frameborder="0"
          scrolling="no"
          allowfullscreen="true"
        >
        </iframe>
      </a-col>
      <a-col :xs="24" :md="4">
        <div class="standings">
          <h2>VNBA Standings</h2>
          <div v-for="(item, index) in sortedTeams()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="item.Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="6">
                {{ item.Standings.Standard.Record }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="16" v-if="!isMobile()">
        <iframe
          :src="
            'https://player.twitch.tv/?channel=thevnba&parent=' +
              (env === 'development' ? 'localhost' : 'thevnba.com') +
              '&muted=true'
          "
          :height="twitchHeight"
          width="100%"
          frameborder="0"
          scrolling="no"
          allowfullscreen="true"
        >
        </iframe>
      </a-col>
      <a-col :xs="24" :md="4" v-if="!isMobile()">
        <div class="standings">
          <h2>Trade Block</h2>
          <div class="badges" :key="content" v-for="content in getRandTB()">
            <a-tooltip
              placement="topLeft"
              :title="
                'Desc: ' +
                  content?.description +
                  '\n' +
                  'Want: ' +
                  content?.want
              "
              arrow-point-at-center
            >
              <div class="playType">
                {{ content?.label }}
                <a-avatar
                  shape="circle"
                  :src="getTeamLogo(content.team, teams)"
                ></a-avatar>
              </div>
            </a-tooltip>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row display="flex" justify="center">
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>MVP Ladder</h2>
          <div v-for="(item, index) in mvpLadder()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>ROTY Ladder</h2>
          <div v-for="(item, index) in rotyLadder()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>6MOY Ladder</h2>
          <div v-for="(item, index) in sixmoyLadder()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>DPOY Ladder</h2>
          <div v-for="(item, index) in dpoyLadder()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>MIP Ladder</h2>
          <div
            v-for="(item, index) in mipLadder(Object.values(pList))"
            :key="index"
          >
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; height: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>LVP Ladder</h2>
          <div
            v-for="(item, index) in lvpLadder(Object.values(pList))"
            :key="index"
          >
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; height: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>Biggest Falloff Ladder</h2>
          <div
            v-for="(item, index) in lipLadder(Object.values(pList))"
            :key="index"
          >
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; height: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>Most Potential Ladder</h2>
          <div
            v-for="(item, index) in untappedLadder(Object.values(pList))"
            :key="index"
          >
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; height: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>Ast/TO Ladder</h2>
          <div
            v-for="(item, index) in astToLadder(Object.values(pList))"
            :key="index"
          >
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; height: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import _ from "lodash";
import { getTeamColors, getTeamLogo } from "../utils/playerHelper";
export default {
  computed: {
    ...mapState(["pList", "teams", "tradeBlock"]),
    twitchHeight() {
      return window.innerWidth * 0.667 * 0.5625 + "px";
    }
  },
  data() {
    return {
      env: process.env.NODE_ENV,
      players: []
    };
  },
  setup() {
    return {
      getTeamLogo,
      getTeamColors
    };
  },
  methods: {
    sortedTeams() {
      const teamValues = _.values(this.teams).filter(
        team => !(team.Frozen === "TRUE")
      );
      teamValues.sort((a, b) => {
        const winPercentage = team => {
          return (
            parseInt(_.get(team, ["Standings", "Standard", "Wins"])) /
            (parseInt(_.get(team, ["Standings", "Standard", "Losses"])) +
              parseInt(_.get(team, ["Standings", "Standard", "Wins"])))
          );
        };

        if (winPercentage(a) < winPercentage(b)) {
          return 1;
        } else if (winPercentage(a) > winPercentage(b)) {
          return -1;
        } else {
          return 0;
        }
      });

      return teamValues.filter(team => !(team.Frozen === "TRUE"));
    },
    mvpLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) >
            10 && _.get(player, ["Stats", player.Team]) !== "Rookie"
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 6);
    },
    rotyLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          player.Age === 1 &&
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 6);
    },
    sixmoyLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          parseInt(player.Role) > 5 &&
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 6);
    },
    dpoyLadder() {
      const players = _.values(this.pList);

      const filteredPlayers = _.filter(players, player => {
        return (
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) >
            15 &&
          !isNaN(
            parseInt(_.get(player, ["Stats", player.Team, "Advanced", "DRtg"]))
          )
        );
      });

      const meanDRtg = _.mean(
        _.values(this.teams)
          .filter(team => team.Frozen === "FALSE")
          .map(team => {
            return parseInt(_.get(team, ["Stats", "Advanced", "DRtg"]));
          })
      );

      const drtgRank = [...filteredPlayers].sort(
        (a, b) =>
          _.get(a, ["Rank", a.Team, "DRtg"]) -
          _.get(b, ["Rank", b.Team, "DRtg"])
      );
      return filteredPlayers

        .sort((a, b) => {
          return (
            (_.get(a, ["Rank", a.Team, "DRtg"]) +
              _.get(a, ["Rank", a.Team, "DWS"]) +
              _.get(a, ["Rank", a.Team, "DRB%"]) +
              _.get(a, ["Rank", a.Team, "Blocks"]) +
              _.get(a, ["Rank", a.Team, "DWS/48"]) +
              _.get(a, ["Rank", a.Team, "BLK%"]) +
              _.get(a, ["Rank", a.Team, "STL%"]) +
              _.get(a, ["Rank", a.Team, "Steals"])) /
              7 -
            (_.get(b, ["Rank", b.Team, "DRtg"]) +
              _.get(b, ["Rank", b.Team, "DWS"]) +
              _.get(b, ["Rank", b.Team, "DRB%"]) +
              _.get(b, ["Rank", b.Team, "Blocks"]) +
              _.get(b, ["Rank", b.Team, "DWS/48"]) +
              _.get(b, ["Rank", b.Team, "BLK%"]) +
              _.get(b, ["Rank", b.Team, "STL%"]) +
              _.get(b, ["Rank", b.Team, "Steals"])) /
              7
          );
        })
        .slice(0, 6);
    },
    mipLadder(players) {
      const filteredPlayers = _.filter(players, player => {
        return (
          !!player.Stats &&
          !!player.Stats.Previous &&
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
        );
      });

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(b, ["Stats", "Previous", "Advanced", "GmSc"]) -
            (_.get(a, ["Stats", a.Team, "Advanced", "GmSc"]) -
              _.get(a, ["Stats", "Previous", "Advanced", "GmSc"]))
          );
        })
        .slice(0, 6);
    },
    lvpLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"]) -
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 6);
    },
    lipLadder(players) {
      const filteredPlayers = _.filter(players, player => {
        return (
          !!player.Stats &&
          !!player.Stats.Previous &&
          player.Age !== "1" &&
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
        );
      });

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", "Previous", "Advanced", "GmSc"]) -
            (_.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
              _.get(b, ["Stats", "Previous", "Advanced", "GmSc"]))
          );
        })
        .slice(0, 6);
    },
    untappedLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) < 22
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) /
              _.get(b, ["Stats", b.Team, "Player Info", "Minutes"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"]) /
              _.get(a, ["Stats", a.Team, "Player Info", "Minutes"])
          );
        })
        .slice(0, 6);
    },
    astToLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Regular", "Turnovers"]) > 0
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            parseFloat(_.get(b, ["Stats", b.Team, "Regular", "Assists"])) /
              parseFloat(_.get(b, ["Stats", b.Team, "Regular", "Turnovers"])) -
            parseFloat(_.get(a, ["Stats", a.Team, "Regular", "Assists"])) /
              parseFloat(_.get(a, ["Stats", a.Team, "Regular", "Turnovers"]))
          );
        })
        .slice(0, 6);
    },
    getRandTB() {
      const getSingleRand = () => {
        const tradeBlockValues = _.values(this.tradeBlock);
        const rand = _.sample(tradeBlockValues);
        return rand;
      };
      return _.shuffle(_.values(this.tradeBlock));
    },
    isMobile() {
      return window.innerWidth < 500;
    }
  },
  name: "Home",
  components: {},
  mounted() {
    this.$loadScript("https://platform.twitter.com/widgets.js");
  },
  watch: {
    pList: {
      immediate: true,
      handler(val, oldVal) {
        this.players = _.cloneDeep(val);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.home {
  height: 100%;
  width: 100%;
}
.standings {
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 20%);
  transition: 0.2s;
  border: 1px solid lightgray;
  margin: 5px;
}

.standing-row {
  align-items: center;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
}

.playType {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  font-weight: bold;
  background-color: #2c3e50;
  color: white;
}
</style>
