<template>
  <div class="team-card">
    <a-row display="flex" justify="center" class="team">
      <a-col :xs="24" :md="8">
        <div class="logo-container">
          <img class="team-logo" :src="teamInfo.Logo" />
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="team-info">
          <div class="team-title">{{ team }}</div>
          <div class="team-subtitle">{{ teamInfo.Owner }}</div>
          <div class="team-frozen" v-if="isFrozen()">FROZEN</div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <a-row display="flex" justify="space-between">
          <a-col :md="12">
            <a-statistic
              class="info-item"
              title="Cash"
              :value="teamInfo.Cash"
              style="margin-right: auto"
            />
          </a-col>
          <a-col :md="12">
            <a-statistic
              class="info-item"
              title="Draft Picks"
              :value="Object.values(teamInfo['Draft Picks']).map(dp => dp.Name)"
              style="margin-right: auto"
              :valueStyle="{ textAlign: 'center' }"
            />
          </a-col>
          <a-col :md="12">
            <a-statistic
              class="info-item"
              title="Total Guaranteed Minutes"
              :value="getTotalMinutes()"
              style="margin-right: auto"
              :valueStyle="{ textAlign: 'center' }"
            />
          </a-col>
          <a-col :md="12">
            <a-statistic
              class="info-item"
              title="Total Salary (325 cap)"
              :value="getTotalSalary()"
              :valueStyle="{
                textAlign: 'center',
                color: getTotalSalary() > 325 ? 'red' : 'initial'
              }"
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>

  <a-row>
    <a-col :span="24">
      <PlayerTable v-bind:players="teamPlayers" />
    </a-col>
  </a-row>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import PlayerTable from "@/components/PlayerTable.vue";
import _ from "lodash";
export default {
  name: "Team",
  computed: {
    ...mapState(["pList", "teams", "schedule"]),
    teamPlayers() {
      const setTeam = this.team;
      return _.pickBy(this.pList, function(value, key) {
        return value.Team === setTeam;
      });
    },
    teamInfo() {
      return this.teams[this.team];
    }
  },
  created() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  methods: {
    isMobile() {
      return window.innerWidth < 500;
    },
    isFrozen() {
      return this.teamInfo.Frozen == "TRUE";
    },
    getGames() {
      return [...this.schedule.regular, ...this.schedule.playoffs].filter(
        game => {
          const homeTeam = game.Home.substring(game.Home.lastIndexOf(" ") + 1);
          const awayTeam = game.Away.substring(game.Away.lastIndexOf(" ") + 1);

          return this.team === homeTeam || this.team === awayTeam;
        }
      );
    },
    getPlayStyles() {
      const getMin = (values, field) => {
        return values.reduce(
          (min, p) => (p[field] < min ? p[field] : min),
          values[0][field]
        );
      };
      const getMax = (values, field) => {
        return values.reduce(
          (max, p) => (p[field] > max ? p[field] : max),
          values[0][field]
        );
      };
      const teamPlaystyle = this.teams[this.team].Playstyles;

      const playstyles = _.values(this.teams)
        .filter(team => team.Frozen !== "TRUE")
        .map(team => team.Playstyles);
      const playstyleLabels = _.keys(playstyles[0]);

      const minMax = {};
      playstyleLabels.forEach(label => {
        minMax[label] = {
          min: getMin(playstyles, label),
          max: getMax(playstyles, label)
        };
      });

      const playstylesNormalized = playstyleLabels.map(playstyle => {
        return (
          (teamPlaystyle[playstyle] - minMax[playstyle].min) /
            (minMax[playstyle].max - minMax[playstyle].min) +
          0.1
        );
      });
      return {
        chartOptions: {
          chart: {
            toolbar: {
              show: false
            }
          },
          title: {
            text: "Playstyles",
            align: "center"
          },
          tooltip: {
            enabled: false,
            x: {
              show: false
            },

            style: {
              fontSize: "20px"
            }
          },
          xaxis: {
            categories: playstyleLabels
          },
          yaxis: {
            show: false,
            max: 1.1
          },
          fill: {
            colors: ["#2FDAA4", "#2FDAA4"]
          },
          stroke: {
            colors: ["#1DA57A", "#1DA57A"]
          },
          markers: {
            colors: ["#1DA57A", "#1DA57A"]
          }
        },
        series: [
          {
            name: "mastery",
            data: playstylesNormalized
          }
        ]
      };
    },
    getChartOptions() {
      const isHome = game =>
        game.Home.substring(game.Home.lastIndexOf(" ") + 1) === this.team;
      const gameDiffs = this.getGames().map(game => {
        const diff =
          parseInt(game["Home Score"]) - parseInt(game["Away Score"]);

        return isHome(game) ? diff : diff * -1;
      });

      const cats = this.getGames().map(game => {
        return {
          date: _.last(game["Date"].split(" ")),
          opponent: isHome(game) ? `VS ${game["Away"]}` : `@ ${game["Home"]}`
        };
      });
      return {
        series: [
          {
            name: "Point Diff",
            data: gameDiffs
          }
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 200,
            toolbar: {
              show: false
            }
          },
          title: {
            text: "Game Results",
            align: "middle"
          },
          plotOptions: {
            bar: {
              colors: {
                ranges: [
                  {
                    from: -100,
                    to: 0,
                    color: "#F15B46"
                  },
                  {
                    from: 0,
                    to: 100,
                    color: "#1DA57A"
                  }
                ]
              },
              columnWidth: "80%"
            }
          },
          tooltip: {
            enabled: true,
            x: {
              show: true,

              formatter: idx => {
                return cats[idx].date + " " + cats[idx].opponent;
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          yaxis: {
            title: {
              text: "Point Margin"
            },
            labels: {}
          },
          xaxis: {
            type: "datetime",
            categories: cats.map((_cat, idx) => idx),
            labels: {
              rotate: -90
            }
          }
        }
      };
    },
    getTotalSalary() {
      return _.values(this.teamPlayers).reduce((prev, curr) => {
        return prev + parseInt(_.get(curr["Contract Offer"], ["Cash"], 0));
      }, 0);
    },
    getTotalMinutes() {
      return _.values(this.teamPlayers).reduce((prev, curr) => {
        return prev + parseInt(_.get(curr["Contract Offer"], ["Minutes"], 0));
      }, 0);
    }
  },
  components: {
    PlayerTable
  },
  props: {
    team: String
  }
};
</script>

<style scoped>
.team {
  display: flex;
  flex-direction: row;
  margin: auto;
  align-self: center;
  width: 100%;
  padding: 10px;
}

.team-logo {
  width: 100%;
  height: auto;
}

.logo-container {
  height: fit-content;
  max-width: 180px;
  margin: auto;
  padding: 10px;
}

.team-title {
  font-size: 36px;
  text-align: left;
  text-align: center;
}

.team-subtitle {
  font-size: 20px;
  text-align: left;
  text-align: center;
}

.info {
  display: flex;
  flex: 1 1 0px;
  justify-content: space-around;
  margin: auto;
  align-items: center;
  max-width: 250px;
}

.info-item {
  text-align: center;
}

.team-card {
  background-color: white;
  width: 100%;
  padding: 0px 20px;
  border-radius: 10px;
  margin: 20px 0px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border: 1px solid lightgray;
}

.team-info {
  margin: auto 20px;
}

.team-frozen {
  font-size: 36px;
  text-align: left;
  text-align: center;
  color: #95ced6;
}
</style>
