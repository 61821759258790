<template>
  <div class="asg">
    <a-row display="flex" justify="center">
      <a-col :xs="24" :md="12">
        <div class="standings">
          <h2>ASG Forwards</h2>
          <div v-for="(item, index) in asgLadder()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="12">
        <div class="standings">
          <h2>ASG Guards</h2>
          <div v-for="(item, index) in asgLadder(true)" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row display="flex" justify="center">
      <a-col :xs="24" :md="12">
        <div class="standings">
          <h2>Rising Star Rookies</h2>
          <div v-for="(item, index) in asgRising(true)" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="12">
        <div class="standings">
          <h2>Rising Star Sophomores</h2>
          <div v-for="(item, index) in asgRising()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row display="flex" justify="center">
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>3 Point Contest Participants</h2>
          <div v-for="(item, index) in threePoint()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>Dunk Contest Participants</h2>
          <div v-for="(item, index) in dunksLadder()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="standings">
          <h2>Skills Challenge Participants</h2>
          <div v-for="(item, index) in skillsLaddeer()" :key="index">
            <a-row class="standing-row" display="flex" justify="center">
              <a-col :span="4">
                {{ index + 1 }}
              </a-col>
              <a-col :span="6">
                <img
                  :src="this.teams[item.Team].Logo"
                  style="width: 40px; object-fit: contain;"
                />
              </a-col>
              <a-col :span="10">
                {{ item.Name }}
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import _ from "lodash";
export default {
  computed: {
    ...mapState(["pList", "teams"]),
    twitchHeight() {
      return window.innerWidth * 0.667 * 0.5625 + "px";
    }
  },
  data() {
    return {
      env: process.env.NODE_ENV,
      players: []
    };
  },
  methods: {
    sortedTeams() {
      const teamValues = _.values(this.teams).filter(
        team => !(team.Frozen === "TRUE")
      );
      teamValues.sort((a, b) => {
        const winPercentage = team => {
          return (
            parseInt(_.get(team, ["Standings", "Standard", "Wins"])) /
            (parseInt(_.get(team, ["Standings", "Standard", "Losses"])) +
              parseInt(_.get(team, ["Standings", "Standard", "Wins"])))
          );
        };

        if (winPercentage(a) < winPercentage(b)) {
          return 1;
        } else if (winPercentage(a) > winPercentage(b)) {
          return -1;
        } else {
          return 0;
        }
      });

      return teamValues.filter(team => !(team.Frozen === "TRUE"));
    },
    asgLadder(isGuard = false) {
      const positions = isGuard ? ["0", "1"] : ["2", "3", "4"];
      const roles = isGuard ? [1, 2] : [3, 4, 5];
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) >
            10 &&
          (parseInt(player.Role) < 5
            ? roles.includes(player.Role)
            : positions.includes(_.get(player, ["Data", "VITALS", "POSITION"])))
      );
      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 7);
    },
    asgRising(isRookie = false) {
      const age = isRookie ? 1 : 2;
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) >
            10 && player.Age === age
      );
      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 7);
    },
    threePoint() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) >
            10 &&
          _.get(player, ["Stats", player.Team, "Regular", "3PT Taken"]) > 4
      );
      return filteredPlayers
        .sort((a, b) => {
          return (
            parseFloat(_.get(b, ["Stats", b.Team, "Regular", "3PT%"])) -
            parseFloat(_.get(a, ["Stats", a.Team, "Regular", "3PT%"]))
          );
        })
        .slice(0, 6);
    },
    dunksLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );
      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Regular", "Dunks"]) -
            _.get(a, ["Stats", a.Team, "Regular", "Dunks"])
          );
        })
        .slice(0, 6);
    },
    skillsLaddeer() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );
      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Regular", "Points Responsible For"]) -
            _.get(a, ["Stats", a.Team, "Regular", "Points Responsible For"])
          );
        })
        .slice(0, 6);
    },
    mvpLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 6);
    },
    rotyLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          player.Age === "1" &&
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 6);
    },
    sixmoyLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          parseInt(player.Role) > 5 &&
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 6);
    },
    dpoyLadder() {
      const players = _.values(this.pList);

      const filteredPlayers = _.filter(players, player => {
        return (
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) >
            15 &&
          !isNaN(
            parseInt(_.get(player, ["Stats", player.Team, "Advanced", "DRtg"]))
          )
        );
      });

      const meanDRtg = _.mean(
        _.values(this.teams)
          .filter(team => team.Frozen === "FALSE")
          .map(team => {
            return parseInt(_.get(team, ["Stats", "Advanced", "DRtg"]));
          })
      );

      const drtgRank = [...filteredPlayers].sort(
        (a, b) =>
          _.get(a, ["Rank", a.Team, "DRtg"]) -
          _.get(b, ["Rank", b.Team, "DRtg"])
      );
      return filteredPlayers

        .sort((a, b) => {
          return (
            (_.get(a, ["Rank", a.Team, "DRtg"]) +
              _.get(a, ["Rank", a.Team, "DWS"]) +
              _.get(a, ["Rank", a.Team, "DRB%"]) +
              _.get(a, ["Rank", a.Team, "Blocks"]) +
              _.get(a, ["Rank", a.Team, "DWS/48"]) +
              _.get(a, ["Rank", a.Team, "BLK%"]) +
              _.get(a, ["Rank", a.Team, "STL%"]) +
              _.get(a, ["Rank", a.Team, "Steals"])) /
              7 -
            (_.get(b, ["Rank", b.Team, "DRtg"]) +
              _.get(b, ["Rank", b.Team, "DWS"]) +
              _.get(b, ["Rank", b.Team, "DRB%"]) +
              _.get(b, ["Rank", b.Team, "Blocks"]) +
              _.get(b, ["Rank", b.Team, "DWS/48"]) +
              _.get(b, ["Rank", b.Team, "BLK%"]) +
              _.get(b, ["Rank", b.Team, "STL%"]) +
              _.get(b, ["Rank", b.Team, "Steals"])) /
              7
          );
        })
        .slice(0, 6);
    },
    mipLadder(players) {
      const filteredPlayers = _.filter(players, player => {
        return (
          !!player.Stats &&
          !!player.Stats.Previous &&
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
        );
      });

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
            _.get(b, ["Stats", "Previous", "Advanced", "GmSc"]) -
            (_.get(a, ["Stats", a.Team, "Advanced", "GmSc"]) -
              _.get(a, ["Stats", "Previous", "Advanced", "GmSc"]))
          );
        })
        .slice(0, 6);
    },
    lvpLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"]) -
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"])
          );
        })
        .slice(0, 6);
    },
    lipLadder(players) {
      const filteredPlayers = _.filter(players, player => {
        return (
          !!player.Stats &&
          !!player.Stats.Previous &&
          player.Age !== "1" &&
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) > 10
        );
      });

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"]) -
            _.get(a, ["Stats", "Previous", "Advanced", "GmSc"]) -
            (_.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) -
              _.get(b, ["Stats", "Previous", "Advanced", "GmSc"]))
          );
        })
        .slice(0, 6);
    },
    untappedLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Player Info", "Minutes"]) < 22
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            _.get(b, ["Stats", b.Team, "Advanced", "GmSc"]) /
              _.get(b, ["Stats", b.Team, "Player Info", "Minutes"]) -
            _.get(a, ["Stats", a.Team, "Advanced", "GmSc"]) /
              _.get(a, ["Stats", a.Team, "Player Info", "Minutes"])
          );
        })
        .slice(0, 6);
    },
    astToLadder() {
      const players = _.values(this.pList);
      const filteredPlayers = _.filter(
        players,
        player =>
          _.get(player, ["Stats", player.Team, "Regular", "Turnovers"]) > 0
      );

      return filteredPlayers
        .sort((a, b) => {
          return (
            parseFloat(_.get(b, ["Stats", b.Team, "Regular", "Assists"])) /
              parseFloat(_.get(b, ["Stats", b.Team, "Regular", "Turnovers"])) -
            parseFloat(_.get(a, ["Stats", a.Team, "Regular", "Assists"])) /
              parseFloat(_.get(a, ["Stats", a.Team, "Regular", "Turnovers"]))
          );
        })
        .slice(0, 6);
    },
    isMobile() {
      return window.innerWidth < 500;
    }
  },
  name: "ASG",
  components: {},
  mounted() {},
  watch: {
    pList: {
      immediate: true,
      handler(val, oldVal) {
        this.players = _.cloneDeep(val);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.asg {
  height: 100%;
  width: 100%;
}
.standings {
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 20%);
  transition: 0.2s;
  border: 1px solid lightgray;
  margin: 5px;
}

.standing-row {
  align-items: center;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
}
</style>
