<template>
  <div class="stats-page">
    <StatsTable
      v-bind:players="pList"
      v-bind:stats="stats"
      v-bind:teams="teams"
    ></StatsTable>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import StatsTable from "@/components/StatsTable.vue";
export default {
  computed: { ...mapState(["pList", "stats", "teams"]) },
  name: "Stats",
  components: {
    StatsTable
  }
};
</script>

<style scoped>
.stats-page {
}
</style>
