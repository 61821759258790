<template>
  <div>
    <a-menu
      v-model:selectedKeys="current"
      mode="inline"
      theme="dark"
      :style="{ lineHeight: '64px' }"
    >
      <div class="ant-dropdown-link" @click.prevent>
        <div class="menu-top">
          <a-dropdown>
            <div v-if="myTeam" class="select-team">
              <a-avatar
                :size="64"
                :src="myTeam.Logo"
                :style="{ padding: '10px' }"
              >
              </a-avatar>
              {{ currentTeam }}
            </div>
            <div v-else class="select-team">
              <a-avatar
                style="padding: 10px"
                :size="64"
                src="https://media.npr.org/assets/img/2016/03/29/ap_090911089838_sq-3271237f28995f6530d9634ff27228cae88e3440.jpg"
              >
              </a-avatar>
              Spectator
            </div>
            <template #overlay>
              <a-menu
                theme="dark"
                :style="{ lineHeight: '64px', 'background-color': '#080808' }"
              >
                <a-menu-item
                  v-for="team in Object.keys(teams)"
                  :key="team"
                  v-on:click="this.changeCurrentTeam(team)"
                >
                  <p>
                    <a-avatar
                      :size="48"
                      :src="teams[team].Logo"
                      :style="{ padding: '10px' }"
                    >
                    </a-avatar
                    >{{ team }}
                  </p>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-button v-if="isMobile()" type="primary" class="toggle">
            <menu-unfold-outlined
              v-if="collapsed"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
            <menu-fold-outlined
              v-else
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
          </a-button>
        </div>
      </div>

      <div v-if="!collapsed">
        <a-menu-item key="Home">
          <router-link to="/">Home</router-link></a-menu-item
        >
        <a-menu-item key="Players">
          <router-link to="/players">Players</router-link></a-menu-item
        >
        <a-sub-menu>
          <template #title>
            <span class="submenu-title-wrapper">
              Teams
            </span>
          </template>

          <a-menu-item v-for="team in Object.keys(teams)" :key="team">
            <router-link :to="{ name: 'Team', params: { teamName: team } }">{{
              team
            }}</router-link>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu>
          <template #title>
            <span class="submenu-title-wrapper">
              Stats
            </span>
          </template>
          <a-menu-item key="Stats">
            <router-link to="/stats">League Leaders</router-link></a-menu-item
          >
          <a-menu-item key="TeamStats">
            <router-link to="/teamstats">Team Stats</router-link></a-menu-item
          >
        </a-sub-menu>
        <a-sub-menu>
          <template #title>
            <span class="submenu-title-wrapper">
              Trade Tools
            </span>
          </template>
          <a-menu-item key="Trade Analyzer">
            <router-link to="/trade_analyzer"
              >Trade Creator</router-link
            ></a-menu-item
          >
          <a-menu-item key="Trade Requests">
            <router-link to="/trade_requests"
              >Trade Requests</router-link
            ></a-menu-item
          >
          <a-menu-item key="Player Comparator">
            <router-link to="/player_compare"
              >Player Compare Tool</router-link
            ></a-menu-item
          >
          <a-menu-item key="Trade Block">
            <router-link to="/trade_block"
              >Trade Block</router-link
            ></a-menu-item
          >
        </a-sub-menu>
        <a-sub-menu>
          <template #title>
            <span class="submenu-title-wrapper">
              Offseason Tools
            </span>
          </template>
          <a-menu-item key="Draft Board">
            <router-link to="/draft">Draft Board</router-link></a-menu-item
          >
          <a-menu-item key="Free Agency">
            <router-link to="/free_agency"
              >Free Agency</router-link
            ></a-menu-item
          >
          <a-menu-item key="Offseason Training">
            <router-link to="/offseason_training"
              >Offseason Training</router-link
            ></a-menu-item
          >
        </a-sub-menu>
        <a-menu-item key="Coaches">
          <router-link to="/coaches">Coaches</router-link></a-menu-item
        >
        <a-menu-item key="Schedule">
          <router-link to="/schedule">Schedule</router-link></a-menu-item
        >
        <a-menu-item key="Tendency Mappings">
          <router-link to="/coach_tendencies"
            >Tendency Mapping</router-link
          ></a-menu-item
        >
        <a-menu-item key="All Star Game">
          <router-link to="/asg">All Star Game</router-link></a-menu-item
        >
        <a-menu-item key="Streamers">
          <router-link to="/for_streamers"
            >For Streamers</router-link
          ></a-menu-item
        >
        <a-sub-menu>
          <template #title>
            <span class="submenu-title-wrapper">
              Season
            </span>
          </template>
          <a-menu-item
            :key="item.Key"
            v-for="item in archives"
            v-on:click="this.changeSeason(item.Key)"
          >
            VNBA Season {{ item.Season }}
          </a-menu-item>
          <a-menu-item
            key="seasonSix"
            v-on:click="
              this.changeSeason('1INS-TKERe24QAyJCkhkhWBQK4eAWF8RVffhN1BZNRtA')
            "
          >
            VNBA Current
          </a-menu-item>
        </a-sub-menu>
        <div class="actions">
          <a-button
            type="primary"
            v-if="currentTeam"
            v-on:click="this.changeModalVisible()"
          >
            Edit Roster
          </a-button>
          <a-button
            :style="{ marginTop: '10px' }"
            type="primary"
            v-if="currentTeam"
            v-on:click="changePickupVisible()"
          >
            Drop Players
          </a-button>
          <a-button
            :style="{ marginTop: '10px' }"
            type="primary"
            v-if="currentTeam"
            v-on:click="refreshPlayers()"
          >
            Refresh Contracts
          </a-button>
          <a-button
            :style="{ marginTop: '10px' }"
            type="primary"
            :danger="!(myTeam['Ready For Signings'] == 'TRUE')"
            v-if="currentTeam"
            v-on:click="toggleFlag()"
          >
            {{
              myTeam["Ready For Signings"] == "TRUE"
                ? "Ready For Signings!"
                : "Not Ready for Signings"
            }}
          </a-button>
        </div>
      </div>
    </a-menu>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import { isMobile } from "../utils/layoutHelper";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";

export default {
  name: "Menu",
  props: {
    changePickupVisible: Function
  },
  setup() {
    return {
      isMobile
    };
  },
  computed: {
    ...mapState([
      "playerList",
      "teams",
      "loading",
      "processing",
      "sheetsId",
      "archives",
      "currentTeam"
    ]),
    myTeam() {
      return this.teams[this.currentTeam];
    }
  },
  data() {
    return {
      current: [this.$router.currentRoute._rawValue.name],
      collapsed: this.isMobile(),
      pickupVisible: false
    };
  },
  mounted() {
    this.current = [this.$router.currentRoute._rawValue.name];
    this.getSheetData(this.sheetsId);
  },
  watch: {
    $route(to, from) {
      this.current = [to.name];
    }
  },
  methods: {
    testFn() {
      console.log(this.playerList);
    },
    getArchiveKeys() {
      return _.keys(this.archives);
    },
    ...mapActions({
      getSheetData: "getSheetData",
      changeSeason: "changeSeason",
      changeCurrentTeam: "changeCurrentTeam",
      changeModalVisible: "changeModalVisible",
      refreshPlayers: "refreshPlayers",
      toggleFlag: "toggleFlag"
    })
  },
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined
  }
};
</script>
<style lang="scss">
.menu-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #080808;
}

.toggle {
  margin: 0 20px;
}

.actions {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
