<template>
  <div class="trades-container">
    <a-timeline mode="alternate">
      <a-timeline-item v-for="(trade, index) in modernTrades" :key="index">
        <template #dot
          ><div class="teams-list">
            <img
              v-for="(team, index) in getTradeTeams(trade)"
              :key="index"
              :src="getTeamLogo(team)"
              style="width: 32px; object-fit: contain;"
            /></div
        ></template>
        <pre class="trade-desc">
          <strong>{{ trade["Date"] }}</strong>
          {{ getTradeDesc(trade) }}
        </pre>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapState } from "vuex";
import { DribbbleOutlined } from "@ant-design/icons-vue";
export default {
  computed: {
    ...mapState(["trades", "teams"]),
    modernTrades() {
      return this.trades.filter(trade => trade.Status === "ACCEPTED");
    }
  },
  name: "Trades",
  components: { DribbbleOutlined },
  methods: {
    getTeamLogo(team) {
      return this.teams[team].Logo;
    },
    structureTrade(tradeContents) {
      const tc = tradeContents;
      return tc.players
        .concat(
          tc.assets.map(asset => `${asset.Name} draft pick`),
          tc.camps?.map(camp => `${camp.Name} camp`),
          tc.cash.map(cash => `$${cash.value}`)
        )
        .join(", ");
    },
    getTradeTeams(trade) {
      const teams = ["Team 1", "Team 2", "Team 3", "Team 4"];
      const activeTeams = [];
      teams.forEach(teamNum => {
        if (trade[teamNum]) activeTeams.push(trade[teamNum]);
      });

      return activeTeams;
    },
    getTradeDesc(trade) {
      const teams = ["Team 1", "Team 2", "Team 3", "Team 4"];
      let statement = ``;
      teams.forEach(teamNum => {
        if (trade[teamNum])
          statement =
            statement +
            `${trade[teamNum]} receive ${this.structureTrade(
              trade[`${teamNum} receives`]
            )}\n`;
      });
      return statement;
    }
  }
};
</script>

<style scoped lang="scss">
.trades-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin: auto;
  height: 100%;
  background-color: white;
  min-height: 50vh;
  padding: 60px 40px;
  max-width: 900px;
}
.teams-list {
  display: flex;
  flex-direction: row;
  margin: 20px;
  padding: 20px;
}

.trade-desc {
  margin: 0 50px;
  white-space: pre-line;
  font-family: inherit;
}
</style>

<style>
.ant-timeline-item-head {
  background-color: transparent !important;
}

.ant-timeline-item-content {
}
</style>
