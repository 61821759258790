<template>
  <div class="tr-page">
    <h2>Your Trade Requests</h2>
    <a-row
      display="flex"
      justify="center"
      class="trade-row"
      v-for="trade in requestedTrades"
      :key="trade"
    >
      <a-col :md="24">
        <a-card
          :bodyStyle="{
            padding: '3px',
            marginBottom: '5px'
          }"
        >
          <a-row class="player-info" display="flex" justify="space-between">
            <a-col :span="2">
              <div class="trait-name">Date</div>
              {{ trade.Date }}
            </a-col>
            <a-col :span="4" v-for="idx in 4" :key="idx">
              <div v-if="trade[`Team ${idx}`].length">
                <div class="trait-name">
                  <span
                    :style="{
                      color: getTeam(trade[`Team ${idx}`])['Primary Color'],
                      fontSize: '16px',
                      fontWeight: 'bold'
                    }"
                    >{{ trade[`Team ${idx}`] }}</span
                  >
                  get...
                  <span
                    v-if="trade[`Team ${idx} accepts`] === 'ACCEPTED'"
                    class="accepted-icon"
                    ><check-circle-outlined
                  /></span>
                  <span
                    v-else-if="trade[`Team ${idx} accepts`] === 'REJECTED'"
                    class="rejected-icon"
                    ><close-circle-outlined
                  /></span>
                  <span v-else class="question-icon"
                    ><question-circle-outlined
                  /></span>
                </div>
                <div v-if="getReceivesFromTrade(trade, idx)">
                  <div
                    v-for="player in getReceivesFromTrade(trade, idx).players"
                    :key="player"
                  >
                    <a-popover placement="left" :title="player">
                      <template #content>
                        <img
                          class="preview-image"
                          :src="getPlayer(player).Image"
                        />
                      </template>
                      {{ player }}
                    </a-popover>
                  </div>
                  <div
                    v-for="asset in getReceivesFromTrade(trade, idx).assets"
                    :key="asset"
                  >
                    {{ asset.Name }}
                  </div>
                  <div
                    v-for="cash in getReceivesFromTrade(trade, idx).cash"
                    :key="cash"
                  >
                    ${{ cash.value }} from {{ cash.name }}
                  </div>
                  <div
                    v-for="camp in getReceivesFromTrade(trade, idx).camps"
                    :key="camp"
                  >
                    {{ camp.From }} {{ camp.Name.split("-")[1] }} Camp
                  </div>
                </div>
                <div v-else>-</div>
              </div>
            </a-col>

            <a-col :span="2">
              <div class="trait-name">Status</div>
              <div
                :style="{
                  color:
                    trade.Status === 'ACCEPTED'
                      ? 'green'
                      : trade.Status === 'REJECTED'
                      ? 'red'
                      : 'gray'
                }"
              >
                {{ trade.Status }}
              </div>
            </a-col>
          </a-row>
        </a-card>
        <a-row
          display="flex"
          justify="space-around"
          class="comment-row"
          align="middle"
        >
          <a-col :span="12">
            <div class="trait-name">
              Proposed By
            </div>
            <a-comment class="comment">
              <template #author> {{ trade["Proposed By"] }} </template>
              <template #avatar>
                <a-avatar :src="getTeam(trade['Proposed By']).Logo" alt="Roj" />
              </template>
              <template #content>
                <p>
                  {{ trade.Comments }}
                </p>
              </template>
            </a-comment>
          </a-col>
          <a-col :span="6">
            <div class="trait-name">
              Actions
            </div>
            <a-row class="action-buttons" display="flex">
              <a-button
                :disabled="!!trade[`Team ${getTeamNumber(trade)} accepts`]"
                @click="onAccept(trade)"
                type="primary"
                class="accept-button"
                >Accept</a-button
              >
              <a-button
                :disabled="!!trade[`Team ${getTeamNumber(trade)} accepts`]"
                type="primary"
                @click="onReject(trade)"
                danger
                class="reject-button"
                >Reject</a-button
              >
              <a-button
                :disabled="
                  trade['Proposed By'] !== currentTeam ||
                    trade.Status !== 'PENDING'
                "
                type="default"
                @click="onCancel(trade)"
                class="reject-button"
                >Cancel Trade</a-button
              >
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons-vue";
import { nameToInitial } from "@/utils/convertHelper.js";
export default {
  name: "Trade Request",
  data() {
    return {};
  },
  computed: {
    ...mapState(["pList", "teams", "currentTeam", "trades"]),
    requestedTrades() {
      return this.trades
        .filter(trade => trade.Status !== "OLD" && this.isInTrade(trade))
        .reverse();
    }
  },
  methods: {
    getReceivesFromTrade(tradeObj, teamNum) {
      if (tradeObj[`Team ${teamNum} receives`]) {
        return tradeObj[`Team ${teamNum} receives`];
      } else {
        return false;
      }
    },
    getPlayer(name) {
      return this.pList[nameToInitial(name)];
    },
    getTeam(name) {
      return this.teams[name];
    },
    isInTrade(trade) {
      const teamSet = new Set();
      teamSet.add(trade["Team 1"]);
      teamSet.add(trade["Team 2"]);
      teamSet.add(trade["Team 3"]);
      teamSet.add(trade["Team 4"]);

      return teamSet.has(this.currentTeam);
    },
    getTeamNumber(trade) {
      const teamArr = [];
      teamArr.push(trade["Team 1"]);
      teamArr.push(trade["Team 2"]);
      teamArr.push(trade["Team 3"]);
      teamArr.push(trade["Team 4"]);
      for (const [i, t] of teamArr.entries()) {
        if (t === this.currentTeam) return i + 1;
      }

      return false;
    },
    getNumTeamInvolved(trade) {
      const teamArr = [];
      teamArr.push(trade["Team 1"]);
      teamArr.push(trade["Team 2"]);
      teamArr.push(trade["Team 3"]);
      teamArr.push(trade["Team 4"]);

      const teamArrAccepts = [];
      teamArrAccepts.push(trade["Team 1 accepts"]);
      teamArrAccepts.push(trade["Team 2 accepts"]);
      teamArrAccepts.push(trade["Team 3 accepts"]);
      teamArrAccepts.push(trade["Team 4 accepts"]);

      return (
        teamArrAccepts.filter(t => t === "ACCEPTED").length + 1 >=
        teamArr.filter(t => !!t).length
      );
    },
    onAccept(trade) {
      this.addTradeResponse({
        trade: trade,
        teamNum: this.getTeamNumber(trade),
        acceptance: "ACCEPTED",
        finalSay: this.getNumTeamInvolved(trade)
      });
    },
    onReject(trade) {
      this.addTradeResponse({
        trade: trade,
        teamNum: this.getTeamNumber(trade),
        acceptance: "REJECTED",
        finalSay: true
      });
    },
    onCancel(trade) {
      this.cancelTrade({
        trade: trade
      });
    },
    ...mapActions({
      addTradeResponse: "addTradeResponse",
      cancelTrade: "cancelTrade"
    })
  },
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
    QuestionCircleOutlined
  }
};
</script>
<style scoped>
.trait-name {
  font-size: 12px;
  color: gray;
  text-align: left;
}
.player-info {
  text-align: left;
  padding: 6px;
}
.preview-image {
  max-width: 200px;
}
.logo-background {
  position: absolute;
  height: 100%;
  top: 0;
  opacity: 25%;
}
.comment-row {
  margin-top: 5px;
}
.comment {
  background-color: white;
  padding: 0px 20px;
  border-radius: 5px;
  margin: 10px;
  transition: 0.2s;
  border: 1px solid lightgray;
  font-size: 26px;
  width: 100%;
}
p {
  text-align: left;
}
.action-buttons {
  margin-top: 10px;
  width: 100%;
  vertical-align: middle;
}
.accept-button {
  margin: auto 5px;
}
.reject-button {
  margin: auto 5px;
}
.accepted-icon {
  color: green;
  font-size: 16px;
}
.rejected-icon {
  color: red;
  font-size: 16px;
}
.question-icon {
  color: skyblue;
  font-size: 16px;
}
.tr-page {
  margin: 10px 0;
}
.trade-row {
  margin: 10px 0;
}
</style>
