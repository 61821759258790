import { createRouter, createWebHistory } from "vue-router";
import ASG from "../views/ASG.vue";
import Home from "../views/Home.vue";
import Players from "../views/Players.vue";
import Stats from "../views/Stats.vue";
import Team from "../views/Team.vue";
import TeamStats from "../views/TeamStats.vue";
import Trades from "../views/Trades.vue";
import Schedule from "../views/Schedule.vue";
import Draft from "../views/Draft.vue";
import FA from "../views/FA.vue";
import TA from "../views/TradeAnalyzer.vue";
import TB from "../views/TradeBlock.vue";
import PC from "../views/PlayerCompare.vue";
import TR from "../views/TradeRequests.vue";
import FS from "../views/ForStreamers.vue";
import Coaches from "../views/Coaches.vue";
import OffseasonTraining from "../views/OffseasonTraining.vue";
import TendencyMappings from "../views/CoachTendencies.vue";
import PlayerPage from "../views/PlayerPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },

  {
    path: "/players",
    name: "Players",
    component: Players
  },

  {
    path: "/team/:teamName",
    name: "Team",
    component: Team,
    props: route => ({
      team: route.params.teamName
    })
  },

  {
    path: "/stats",
    name: "Stats",
    component: Stats
  },

  {
    path: "/teamstats",
    name: "TeamStats",
    component: TeamStats
  },

  {
    path: "/trades",
    name: "Trades",
    component: Trades
  },

  {
    path: "/schedule",
    name: "Schedule",
    component: Schedule
  },

  {
    path: "/draft",
    name: "Draft Board",
    component: Draft
  },

  {
    path: "/free_agency",
    name: "Free Agency",
    component: FA
  },
  {
    path: "/trade_analyzer",
    name: "Trade Analyzer",
    component: TA
  },
  {
    path: "/trade_requests",
    name: "Trade Requests",
    component: TR
  },
  {
    path: "/player_compare",
    name: "Player Compare Tool",
    component: PC
  },
  {
    path: "/for_streamers",
    name: "Info For Streamers",
    component: FS
  },
  {
    path: "/coaches",
    name: "Coaches",
    component: Coaches
  },
  {
    path: "/offseason_training",
    name: "Offseason Training",
    component: OffseasonTraining
  },
  {
    path: "/coach_tendencies",
    name: "Tendency Mappings",
    component: TendencyMappings
  },
  {
    path: "/player/:id",
    name: "Player",
    component: PlayerPage
  },
  {
    path: "/trade_block",
    name: "Trade Block",
    component: TB
  },
  {
    path: "/asg",
    name: "All Star Game",
    component: ASG
  }
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
