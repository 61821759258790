<template>
  <div>
    <a-table
      class="player-table"
      :columns="
        useRank
          ? isMobile
            ? draftColumns.slice(0, 3)
            : draftColumns
          : isMobile
          ? mobileColumns
          : columns
      "
      :rowKey="record => record.Name"
      :data-source="playerValues"
      :pagination="false"
      :bordered="false"
      :expandRowByClick="true"
      :expandIconAsCell="false"
      :expandedRowKeys="currentExpanded"
      :onExpand="onRowExpand"
      :expandIconColumnIndex="-1"
      :expandIcon="
        () => {
          return null;
        }
      "
      :rowClassName="
        () => {
          return 'custom-row';
        }
      "
    >
      <template #bodyCell="{text, record, index, column}">
        <div v-if="column.key === 'Rank'">
          {{ toGmRank(record) }}
        </div>
        <div v-if="column.key === 'XOverall'">
          {{
            record["TriKov Value"] === "0"
              ? "N/A"
              : Math.floor(toXOverall(record))
          }}
        </div>
        <div
          v-if="
            [
              'Combined Rank',
              `Gallo's Rank`,
              `Morris' Rank`,
              `Soto's Rank`
            ].includes(column.title)
          "
        >
          {{ toXRank(column.dataIndex, record.Name) }}
        </div>
        <div
          v-if="column.title === 'Team'"
          :class="['team-column']"
          :style="{
            backgroundColor: getTeamColors(record, this.teams).primary,
            borderBottom: `5px solid ${
              getTeamColors(record, this.teams).secondary
            }`,
            color: 'white'
          }"
        >
          <div v-if="isMobile">
            <a-avatar
              shape="circle"
              :src="getTeamLogo(record, teams)"
            ></a-avatar>
          </div>
          <div v-else>{{ text }}</div>
        </div>
        <div v-if="column.title === 'Position'">
          {{ getPositionName(record) }}
        </div>

        <a-badge
          v-if="column.title === 'Name'"
          :count="getPendingBoosts(record).length"
          class="name-badge"
          :number-style="{ backgroundColor: '#1DA57A' }"
        >
          {{ text }}
          <a-tooltip v-if="record['Age'] === '1'">
            <template #title>Rookie Player</template>
            <trademark-circle-two-tone
              class="desc-icon"
              two-tone-color="#CF9FFF"
            />
          </a-tooltip>
          <a-tooltip v-if="getSupermax(record)">
            <template #title>Player is on a Supermax Contract.</template>
            <dollar-two-tone class="desc-icon" two-tone-color="#00FF00" />
          </a-tooltip>
          <a-tooltip v-if="record.Status !== 'HEALTHY'">
            <template #title
              ><div>
                Player is Injured: {{ JSON.parse(record.Status).Name }}
              </div>
              <div>
                Minorly affects {{ JSON.parse(record.Status).AffectedLow }}
              </div>
              <div>
                Majorly affects {{ JSON.parse(record.Status).AffectedHigh }}
              </div>
              <div>
                {{
                  JSON.parse(record.Status).DNP === true
                    ? "Recommended DNP"
                    : ""
                }}
              </div></template
            >
            <medicine-box-two-tone class="desc-icon" two-tone-color="#eb2f96" />
          </a-tooltip>
          <a-tooltip v-if="isOnTradeBlock(record)">
            <template #title>Player is on the trade block.</template>
            <car-two-tone class="desc-icon" two-tone-color="#920c51" />
          </a-tooltip>
          <a-tooltip v-if="getOnFire(record)">
            <template #title
              >Player is on fire compared to last season.</template
            >
            <fire-two-tone class="desc-icon" two-tone-color="#FF0000" />
          </a-tooltip>
          <a-tooltip v-if="getTwoWay(record)">
            <template #title
              >Player is on a 2 way contract and can be offered cash to sign
              elsewhere</template
            >
            <highlight-two-tone class="desc-icon" two-tone-color="#FEE349" />
          </a-tooltip>
          <a-tooltip v-if="getBust(record)">
            <template #title
              >Player is underperforming compared to last season.</template
            >
            <frown-two-tone class="desc-icon" two-tone-color="#E97451" />
          </a-tooltip>
          <a-tooltip v-if="ifMostValuable(record) && !useRank">
            <template #title>Most Valuable Player on Team</template>
            <crown-two-tone class="desc-icon" two-tone-color="#FFBF00" />
          </a-tooltip>
          <a-tooltip v-if="record['Contract Length'] === 1 && !useRank">
            <template #title>Player is on an expiring contract.</template>
            <file-excel-two-tone class="desc-icon" two-tone-color="#0000FF" />
          </a-tooltip>
          <a-tooltip v-if="isRetiring(record)">
            <template #title>Player is retiring next season.</template>
            <alert-two-tone class="desc-icon" two-tone-color="#6A6A69" />
          </a-tooltip>
        </a-badge>
        <div v-if="column.title === 'Salary'">
          {{ getContract(record)["Cash"] || 0 }}
        </div>
        <div v-if="column.title === 'Height'">
          {{ getHeight(record) }}
        </div>
        <div v-if="column.title === 'Weight'">
          {{ getWeight(record) }}
        </div>
        <div v-if="column.title === 'Wingspan'">
          {{ getWingspan(record) }}
        </div>
        <div v-if="column.title === 'Minutes'">
          {{ getContract(record)["Minutes"] || 0 }}
        </div>

        <div v-if="column.dataIndex === 'Contract Length'">
          <a-col>
            <a-row>
              <a-button
                class="pickup-button"
                :disabled="!globals.canDraft.status"
                :onClick="e => signPlayer(e, record)"
                v-if="record.Team === 'Rookie'"
              >
                Draft <user-add-outlined />
              </a-button>

              <a-button
                class="pickup-button"
                :disabled="!currentTeam || !globals.canSignFA.status"
                :onClick="e => signPlayer(e, record)"
                v-else-if="record.Team === 'FA'"
              >
                Sign Two-Way <solution-outlined />
              </a-button>

              <div
                v-else-if="
                  record['Contract Length'] !== 0 &&
                    record['Contract Offer'].Team
                "
              >
                {{ parseInt(record["Contract Length"]) }}
              </div>
            </a-row>
            <a-row>
              <a-button
                class="pickup-button"
                :disabled="!currentTeam || !globals.canOfferFA.status"
                :onClick="e => offerContract(e, record)"
                v-if="
                  !record['Contract Offer'].Team && record.Team !== 'Rookie'
                "
              >
                Offer Contract <solution-outlined />
              </a-button>
            </a-row>
          </a-col>
        </div>
      </template>

      <template #expandedRowRender="{ record }">
        <PlayerDetails
          :player="record"
          :playerCount="getSignedPlayerCount()"
          :clearExpanded="clearExpandedRow"
        />
      </template>
    </a-table>
    <RosterPickup
      :visible="pickupVisible"
      :team="currentTeam"
      :playerToAdd="playerToAdd"
      :handleCancel="changePickupVisible"
    />
    <AuctionModal
      v-if="globals.canOfferFA.status && currentTeam"
      :visible="pickupContractVisible"
      :team="currentTeam"
      :playerToAdd="playerToAdd"
      :handleCancel="changePickupContractVisible"
      :remainingCash="getSpentCash()"
      :remainingMinutes="240 - getTotalMinutes()"
      :fromPlayerTable="true"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  UserAddOutlined,
  SolutionOutlined,
  MedicineBoxTwoTone,
  FireTwoTone,
  DollarTwoTone,
  FileExcelTwoTone,
  TrademarkCircleTwoTone,
  CrownTwoTone,
  FrownTwoTone,
  CarTwoTone,
  HighlightTwoTone,
  AlertTwoTone
} from "@ant-design/icons-vue";
import { labels } from "../models/labels";
import Statline from "./Statline";
import PlayerDetails from "./PlayerDetails";
import RosterPickup from "./RosterPickup";
import AuctionModal from "./AuctionModal";
import {
  getPositionName,
  getHeight,
  getWingspan,
  getTeamColors,
  getTeamLogo
} from "../utils/playerHelper";
import { drawPropic } from "../utils/propicHelper";

export default {
  name: "PlayerTable",
  props: ["players", "showTriKov", "useRank"],
  setup() {
    return {
      getPositionName,
      getHeight,
      getWingspan,
      getTeamColors,
      getTeamLogo,
      drawPropic
    };
  },
  data() {
    return {
      sortKey: "Rank",
      reverse: false,
      charTypes: {
        standard: ["Points", "Rebounds", "Assists", "Steals", "Blocks"],
        efficiency: ["FG%", "3PT%", "FT%", "TS%", "eFG%"],
        advanced: ["PER", "USG%", "GmSc", "Rtg Diff", "WS"]
      },
      currentExpanded: [],
      playerToAdd: {},
      pickupVisible: false,
      pickupContractVisible: false,
      rankWithouts: ["Team", "Age", "Loyalty"],
      columns: [
        {
          title: "Name",
          dataIndex: "Name",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            a.Name.localeCompare(b.Name, "en", { numeric: true })
        },
        // {
        //   title: "XOverall",
        //   dataIndex: "TriKov Value",
        //   key: "XOverall",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => this.toXOverall(a) - this.toXOverall(b),
        //   sortDirections: ["descend", "ascend"],
        //   defaultSortOrder: "descend"
        // },
        // {
        //   title: "Overall",
        //   dataIndex: "Overall",
        //   key: "Overall",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => a["Overall"] - b["Overall"],
        //   sortDirections: ["descend", "ascend"]
        // },
        {
          title: "Rank",
          dataIndex: "Rank",
          key: "Rank",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.toGmRank(a) - this.toGmRank(b),
          sortDirections: ["ascend", "descend"],
          defaultSortOrder: "ascend"
        },
        {
          title: "Position",
          dataIndex: "Position",
          key: "Position",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            this.getPositionName(a.Data?.VITALS.POSITION).localeCompare(
              this.getPositionName(b.Data?.VITALS.POSITION),
              "en",
              {
                numeric: true
              }
            ),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Height",
          dataIndex: "Height",
          key: "Height",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            parseInt(_.get(a, ["Data", "VITALS", "HEIGHT_CM"])) -
            parseInt(_.get(b, ["Data", "VITALS", "HEIGHT_CM"])),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Weight",
          dataIndex: "Weight",
          key: "Weight",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.getWeight(a) - this.getWeight(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Team",
          dataIndex: "Team",
          key: "Team",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            a.Team.localeCompare(b.Team, "en", { numeric: true }),
          sortDirections: ["descend", "ascend"],
          defaultSortOrder: "descend"
        },
        {
          title: "Age",
          dataIndex: "Age",
          key: "Age",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a.Age - b.Age,
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Salary",
          dataIndex: "Salary",
          key: "Salary",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.getContract(a).Cash - this.getContract(b).Cash,
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Minutes",
          dataIndex: "Minutes",
          key: "Minutes",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            parseInt(this.getContract(a).Minutes) -
            parseInt(this.getContract(b).Minutes),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Loyalty",
          dataIndex: "Loyalty",
          key: "Loyalty",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a["Loyalty"] - b["Loyalty"],
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Contract Length",
          dataIndex: "Contract Length",
          key: "Contract Lenght",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a["Contract Length"] - b["Contract Length"],
          sortDirections: ["descend", "ascend"]
        }
      ],
      mobileColumns: [
        {
          title: "Name",
          dataIndex: "Name",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            a.Name.localeCompare(b.Name, "en", { numeric: true })
        },
        // {
        //   title: "XOvr",
        //   dataIndex: "TriKov Value",
        //   key: "XOverall",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => this.toXOverall(a) - this.toXOverall(b),
        //   sortDirections: ["descend", "ascend"],
        //   defaultSortOrder: "descend"
        // },
        {
          title: "Rank",
          dataIndex: "Rank",
          key: "mRank",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.toGmRank(a) - this.toGmRank(b),
          sortDirections: ["ascend", "descend"],
          defaultSortOrder: "ascend"
        },
        {
          title: "Team",
          dataIndex: "Team",
          key: "Team",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            a.Team.localeCompare(b.Team, "en", { numeric: true }),
          sortDirections: ["descend", "ascend"],
          defaultSortOrder: "descend"
        }
      ],
      draftColumns: [
        {
          title: "Name",
          dataIndex: "Name",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            a.Name.localeCompare(b.Name, "en", { numeric: true })
        },
        {
          title: "Rank",
          dataIndex: "Rank",
          key: "Rank",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.toGmRank(a) - this.toGmRank(b),
          sortDirections: ["ascend", "descend"],
          defaultSortOrder: "ascend"
        },
        // {
        //   title: "Overall",
        //   dataIndex: "Overall",
        //   key: "Overall",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => a["Overall"] - b["Overall"],
        //   sortDirections: ["descend", "ascend"],
        //   defaultSortOrder: "descend"
        // },
        // {
        //   title: "Combined Rank",
        //   dataIndex: "TriKov Value",
        //   key: "Combined Rank",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => a["TriKov Value"] - b["TriKov Value"],
        //   sortDirections: ["descend", "ascend"]
        // },
        // {
        //   title: "Gallo's Rank",
        //   dataIndex: "botValue1",
        //   key: "Gallo's Rank",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => a["botValue1"] - b["botValue1"],
        //   sortDirections: ["descend", "ascend"]
        // },
        // {
        //   title: "Morris' Rank",
        //   dataIndex: "botValue2",
        //   key: "Morris' Rank",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => a["botValue2"] - b["botValue2"],
        //   sortDirections: ["descend", "ascend"]
        // },
        // {
        //   title: "Soto's Rank",
        //   dataIndex: "botValue3",
        //   key: "Soto's Rank",
        //   onFilter: (value, record) => record.name.indexOf(value) === 0,
        //   sorter: (a, b) => a["botValue3"] - b["botValue3"],
        //   sortDirections: ["descend", "ascend"]
        // },
        {
          title: "Position",
          dataIndex: "Position",
          key: "Position",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) =>
            this.getPositionName(a.Data?.VITALS.POSITION).localeCompare(
              this.getPositionName(b.Data?.VITALS.POSITION),
              "en",
              {
                numeric: true
              }
            ),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Height",
          dataIndex: "Height",
          key: "Height",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => getHeight(a) - getHeight(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Weight",
          dataIndex: "Weight",
          key: "Weight",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => this.getWeight(a) - this.getWeight(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Wingspan",
          dataIndex: "Wingspan",
          key: "Wingspan",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => getWingspan(a) - getWingspan(b),
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Contract Length",
          dataIndex: "Contract Length",
          key: "Contract Lenght",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a["Contract Length"] - b["Contract Length"],
          sortDirections: ["descend", "ascend"]
        },
        {
          title: "Shades Of",
          dataIndex: "Shades Of",
          key: "Shades Of",
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a["Shades Of"] - b["Shades Of"],
          sortDirections: ["descend", "ascend"]
        }
      ]
    };
  },
  computed: {
    ...mapState(["pList", "currentTeam", "globals", "tradeBlock", "teams"]),
    playerList() {
      return _.values(this.players);
    },
    teamPlayers() {
      const setTeam = this.currentTeam;
      return _.values(
        _.pickBy(this.pList, function(value, key) {
          return value.Team === setTeam;
        })
      );
    },
    teamInfo() {
      return this.teams[this.currentTeam];
    },
    mobileHeaders() {
      if (!this.playerList.length) return [];
      const columns = ["Name", "Team"]
        .concat(this.showTriKov ? ["TriKov Value"] : [])
        .map(header => {
          return {
            title: header,
            dataIndex: header,
            key: header,
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) =>
              a[header].localeCompare(b[header], "en", { numeric: true }),
            sortDirections: ["descend", "ascend"]
          };
        });
      columns.splice(1, 0, this.customHeaders);
      return columns.flat();
    },
    isMobile() {
      return window.outerWidth < 500;
    },
    playerValues() {
      return _.values(this.players);
    },
    chartWidth() {
      return window.innerWidth * 0.3;
    },
    statLabels() {
      return labels;
    },
    sortedGmRank() {
      const getGameScore = player => {
        return _.get(player, ["Stats", player.Team, "Advanced", "GmSc"], 0);
      };
      const playerListClone = [...this.playerList];

      playerListClone.sort(function(a, b) {
        return getGameScore(b) - getGameScore(a);
      });
      return playerListClone;
    }
  },
  methods: {
    getWeight(player) {
      return parseInt(_.get(player, ["Data", "VITALS", "WEIGHT_LBS"]));
    },
    sortBy: function(sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;

      this.sortKey = sortKey;
    },
    getSignedPlayerCount() {
      return _.values(this.pList).filter(player => player.Team != "FA").length;
    },
    onRowExpand(expanded, record) {
      const keys = [];
      if (expanded) {
        this.currentExpanded.push(record.Name);
      } else {
        const itemIdx = this.currentExpanded.indexOf(record.Name);
        if (itemIdx >= 0) {
          this.currentExpanded.splice(itemIdx, 1);
        }
      }
    },
    clearExpandedRow() {
      this.currentExpanded = [];
    },
    getPendingBoosts(player) {
      return player.Updates.filter(
        boost => boost["Done?"] !== "yes" && !(boost.Event === "budget")
      );
    },
    signPlayer(e, player) {
      e.stopPropagation();

      this.playerToAdd = player;
      this.changePickupVisible();
    },
    offerContract(e, player) {
      e.stopPropagation();

      this.playerToAdd = player;
      this.changePickupContractVisible();
    },
    changePickupVisible() {
      this.pickupVisible = !this.pickupVisible;
    },
    changePickupContractVisible() {
      this.pickupContractVisible = !this.pickupContractVisible;
    },
    toXOverall(val) {
      return (parseInt(val["TriKov Value"]) / 50) * 40 + 60;
    },
    toXRank(analystId, player) {
      const playerListClone = [...this.playerList];
      playerListClone.sort(function(a, b) {
        return b[analystId] - a[analystId];
      });

      return (
        _.findIndex(
          playerListClone.map(plyr => plyr.Name),
          p => p === player
        ) + 1
      );
    },
    toGmRank(player) {
      return (
        _.findIndex(
          this.sortedGmRank.map(plyr => plyr.Name),
          p => p === player.Name
        ) + 1
      );
    },
    getContract(player) {
      return player["Contract Offer"];
    },
    getOnFire(player) {
      const gameScore = _.get(player, [
        "Stats",
        player.Team,
        "Advanced",
        "GmSc"
      ]);
      const oldScore = _.get(player, ["Stats", "Previous", "Advanced", "GmSc"]);

      return parseFloat(gameScore) - parseFloat(oldScore) > 3.5;
    },
    getBust(player) {
      const gameScore = _.get(player, [
        "Stats",
        player.Team,
        "Advanced",
        "GmSc"
      ]);
      const oldScore = _.get(player, ["Stats", "Previous", "Advanced", "GmSc"]);

      return parseFloat(oldScore) - parseFloat(gameScore) > 5;
    },
    getSupermax(player) {
      const contract = player["Contract Offer"];
      return contract.Supermax;
    },
    getTwoWay(player) {
      const contract = player["Contract Offer"];
      return (
        !contract.Cash &&
        !!player.Team &&
        player.Team !== "Rookie" &&
        player.Team !== "FA"
      );
    },
    isRetiring(player) {
      return player["Retiring?"];
    },
    ifMostValuable(player) {
      const teamPlayers = _.values(this.pList)
        .filter(pl => pl.Team === player.Team)
        .sort(
          (a, b) =>
            parseFloat(_.get(b, ["Stats", b.Team, "Advanced", "GmSc"], 0)) -
            parseFloat(_.get(a, ["Stats", a.Team, "Advanced", "GmSc"], 0))
        );

      return teamPlayers[0] === player;
    },
    isOnTradeBlock(player) {
      return _.find(
        this.tradeBlock,
        tb => tb.label === player.Name && tb.status === "Selling"
      );
    },
    getSpentCash() {
      return parseInt(this.teamInfo.Cash);
    },
    getTotalMinutes() {
      return _.values(this.teamPlayers).reduce((prev, curr) => {
        return prev + parseInt(_.get(curr, ["Contract Offer", "Minutes"], 0));
      }, 0);
    },
    ...mapActions({
      pickupPlayer: "pickupPlayer"
    })
  },

  components: {
    CaretDownOutlined,
    CaretUpOutlined,
    Statline,
    PlayerDetails,
    UserAddOutlined,
    SolutionOutlined,
    RosterPickup,
    MedicineBoxTwoTone,
    FireTwoTone,
    DollarTwoTone,
    FileExcelTwoTone,
    TrademarkCircleTwoTone,
    CrownTwoTone,
    FrownTwoTone,
    CarTwoTone,
    AuctionModal,
    HighlightTwoTone,
    AlertTwoTone
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.custom-row {
  cursor: pointer;
}
</style>

<style scoped lang="scss">
.expandable-container {
  // margin-left: -60px;
  // padding: 0px 60px;
  max-width: calc(100vw - 200px);
  @media only screen and (max-width: 600px) {
    max-width: calc(100vw - 36px);
    margin: 0;
  }
}

.player-table {
  @media only screen and (max-width: 600px) {
    max-width: 100vw;
  }
}

.name-badge {
  margin: -8px;
  padding: 0 8px;
}

.pickup-button {
  z-index: 10;
}

.pickup-button:hover {
  color: green;
}

.desc-icon {
  font-size: 16px;
  margin-right: 3px;
}
</style>
