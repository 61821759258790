<template>
  <div class="stats">
    <a-row type="flex" justify="space-around" class="filters">
      <a-col :xs="24" :md="4">
        <Lookup
          :options="playerNames"
          @option-selected="updatePlayerFilter"
          placeholder="Filter Players"
        />
      </a-col>
      <a-col :xs="24" :md="4" v-if="!isMobile()">
        <NumInput desc="GP" @num-updated="updateMinGamesPlayed" />
      </a-col>
      <a-col :xs="24" :md="6">
        <a-radio-group v-model:value="currentKey" button-style="solid">
          <a-radio-button value="Regular">
            Regular
          </a-radio-button>
          <a-radio-button value="Advanced">
            Advanced
          </a-radio-button>
          <a-radio-button value="Per 36">
            Per 36
          </a-radio-button>
        </a-radio-group>
      </a-col>
      <a-col :xs="24" :md="4" v-if="!isMobile()">
        <NumInput desc="MPG" @num-updated="updateMinMPG" />
      </a-col>
      <a-col :xs="24" :md="4">
        <Lookup
          :options="Object.keys(teams)"
          @option-selected="updateTeamFilter"
          placeholder="Filter Teams"
        />
      </a-col>
    </a-row>
    <a-row class="table-wrapper" ref="tableWrapper">
      <a-col :span="24">
        <a-table
          class="league-leaders"
          size="middle"
          :columns="headers"
          :data-source="getFilteredPlayers"
          :rowKey="player => player.Player + player.Team"
          :pagination="false"
          :scroll="{ x: '3000px', y: 'calc(100vh - 170px)' }"
        >
          <template #bodyCell="{ text, record, index, column }">
            <div
              v-if="column.title === 'Team'"
              :class="['stat-team-column']"
              :style="
                isMobile()
                  ? {}
                  : {
                      backgroundColor: getTeamColors(text, teams).primary,
                      borderBottom: `5px solid ${
                        getTeamColors(text, teams).secondary
                      }`,
                      color: 'white'
                    }
              "
            >
              <div v-if="isMobile()">
                <a-avatar
                  shape="circle"
                  :src="getTeamLogo(record, teams)"
                ></a-avatar>
              </div>
              <div v-else>{{ text }}</div>
            </div>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import _ from "lodash";
import { toNumber } from "../utils/convertHelper";
import { getTeamColors, getTeamLogo } from "../utils/playerHelper";
import { isMobile } from "../utils/layoutHelper";
import Lookup from "./Lookup";
import NumInput from "./NumInput";
import PlayerDetails from "./PlayerDetails";

export default {
  name: "StatsTable",
  props: ["players", "stats", "teams"],
  setup() {
    return {
      getTeamColors,
      getTeamLogo,
      isMobile
    };
  },
  data() {
    return {
      sortKey: "Points",
      currentKey: "Regular",
      reverse: false,
      selectedPlayers: [],
      selectedTeams: [],
      minMPG: 0,
      minGamesPlayed: 0
    };
  },

  computed: {
    headers() {
      const statsArray = _.values(this.stats);
      const infoKeys = _.keys(statsArray[0]["Player Info"]);
      const fixedHeaders = isMobile() ? ["Player"] : ["Player", "Team"];
      const currKeys = _.keys(statsArray[0][this.currentKey]);
      return infoKeys.concat(currKeys).map(header => {
        return {
          title: header,
          align: "center",
          dataIndex: header,
          key: header,
          ellipsis: false,
          width: this.getColumnWidth(header),
          fixed: _.includes(fixedHeaders, header) ? "left" : "",
          sorter: (a, b) => toNumber(a[header]) - toNumber(b[header]),
          sortDirections: ["descend", "ascend"]
        };
      });
    },
    totalPlayers() {
      return _.values(this.stats)
        .map(player =>
          _.merge({}, player["Player Info"], player[this.currentKey])
        )
        .filter(player => player.Player && !!parseFloat(player.Minutes));
    },
    playerNames() {
      return this.totalPlayers.map(player => {
        return player.Player;
      });
    },
    getFilteredPlayers() {
      if (
        !this.selectedPlayers.length &&
        !this.selectedTeams.length &&
        !this.minGamesPlayed &&
        !this.minMPG
      ) {
        return this.totalPlayers;
      }

      return this.totalPlayers.filter(player => {
        const hasPlayer =
          !this.selectedPlayers.length ||
          _.includes(this.selectedPlayers, player.Player);
        const hasTeam =
          !this.selectedTeams.length ||
          _.includes(this.selectedTeams, player.Team);

        const playedPastMin =
          parseInt(player.Minutes) >= this.minMPG &&
          parseInt(player["Games Played"]) >= this.minGamesPlayed;

        return hasPlayer && hasTeam && playedPastMin;
      });
    }
  },
  methods: {
    updatePlayerFilter(selectedPlayers) {
      this.selectedPlayers = selectedPlayers;
    },
    updateTeamFilter(selectedTeams) {
      this.selectedTeams = selectedTeams;
    },
    updateMinGamesPlayed(minGames) {
      this.minGamesPlayed = minGames;
    },
    updateMinMPG(minMPG) {
      this.minMPG = minMPG;
    },
    getColumnWidth(header) {
      if (header.length >= 20) return 225;
      if (header.length >= 15) return 175;
      if (header.length >= 8) return 125;
      return isMobile() ? 70 : 100;
    }
  },
  components: {
    Lookup,
    NumInput,
    PlayerDetails
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.stat-team-column {
  margin: -10px -10px;
  padding: 10px;
}

.sort-button {
  display: flex;
  color: inherit;
}

.sort-button.active {
  font-weight: bold;
  color: yellow;
}
.a {
  color: inherit;
}

.stats-radio {
  margin: 20px;
}

.filters {
  margin: 10px;
}

.table-wrapper {
  width: 100vw;
  max-width: 100%;
}

.league-leaders {
  width: 100%;
}
</style>

<style>
.ant-table td {
  white-space: nowrap;
}
</style>
