<template>
  <div class="stats">
    <a-row type="flex" justify="space-around" class="filters">
      <a-col :span="6">
        <a-radio-group v-model:value="currentKey" button-style="solid">
          <a-radio-button value="Regular">
            Regular
          </a-radio-button>
          <a-radio-button value="Opponent">
            Opponent
          </a-radio-button>
          <a-radio-button value="Diff">
            Diff
          </a-radio-button>
          <a-radio-button value="Advanced">
            Advanced
          </a-radio-button>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-row class="table-wrapper" ref="tableWrapper">
      <a-col :span="24">
        <a-table
          class="league-leaders"
          :columns="headers"
          :data-source="teams"
          :rowKey="team => team.Team"
          :pagination="false"
          :scroll="{ x: '3000px', y: 'calc(100vh - 170px)' }"
        >
          <template #Team="{ text, record }">
            <div :class="[record.Team, 'team-column']">
              {{ text }}
            </div>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import { toNumber } from "../utils/convertHelper";

export default {
  name: "StatsTable",
  props: ["stats"],
  data() {
    return {
      sortKey: "Points",
      currentKey: "Regular",
      reverse: false,
      selectedPlayers: [],
      selectedTeams: [],
      minMPG: 0,
      minGamesPlayed: 0,
      tableHeight: 0
    };
  },

  computed: {
    headers() {
      const statsArray = _.values(this.stats);
      const infoKeys = _.keys(statsArray[0]["Team Info"]);
      const currKeys = _.keys(statsArray[0][this.currentKey]);
      return infoKeys.concat(currKeys).map(header => {
        return {
          title: header,
          dataIndex: header,
          key: header,
          ellipsis: false,
          width: this.getColumnWidth(infoKeys, header),
          fixed: _.includes(infoKeys, header) ? "left" : "",
          sorter: (a, b) => toNumber(a[header]) - toNumber(b[header]),
          sortDirections: ["descend", "ascend"],
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: header || "column"
          }
        };
      });
    },
    teams() {
      return _.values(this.stats).map(team =>
        _.merge({}, team["Team Info"], team[this.currentKey])
      );
    }
  },
  methods: {
    getColumnWidth(infoKeys, header) {
      if (header.length >= 20) return 250;
      if (header.length >= 15) return 200;

      return 150;
    }
  },
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.team-column {
  margin: -16px -16px;
  padding: 16px;
  text-align: center;
}

.WIZARDS {
  background-color: #e31837;
  border-bottom: 5px solid #002b5c;
  color: white;
}
.GRIZZLIES {
  background-color: #5d76a9;
  border-bottom: 5px solid #12173f;
  color: white;
}
.WARRIORS {
  background-color: #1d428a;
  border-bottom: 5px solid #ffc72c;
  color: white;
}
.PACERS {
  background-color: #002d62;
  border-bottom: 5px solid #fdbb30;
  color: white;
}
.KNICKS {
  background-color: #006bb6;
  border-bottom: 5px solid #f58426;
  color: white;
}
.SPURS {
  background-color: #000000;
  border-bottom: 5px solid #c4ced4;
  color: white;
}
.MAVERICKS {
  background-color: #00538c;
  border-bottom: 5px solid #b8c4ca;
  color: white;
}
.NUGGETS {
  background-color: #0e2240;
  border-bottom: 5px solid #1d428a;
  color: white;
}
.LAKERS {
  background-color: #552583;
  border-bottom: 5px solid #fdb927;
  color: white;
}
.JAZZ {
  background-color: #f9a01b;
  border-bottom: 5px solid #00471b;
  color: white;
}
.CELTICS {
  background-color: #007a33;
  border-bottom: 5px solid #ba9653;
  color: white;
}
.RAPTORS {
  background-color: #ce1141;
  border-bottom: 5px solid #000000;
  color: white;
}
.PELICANS {
  background-color: #c8102e;
  border-bottom: 5px solid #85714d;
  color: white;
}
.TIMBERWOLVES {
  background-color: #0c2340;
  border-bottom: 5px solid #236192;
  color: white;
}
.BUCKS {
  background-color: #00471b;
  border-bottom: 5px solid #eee1c6;
  color: white;
}
.BULLS {
  background-color: #ce1141;
  border-bottom: 5px solid #000000;
  color: white;
}
.ROCKETS {
  background-color: #ba0c2f;
  border-bottom: 5px solid #ffc72c;
  color: white;
}

.Status {
  color: red;
  &.HEALTHY {
    color: green;
  }
}

.sort-button {
  display: flex;
  color: inherit;
}

.sort-button.active {
  font-weight: bold;
  color: yellow;
}
.a {
  color: inherit;
}

.stats {
}

.stats-radio {
  margin: 20px;
}

.filters {
  margin: 10px;
}

.table-wrapper {
  width: 100vw;
  max-width: 100%;
}

.league-leaders {
  width: 100%;
}
</style>

<style>
.ant-table td {
  white-space: nowrap;
}
</style>
