<template>
  <div v-if="processing" class="processing-container">
    <h1 style="color: white">BE PATIENT</h1>
    <DribbbleOutlined class="loading" :spin="true" />
    <h1 style="color: white">BALL IS LIFE</h1>
  </div>
  <div v-if="this.loading" class="loading-container">
    <h1 style="color: white">BE PATIENT</h1>
    <DribbbleOutlined class="loading" :spin="true" />
    <h1 style="color: white">BALL IS LIFE</h1>
  </div>
  <a-layout class="layout" v-else id="components-layout-demo-top">
    <a-layout-header
      v-if="isMobile()"
      :collapsed="collapsed"
      :collapsible="true"
      :collapsedWidth="0"
      :trigger="null"
      :style="{
        overflow: 'auto',
        height: '100%',
        padding: '0'
      }"
    >
      <Menu :changePickupVisible="changePickupVisible" />
    </a-layout-header>
    <a-layout-sider
      v-else
      :collapsed="collapsed"
      :collapsible="true"
      :collapsedWidth="0"
      :trigger="null"
      :style="{
        overflow: 'auto',
        height: '100vh',
        position: isMobile() ? 'fixed' : 'sticky',
        zIndex: '2',
        top: 0,
        left: 0
      }"
    >
      <Menu :changePickupVisible="changePickupVisible" />
    </a-layout-sider>
    <a-layout-content class="content">
      <router-view />
      <RosterEditor v-bind:team="currentTeam" />
      <RosterPickup
        :visible="pickupVisible"
        :team="currentTeam"
        :handleCancel="changePickupVisible"
      />
    </a-layout-content>
  </a-layout>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import {
  LoadingOutlined,
  DribbbleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from "@ant-design/icons-vue";
import _ from "lodash";
import RosterEditor from "@/components/RosterEditor.vue";
import RosterPickup from "@/components/RosterPickup.vue";
import Menu from "@/components/Menu.vue";

export default {
  computed: {
    ...mapState([
      "playerList",
      "teams",
      "loading",
      "processing",
      "sheetsId",
      "archives",
      "currentTeam"
    ]),
    myTeam() {
      return this.teams[this.currentTeam];
    }
  },
  data() {
    return {
      current: [this.$router.currentRoute._rawValue.name],
      collapsed: this.isMobile(),
      pickupVisible: false
    };
  },
  mounted() {
    this.current = [this.$router.currentRoute._rawValue.name];
    this.getSheetData(this.sheetsId);
  },
  watch: {
    $route(to, from) {
      this.current = [to.name];
    }
  },
  methods: {
    testFn() {
      console.log(this.playerList);
    },
    isMobile() {
      return window.innerWidth < 500;
    },
    getArchiveKeys() {
      return _.keys(this.archives);
    },
    changePickupVisible() {
      this.pickupVisible = !this.pickupVisible;
    },
    ...mapActions({
      getSheetData: "getSheetData",
      changeSeason: "changeSeason",
      changeCurrentTeam: "changeCurrentTeam",
      changeModalVisible: "changeModalVisible",
      refreshPlayers: "refreshPlayers",
      toggleFlag: "toggleFlag"
    })
  },
  components: {
    LoadingOutlined,
    DribbbleOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    RosterEditor,
    RosterPickup,
    Menu
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.loading {
  display: flex;
  font-size: 72px;
  color: orange;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("https://i.ytimg.com/vi/Cj_4DupKfuk/maxresdefault.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.processing-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.layout {
  // min-width: fit-content;
}

.nav-header {
  max-height: 40px;
  display: flex;
  justify-content: flex-end;

  color: white;
}

.title {
  color: white;
  height: 40px;
  margin-top: -10px;
}

.ant-layout-content {
  min-height: calc(100vh - 64px) !important;
}

.fab {
  position: fixed !important;
  bottom: 30px;
  right: 30px;
  z-index: 2;
  min-height: 50px;
  width: 50px;
}

.select-team {
  padding: 0 10px;
  cursor: pointer;
}

.team-avatar {
  margin-right: 5px;
}

body {
  overflow-x: hidden;
}
</style>
