<template>
  <div class="player">
    <h2>
      {{ player.Name }}
    </h2>
    <div v-if="player">
      <PlayerDetails :player="player" v-if="player" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import _ from "lodash";
import PlayerDetails from "@/components/PlayerDetails.vue";
import { nameToInitial, capitalize } from "../utils/convertHelper.js";
export default {
  computed: {
    ...mapState(["pList"]),
    player() {
      return this.pList[
        nameToInitial(capitalize(this.$route.params.id.replaceAll("_", " ")))
      ];
    }
  },
  name: "Players",
  components: {
    PlayerDetails
  }
};
</script>
<style>
.player {
  margin: 20px;
}
</style>
